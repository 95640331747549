import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Alert, Box, Container, Header, FileUpload, SpaceBetween } from 'rad-framework-ui'
import { FormField } from '../common/FormField'
import { toBase64, validateAdditionalInfo } from '../common/utilities'

export function AdditionalInfo ({
  formValues,
  setFormValues,
  translate
}) {
  const files = formValues.files.map(x => {
    const file = new File([new ArrayBuffer(x.size)], x.name, { type: x.type })
    file.id = x.id
    return file
  })
  const [value, setValue] = useState(files)

  const {
    invalidTotalSize,
    maxFileSizeMB,
    maxTotalFileSizeMB,
    totalMB,
    validFileExtensions
  } = validateAdditionalInfo(formValues.files)
  const validFileTypes = [
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ]
  const proofOfIncome = [
    'Wage stubs',
    'Unemployment, Disability, or Worker\'s Compensation notice',
    'Support payment decrees from courts',
    'Award letters',
    'Letters from employers'
  ]
  const proofOfSchoolEnrollment = [
    'Report Card',
    'Enrollment letter/email',
    'Screenshot of PowerSchool , Canvas, etc.',
    'Lunch bill or statement',
    'Certificate or award with the student and school name on it'
  ]

  useEffect(() => {
    const processFiles = async () => {
      const files = []
      if (formValues.files.length > value.length) {
        const keep = formValues.files.filter(x => x.id != null && value.find(y => y.id === x.id) != null)
        files.push(...keep)
      } else {
        files.push(...formValues.files.filter(x => x.id != null))
      }
      const newFiles = []
      for (const file of value) {
        if (file.data == null && file.id == null) {
          const data = file.size / 1024 / 1024 < maxFileSizeMB ? await toBase64(file) : null
          newFiles.push({
            uuid: uuidv4(),
            name: file.name,
            type: file.type,
            size: file.size,
            data
          })
        }
      }
      setFormValues({ ...formValues, files: files.concat(newFiles) })
    }
    processFiles()
  }, [value])

  return (
    <Container
      field='additionalInformation'
      header={
        <Header variant='h2'>
          {translate('Additional Information')}
        </Header>
      }
    >
      <SpaceBetween size='l'>
        <Box color='text-status-inactive'>
          {translate('Upload additional information related to your application in this section. The following documents are not required to submit your application, but may help in determining your eligibility.')}
        </Box>
        <SpaceBetween size='xs'>
          <Box fontWeight='bold'>
            {translate('Proof of income')}
            <Box color='text-status-inactive' padding={{ left: 's' }}>
              {proofOfIncome.map((x, i) => <div key={'proof_of_income-' + i}>• {translate(x)}</div>)}
            </Box>
          </Box>
          <Box fontWeight='bold'>
            {translate('Proof of school enrollment for each child')}
            <Box color='text-status-inactive' padding={{ left: 's' }}>
              {proofOfSchoolEnrollment.map((x, i) => <div key={'proof_of_income-' + i}>• {translate(x)}</div>)}
            </Box>
          </Box>
          <Box color='text-status-inactive'>
            {translate('You may drag and drop files or click the Choose files button below.')}
            <br />
            {translate(`Each file must be less than ${maxFileSizeMB} MB, a maximum of ${maxTotalFileSizeMB} MB total can be uploaded per application. Valid file types are ${validFileExtensions.map(x => `.${x}`).join(', ')}.`)}
          </Box>
        </SpaceBetween>
        <FormField
          field='files'
        >
          <FileUpload
            onChange={({ detail }) => {
              setValue(detail.value)
            }}
            value={value}
            i18nStrings={{
              uploadButtonText: e => e ? translate('Choose files') : translate('Choose file'),
              dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
              removeFileAriaLabel: e => `Remove file ${e + 1}`,
              limitShowFewer: 'Show fewer files',
              limitShowMore: 'Show more files',
              errorIconAriaLabel: 'Error',
              warningIconAriaLabel: 'Warning'
            }}
            accept={validFileTypes.concat(validFileExtensions).join(',')}
            multiple
            maxSize={999999999} // set too large to override built in error handling
            showFileSize
            fileErrors={value.map(x => {
              const errors = []
              if (!validFileExtensions.includes(x.name.split('.').pop().toLowerCase())) {
                errors.push(translate('Invalid file type.'))
              }
              if (x.size > maxFileSizeMB * 1024 * 1024) {
                errors.push(translate('File size exceeds maximum.'))
              }
              return errors.join(' ')
            })}
          />
          {!invalidTotalSize &&
            <Box
              padding={{ top: 'xs' }}
              fontSize='body-s'
              color='text-status-inactive'
            >
              {totalMB.toFixed(2)} MB total
            </Box>}

          {invalidTotalSize &&
            <Box padding={{ top: 'xs' }}>
              <Alert
                statusIconAriaLabel='Error'
                type='error'
              >
                Total file size {totalMB.toFixed(2)} MB exceeds maximum of {maxTotalFileSizeMB} MB
              </Alert>
            </Box>}
        </FormField>
      </SpaceBetween>
    </Container>
  )
}
