import React, { useContext } from 'react'
import { AppContext } from 'rad-framework-ui'
import FormField from '@cloudscape-design/components-themed/form-field'

// https://cloudscape.design/components/form-field
function _FormField ({ errorText, field, label, required, screenReader, ...rest }) {
  const { error, translate } = useContext(AppContext)
  const errorText2 = errorText ?? (field != null && error != null && error.validationErrors != null ? error.validationErrors[field] : null)

  const classes = []
  if (screenReader) {
    classes.push('screen-reader-only')
  }
  if (required) {
    classes.push('required')
  }

  if (errorText2 != null && rest.children != null) {
    if (Array.isArray(rest.children)) {
      // rest.children = rest.children.map(x => React.cloneElement(x, { invalid: true })) // TODO: Fix this - not critical
    } else {
      rest.children = React.cloneElement(rest.children, { invalid: true })
    }
  }

  return (
    <FormField
      data-error={errorText2 != null || errorText != null ? 'true' : null}
      errorText={errorText2 != null ? translate(errorText2) : null}
      label={classes.length > 0 ? <span className={classes.join(' ')}>{label}</span> : <span>{label}</span>}
      {...{ ...rest }}
    />
  )
}

export { _FormField as FormField }
