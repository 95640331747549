import { useContext } from 'react'
import { AppContext } from 'rad-framework-ui'

// This version of useConfirm has different parameters and work differently than
// the one in the Rad Framework UI. Changes were made to facilitate error handling.

export function useConfirm ({ title, message, actionLabel, onConfirm, onCancel, autoDismiss = false, showError = true }) {
  const { setConfirmModal } = useContext(AppContext)

  async function confirm () {
    setConfirmModal({ visible: true, title, message, actionLabel, onConfirm, onCancel, autoDismiss, showError })
  }

  function dismiss () {
    setConfirmModal({ visible: false })
  }

  return [confirm, dismiss]
}
