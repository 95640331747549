import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet, useDelete, useConfirm, AppLayout, Button, Link, SpaceBetween, Header, Container, ColumnLayout, Box } from 'rad-framework-ui'

export function UserDetail () {
  const navigate = useNavigate()
  const { userId } = useParams()
  const { data: user } = useGet(`/api/user/${userId}`, { primary: true })
  const confirmDelete = useConfirm('Delete user?', 'Delete user permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/user/${userId}`, () => { navigate('/admin/user') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (user) {
    return (
      <AppLayout
        name={user.name}
        contentHeader={
          <Header
            variant='h1'
            description={user.email ? <Link href={`mailto:${user.email}`}>{user.email}</Link> : ''}
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button onClick={edit}>Edit</Button>
                <Button onClick={confirmDelete}>Delete</Button>
              </SpaceBetween>
            }
          >
            {user.name}
          </Header>
        }
        content={
          <SpaceBetween size='l'>
            <Container
              header={
                <Header
                  variant='h2'
                >
                  {user.photoUrl &&
                    <Box padding={{ bottom: 's' }}>
                      <img className='profile' src={user.photoUrl} alt={user.name} referrerPolicy='no-referrer' />
                    </Box>}
                  Details
                </Header>
              }
            >
              <ColumnLayout columns={4} borders='vertical'>
                <div>
                  <Box variant='awsui-key-label'>
                    Title
                  </Box>
                  <div>{user.title ?? '-'}</div>
                </div>
                <div>
                  <Box variant='awsui-key-label'>
                    Phone
                  </Box>
                  <div>{user.phone ?? '-'}</div>
                </div>
                <div>
                  <Box variant='awsui-key-label'>
                    Role
                  </Box>
                  {user.roles.map((x) => {
                    return (
                      <div key={x.id}><Link href={`/admin/role/${x.id}`}>{x.name}</Link></div>
                    )
                  })}
                  {user.roles.length === 0 && '-'}
                </div>
                <div>
                  <Box variant='awsui-key-label'>
                    Tribes
                  </Box>
                  {user.tribes.map((x) => {
                    return (
                      <div key={x.id}>{x.name}</div>
                    )
                  })}
                  {user.tribes.length === 0 && '-'}
                </div>
              </ColumnLayout>
            </Container>
          </SpaceBetween>
        }
      />
    )
  }
}
