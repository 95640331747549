import React from 'react'

export function DisabledMessage ({ settings }) {
  if (settings?.publicApplicationDisabled !== true) {
    return null
  }

  const html = settings.publicApplicationDisabledMessage ??
    'Application submission disabled. Please try again later.'

  return (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  )
}
