export const cherokeeTribeId = 5
export const cherokeeNationBranding = 'Cherokee Nation'
export const cherokeeDomain = 'cherokee.oksebt.org'
export const defaultBranding = 'default'

export const brandingOptions = [
  { value: defaultBranding, label: 'Default' },
  { value: cherokeeNationBranding, label: cherokeeNationBranding }
]

export function getDefaultBranding (tribes) {
  // Cherokee Nation only
  if (isOnlyCherokeeTribe(tribes)) {
    return cherokeeNationBranding
  }

  // Other Tribes only
  if (tribes.find(x => x.id === cherokeeTribeId) == null) {
    return defaultBranding
  }

  return null
}

export function isChangeable (tribeId, userInfo) {
  return tribeId == null &&
  (userInfo?.isAdmin || (userInfo?.tribes.find(x => x.id === cherokeeTribeId) != null && userInfo?.tribes.length > 1))
}

export function isOnlyCherokeeTribe (tribes) {
  return tribes.length === 1 && tribes[0].id === cherokeeTribeId
}

export function showLactoseFree (branding) {
  return branding === cherokeeNationBranding
}

export function showPin (branding) {
  return branding !== cherokeeNationBranding
}

export function TribeLogo ({ branding }) {
  if (branding === cherokeeNationBranding) {
    return <img src='/cherokee_logo_2025.png' alt='Cherokee Logo 2025' width='300' height='150' />
  }
  return null
}
