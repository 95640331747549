import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGet,
  Input,
  Link,
  Select,
  SpaceBetween,
  TextFilter
} from 'rad-framework-ui'
import { AdaptiveList } from '../common/AdaptiveList'
import { formatDate } from '../common/utilities'

export function IdentityList () {
  const [searchParams, setSearchParams] = useSearchParams()
  const [firstNameFilteringText, setFirstNameFilteringText] = useState(searchParams.get('firstName') ?? '')
  const [lastNameFilteringText, setLastNameFilteringText] = useState(searchParams.get('lastName') ?? '')
  const birthdate = searchParams.get('birthdate') ?? ''
  const { data: userInfo } = useGet('/api/user/current')
  const { data: tribeOptions } = useGet('/api/option/tribe') // TODO: add ?imsOnly=true to this once tribes table is updated

  if (userInfo == null || tribeOptions == null) return null

  const allTribeOptions = [{ label: 'All Tribes', value: '' }, ...tribeOptions]

  const sourceOptions = [
    { label: 'All Sources', value: '' },
    { label: 'Application', value: 'application' },
    { label: 'Passive Enrollment', value: 'passive_enrollment' },
    { label: 'External', value: 'external' }
  ]

  return (
    <>
      <AdaptiveList
        entity='identity'
        rootHref='/admin'
        create={false}
        edit={false}
        search={false}
        description='Search requires 1) date of birth, 2) first and last name, or 3) date of birth combined with first and/or last name.'
        emptyText={
          (firstNameFilteringText.trim() !== '' && lastNameFilteringText.trim() !== '') || birthdate !== ''
            ? 'No identities match your search'
            : 'No results will be returned until all search terms are entered.'
        }
        filter={
          <SpaceBetween size='m' direction='horizontal'>
            <TextFilter
              filteringPlaceholder='Search first name'
              filteringAriaLabel='Search first name'
              filteringText={firstNameFilteringText}
              onChange={({ detail }) => setFirstNameFilteringText(detail.filteringText)}
              onDelayedChange={({ detail }) => {
                searchParams.delete('page')
                if (detail.filteringText) {
                  searchParams.set('firstName', detail.filteringText)
                } else {
                  searchParams.delete('firstName')
                }
                setSearchParams(searchParams)
              }}
            />
            <TextFilter
              filteringPlaceholder='Search last name'
              filteringAriaLabel='Search last name'
              filteringText={lastNameFilteringText}
              onChange={({ detail }) => setLastNameFilteringText(detail.filteringText)}
              onDelayedChange={({ detail }) => {
                searchParams.delete('page')
                if (detail.filteringText) {
                  searchParams.set('lastName', detail.filteringText)
                } else {
                  searchParams.delete('lastName')
                }
                setSearchParams(searchParams)
              }}
            />
            <Input
              onChange={({ detail }) => {
                if (detail.value == null) {
                  searchParams.delete('birthdate')
                } else {
                  searchParams.set('birthdate', detail.value)
                }
                setSearchParams(searchParams)
              }}
              value={birthdate}
              type='date'
            />
            <Select
              filteringType='auto'
              selectedOption={
                allTribeOptions.length === 1
                  ? allTribeOptions[0]
                  : allTribeOptions.find(x => x.value === (searchParams.get('tribe') ?? ''))
              }
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('tribe')
                } else {
                  searchParams.set('tribe', detail.selectedOption.value)
                }
                setSearchParams(searchParams)
              }}
              options={allTribeOptions}
              enteredTextLabel={value => value}
              disabled={allTribeOptions.length === 1}
              disabledReason='You are only assigned to one Tribe'
              selectedAriaLabel='Selected'
              placeholder='Choose a Tribe'
            />
            <Select
              filteringType='auto'
              selectedOption={sourceOptions.find(x => x.value === (searchParams.get('source') ?? ''))}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('source')
                } else {
                  searchParams.set('source', detail.selectedOption.value)
                }
                setSearchParams(searchParams)
              }}
              options={sourceOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a source'
            />
          </SpaceBetween>
        }
        fields={[
          { header: 'First Name', name: 'firstName' },
          { header: 'Last Name', name: 'lastName' },
          { header: 'Date of Birth', content: (item) => formatDate(item.birthDate) },
          { header: 'Tribe', content: (item) => item.tribe.name },
          {
            header: 'Source',
            content: (item) => {
              if (item.source === 'application') {
                return <Link target='_blank' href={'/admin/application/' + item.sourceId} rel='noreferrer'>Application # {item.sourceId}</Link>
              } else if (item.source === 'passive_enrollment') {
                return <Link target='_blank' href={'/admin/passive-enrollment/' + item.sourceId} rel='noreferrer'>Passive Enrollment # {item.sourceId}</Link>
              } else {
                return `${item.source} # ${item.sourceId}`
              }
            }
          },
          { header: 'Created', content: (item) => formatDate(item.createdAt) }
        ]}
      />
    </>
  )
}
