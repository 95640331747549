import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
  useGet,
  usePost,
  usePut,
  AppLayout,
  AttributeEditor,
  Header,
  Container,
  Input,
  Select,
  Button,
  SpaceBetween
} from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'

export function RoleEdit () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { roleId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: role } = useGet(roleId ? `/api/role/${roleId}` : '', { primary: true })
  const { data: permissionOptions } = useGet('/api/option/permission')
  const create = usePost('/api/role', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/admin/role/${resp.id}`) })
  const update = usePut(`/api/role/${roleId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/admin/role/${roleId}`) })

  useEffect(() => {
    setFormValues(role ?? { permissions: [] })
  }, [role])

  async function saveChanges () {
    if (roleId) { update() } else { create() }
  }

  async function cancel () {
    if (roleId) {
      navigate(searchParams.get('redirectURL') ?? `/admin/role/${roleId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/admin/role')
    }
  }

  if (formValues != null && permissionOptions != null) {
    return (
      <AppLayout
        name={formValues.name}
        contentHeader={
          <Header variant='h1'>{formValues.id ? 'Edit' : 'New'} Role</Header>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={cancel}>Cancel</Button>
                  <Button formAction='submit' variant='primary' onClick={saveChanges} disabled={role?.name === 'Admin'}>
                    {roleId == null ? 'Add New Role' : 'Save Changes'}
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size='l'>
                <Container
                  header={
                    <Header variant='h1'>Details</Header>
                  }
                >
                  <SpaceBetween size='l'>
                    <FormField label='Name' field='name' required>
                      <Input
                        ariaRequired
                        placeholder='Enter name'
                        disabled={formValues.isProtected}
                        value={formValues.name ?? ''}
                        onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
                      />
                    </FormField>
                  </SpaceBetween>
                </Container>
                {
                  formValues.name !== 'Admin' &&
                    <Container
                      header={
                        <Header variant='h1'>Permissions</Header>
                      }
                    >
                      <AttributeEditor
                        onAddButtonClick={() => {
                          const permissions = formValues.permissions
                          permissions.push({ uuid: uuidv4() })
                          setFormValues({ ...formValues, permissions })
                        }}
                        onRemoveButtonClick={({
                          detail: { itemIndex }
                        }) => {
                          const permissions = [...formValues.permissions]
                          permissions.splice(itemIndex, 1)
                          setFormValues({ ...formValues, permissions })
                        }}
                        items={formValues.permissions}
                        addButtonText='Add new permission'
                        definition={[
                          {
                            label: <div className='required'>Permission</div>,
                            control: item => {
                              const filteredOptions = permissionOptions.filter((x) => !formValues.permissions.map((y) => y.id?.toString()).includes(x.value))
                              return (
                                <FormField field={`permissions.${item.id || item.uuid}.id`}>
                                  <Select
                                    filteringType='auto'
                                    selectedOption={permissionOptions.find(x => x.value === item.id?.toString())}
                                    onChange={({ detail }) => {
                                      const permissions = formValues.permissions
                                      const permission = formValues.permissions.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                      permission.id = parseInt(detail.selectedOption.value)
                                      permission.name = detail.selectedOption.label
                                      permission.lastName = detail.selectedOption.label.split(' ')[1]
                                      setFormValues({ ...formValues, permissions })
                                    }}
                                    options={filteredOptions}
                                    enteredTextLabel={value => value}
                                    selectedAriaLabel='Selected'
                                    placeholder='Choose a permission'
                                    empty='No matches found'
                                  />
                                </FormField>
                              )
                            }
                          }
                        ]}
                        removeButtonText='Remove'
                        empty='No permissions added to this role.'
                      />
                    </Container>
                }
              </SpaceBetween>
            </Form>
          </form>
        }
      />
    )
  }
}
