import React from 'react'
import { stateList, Box, Checkbox, Grid, Input, RadioGroup, Select, SpaceBetween } from 'rad-framework-ui'
import { FormField } from '../common/FormField'

export function PrimaryContactEditor ({ primaryContact, formValues, setFormValues, suffixOptions, phoneTypeOptions, raceEthnicityOptions, roleOptions, tribeOptions, translate }) {
  const contactMethodOptions = [
    { value: 'Text', label: translate('Text') },
    { value: 'Call', label: translate('Call') },
    { value: 'Email', label: translate('Email') }
  ]

  return (
    <SpaceBetween size='l'>
      <Box color='text-body-secondary'>
        {translate('The primary contact should be the parent/legal guardian applying for benefits on behalf of the children in the household.')}
      </Box>

      <Grid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <FormField label={translate('First Name')} field='primaryContact.firstName' required>
          <Input
            ariaRequired
            placeholder={translate('Enter first name')}
            value={primaryContact.firstName}
            onChange={({ detail }) => {
              primaryContact.firstName = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <FormField label={translate('Middle Name')} field='primaryContact.middleName'>
          <Input
            placeholder={translate('Enter middle name')}
            value={primaryContact.middleName}
            onChange={({ detail }) => {
              primaryContact.middleName = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <FormField label={translate('Last Name')} field='primaryContact.lastName' required>
          <Input
            ariaRequired
            placeholder={translate('Enter last name')}
            value={primaryContact.lastName}
            onChange={({ detail }) => {
              primaryContact.lastName = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <FormField label={translate('Suffix')} field='primaryContact.suffix'>
          <Select
            filteringType='auto'
            selectedOption={suffixOptions.find(x => x.value === primaryContact.suffix)}
            onChange={({ detail }) => {
              primaryContact.suffix = detail.selectedOption.value
              setFormValues({ ...formValues, primaryContact })
            }}
            options={suffixOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a suffix')}
            empty={translate('No matches found')}
          />
        </FormField>
      </Grid>

      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <FormField label={translate('Race / Ethnicity')} field='primaryContact.raceEthnicity'>
          <Select
            filteringType='auto'
            selectedOption={raceEthnicityOptions.find(x => x.value === primaryContact.raceEthnicity)}
            onChange={({ detail }) => {
              primaryContact.raceEthnicity = detail.selectedOption.value
              setFormValues({ ...formValues, primaryContact })
            }}
            options={raceEthnicityOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a race / ethnicity')}
            empty={translate('No matches found')}
          />
        </FormField>
        {primaryContact.raceEthnicity === 'Native American' &&
          <FormField label={translate('Tribe')} field='primaryContact.raceEthnicity'>
            <Select
              filteringType='auto'
              selectedOption={primaryContact.otherTribe ? { value: 'Other', label: translate('Other') } : tribeOptions.find(x => x.value === primaryContact.tribe)}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === 'Other') {
                  primaryContact.otherTribe = true
                  primaryContact.tribe = null
                } else {
                  primaryContact.otherTribe = false
                  primaryContact.tribe = detail.selectedOption.value
                }
                setFormValues({ ...formValues, primaryContact })
              }}
              options={tribeOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel={translate('Selected')}
              placeholder={translate('Choose a Tribe')}
              empty={translate('No matches found')}
            />
          </FormField>}
        {primaryContact.otherTribe &&
          <FormField label={translate('If other Tribe please specify')} field='primaryContact.raceEthnicity'>
            <Input
              placeholder={translate('Enter Tribe')}
              value={primaryContact.tribe}
              onChange={({ detail }) => {
                primaryContact.tribe = detail.value
                setFormValues({ ...formValues, primaryContact })
              }}
            />
          </FormField>}
        <FormField label={translate('Birth Date')} field='primaryContact.birthDate' required>
          <Input
            ariaRequired
            type='date'
            placeholder={translate('Enter birth date')}
            value={primaryContact.birthDate}
            onChange={({ detail }) => {
              primaryContact.birthDate = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
      </Grid>
      <div>
        <FormField label={translate('Mailing Address')} field='primaryContact.mailingAddress.line1' description={translate('This is the address we will mail your card to.')} stretch required>
          <Input
            ariaRequired
            placeholder={translate('Enter line 1')}
            value={primaryContact.mailingAddress.line1}
            onChange={({ detail }) => {
              primaryContact.mailingAddress.line1 = detail.value
              if (primaryContact.physicalAddressSameAsMailing) primaryContact.physicalAddress.line1 = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <FormField label={translate('Mailing Address line 2')} field='primaryContact.mailingAddress.line2' stretch screenReader>
          <Input
            placeholder={translate('Enter line 2')}
            value={primaryContact.mailingAddress.line2}
            onChange={({ detail }) => {
              primaryContact.mailingAddress.line2 = detail.value
              if (primaryContact.physicalAddressSameAsMailing) primaryContact.physicalAddress.line2 = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xs: 5 } },
            { colspan: { default: 12, xs: 4 } },
            { colspan: { default: 12, xs: 3 } }
          ]}
        >
          <FormField label={translate('City')} field='primaryContact.mailingAddress.city' required>
            <Input
              ariaRequired
              placeholder={translate('Enter city')}
              value={primaryContact.mailingAddress.city}
              onChange={({ detail }) => {
                primaryContact.mailingAddress.city = detail.value
                if (primaryContact.physicalAddressSameAsMailing) primaryContact.physicalAddress.city = detail.value
                setFormValues({ ...formValues, primaryContact })
              }}
            />
          </FormField>
          <FormField label={translate('State')} field='primaryContact.mailingAddress.state' required>
            <Select
              filteringType='auto'
              placeholder={translate('Choose a state')}
              options={stateList}
              selectedOption={stateList.find(x => x.value === primaryContact.mailingAddress.state)}
              onChange={({ detail }) => {
                primaryContact.mailingAddress.state = detail.selectedOption.value
                if (primaryContact.physicalAddressSameAsMailing) primaryContact.physicalAddress.state = detail.selectedOption.value
                setFormValues({ ...formValues, primaryContact })
              }}
            />
          </FormField>
          <FormField label={translate('ZIP')} field='primaryContact.mailingAddress.zip' required>
            <Input
              ariaRequired
              placeholder={translate('Enter zip')}
              type='number'
              value={primaryContact.mailingAddress.zip}
              onChange={({ detail }) => {
                primaryContact.mailingAddress.zip = detail.value
                if (primaryContact.physicalAddressSameAsMailing) primaryContact.physicalAddress.zip = detail.value
                setFormValues({ ...formValues, primaryContact })
              }}
            />
          </FormField>
        </Grid>
      </div>
      <div>
        <FormField label={translate('Physical Address')} required>
          <Checkbox
            checked={primaryContact.physicalAddressSameAsMailing}
            onChange={({ detail }) => {
              primaryContact.physicalAddressSameAsMailing = detail.checked
              if (detail.checked) {
                primaryContact.physicalAddress = { ...primaryContact.mailingAddress }
              } else {
                primaryContact.physicalAddress = {}
              }
              setFormValues({ ...formValues, primaryContact })
            }}
          >
            {translate('Same as mailing address')}
          </Checkbox>
        </FormField>
        {!primaryContact.physicalAddressSameAsMailing &&
          <Box padding={{ top: 's' }}>
            <FormField label={translate('Physical Address Line 1')} screenReader field='primaryContact.physicalAddress.line1' stretch required>
              <Input
                ariaRequired
                placeholder={translate('Enter line 1')}
                value={primaryContact.physicalAddress.line1}
                onChange={({ detail }) => {
                  primaryContact.physicalAddress.line1 = detail.value
                  setFormValues({ ...formValues, primaryContact })
                }}
              />
            </FormField>
            <FormField label={translate('Physical Address Line 2')} field='primaryContact.physicalAddress.line2' stretch screenReader>
              <Input
                placeholder={translate('Enter line 2')}
                value={primaryContact.physicalAddress.line2}
                onChange={({ detail }) => {
                  primaryContact.physicalAddress.line2 = detail.value
                  setFormValues({ ...formValues, primaryContact })
                }}
              />
            </FormField>
            <Grid
              gridDefinition={[
                { colspan: { default: 12, xs: 5 } },
                { colspan: { default: 12, xs: 4 } },
                { colspan: { default: 12, xs: 3 } }
              ]}
            >
              <FormField label={translate('City')} field='primaryContact.physicalAddress.city' required>
                <Input
                  ariaRequired
                  placeholder={translate('Enter city')}
                  value={primaryContact.physicalAddress.city}
                  onChange={({ detail }) => {
                    primaryContact.physicalAddress.city = detail.value
                    setFormValues({ ...formValues, primaryContact })
                  }}
                />
              </FormField>
              <FormField label={translate('State')} field='primaryContact.physicalAddress.state' required>
                <Select
                  filteringType='auto'
                  placeholder={translate('Choose a state')}
                  options={stateList}
                  selectedOption={stateList.find(x => x.value === primaryContact.physicalAddress.state)}
                  onChange={({ detail }) => {
                    primaryContact.physicalAddress.state = detail.selectedOption.value
                    setFormValues({ ...formValues, primaryContact })
                  }}
                />
              </FormField>
              <FormField label={translate('ZIP')} field='primaryContact.physicalAddress.zip' required>
                <Input
                  ariaRequired
                  placeholder={translate('Enter zip')}
                  type='number'
                  value={primaryContact.physicalAddress.zip}
                  onChange={({ detail }) => {
                    primaryContact.physicalAddress.zip = detail.value
                    setFormValues({ ...formValues, primaryContact })
                  }}
                />
              </FormField>
            </Grid>
          </Box>}
      </div>
      <FormField label={translate('Email')} field='primaryContact.email' stretch required>
        <Input
          ariaRequired
          placeholder={translate('Enter email')}
          type='email'
          value={primaryContact.email}
          onChange={({ detail }) => {
            primaryContact.email = detail.value
            setFormValues({ ...formValues, primaryContact })
          }}
        />
      </FormField>
      <Grid
        gridDefinition={[
          { colspan: { default: 6, s: 3 } },
          { colspan: { default: 6, s: 3 } },
          { colspan: { default: 6, s: 3 } },
          { colspan: { default: 6, s: 3 } }
        ]}
      >
        <FormField label={translate('Primary Phone Number')} field='primaryContact.phone1.number' required>
          <Input
            ariaRequired
            placeholder='999-999-9999 #999999999'
            type='tel'
            value={primaryContact.phone1.number}
            onChange={({ detail }) => {
              primaryContact.phone1.number = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <FormField label={translate('Primary Phone Type')} field='primaryContact.phone1.type' required>
          <Select
            filteringType='auto'
            placeholder={translate('Choose a phone type')}
            options={phoneTypeOptions.filter(x => x.value !== '')}
            selectedOption={phoneTypeOptions.find(x => x.value === primaryContact.phone1.type)}
            onChange={({ detail }) => {
              primaryContact.phone1.type = detail.selectedOption.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <FormField label={translate('Secondary Phone Number')} field='primaryContact.phone2.number'>
          <Input
            ariaRequired
            placeholder='999-999-9999 #999999999'
            type='tel'
            value={primaryContact.phone2?.number}
            onChange={({ detail }) => {
              if (primaryContact.phone2 == null) primaryContact.phone2 = {}
              primaryContact.phone2.number = detail.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
        <FormField label={translate('Secondary Phone Type')} field='primaryContact.phone2.type'>
          <Select
            filteringType='auto'
            placeholder={translate('Choose a phone type')}
            options={phoneTypeOptions}
            selectedOption={phoneTypeOptions.find(x => x.value === primaryContact.phone2?.type)}
            onChange={({ detail }) => {
              if (primaryContact.phone2 == null) primaryContact.phone2 = {}
              primaryContact.phone2.type = detail.selectedOption.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>
      </Grid>
      <FormField label={translate('I consent to be contacted')} field='primaryContact.consentToContact' description={translate('We will contact you if we have questions about your application, to confirm receipt of your application, and to inform you whether or not your application is approved. By selecting "Yes", you consent to be contacted with additional information related to your benefits including reminders and alerts about your benefits.')} required>
        <RadioGroup
          onChange={({ detail }) => {
            primaryContact.consentToContact = detail.value
            setFormValues({ ...formValues, primaryContact })
          }}
          ariaRequired
          value={primaryContact.consentToContact}
          items={[
            { value: true, label: translate('Yes') },
            { value: false, label: 'No' }
          ]}
        />
      </FormField>
      {primaryContact.consentToContact &&
        <FormField label={translate('Preferred Contact Method')} field='primaryContact.preferredContactMethod' required>
          <Select
            filteringType='auto'
            placeholder={translate('Choose a preferred contact method')}
            options={contactMethodOptions}
            selectedOption={contactMethodOptions.find(x => x.value === primaryContact.preferredContactMethod)}
            onChange={({ detail }) => {
              primaryContact.preferredContactMethod = detail.selectedOption.value
              setFormValues({ ...formValues, primaryContact })
            }}
          />
        </FormField>}
      <FormField label={translate('Are you a member of the household?')} field='primaryContact.householdMember' required>
        <RadioGroup
          onChange={({ detail }) => {
            primaryContact.householdMember = detail.value
            setFormValues({ ...formValues, primaryContact })
          }}
          ariaRequired
          value={primaryContact.householdMember}
          items={[
            { value: true, label: translate('Yes') },
            { value: false, label: 'No' }
          ]}
        />
      </FormField>
      {primaryContact.householdMember === true &&
        <FormField label={translate('Role')} field='primaryContact.role' required>
          <Select
            filteringType='auto'
            selectedOption={roleOptions.find(x => x.value === primaryContact.role)}
            onChange={({ detail }) => {
              const primaryContact = formValues.primaryContact
              primaryContact.role = detail.selectedOption.value
              setFormValues({ ...formValues, primaryContact })
            }}
            options={roleOptions.filter(x => x.value !== 'Non-student child')}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a role')}
            empty={translate('No matches found')}
          />
        </FormField>}
    </SpaceBetween>
  )
}
