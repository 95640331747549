
    import './styles.scoped.css';
    export default {
  "drag-overlay": "awsui_drag-overlay_1ksmw_lhzgl_145",
  "drag-overlay-item": "awsui_drag-overlay-item_1ksmw_lhzgl_148",
  "drag-overlay-container": "awsui_drag-overlay-container_1ksmw_lhzgl_175",
  "active": "awsui_active_1ksmw_lhzgl_203",
  "placeholder": "awsui_placeholder_1ksmw_lhzgl_213",
  "placeholder-item": "awsui_placeholder-item_1ksmw_lhzgl_222",
  "placeholder-container": "awsui_placeholder-container_1ksmw_lhzgl_228",
  "sorting": "awsui_sorting_1ksmw_lhzgl_235"
};
  