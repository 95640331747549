import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AppContext, IsLoading, NotFound, PermissionDenied } from 'rad-framework-ui'
import { ConfirmationModal } from './common/ConfirmationModal'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { TopNavigation } from './TopNavigation'
import { Confirmation } from './Confirmation'
import { Application } from './application/Application'
import { ApplicationDetail } from './application/ApplicationDetail'
import { ApplicationEdit } from './application/ApplicationEdit'
import { ApplicationList } from './application/ApplicationList'
import { BannerEdit } from './bannerManagement/BannerEdit'
import { CardList } from './card/CardList'
import { CardDetail } from './card/CardDetail'
import { CherokeeNationBenefits } from './eligibilityAdmin/CherokeeNationBenefits'
import { ChickasawNationBenefits } from './eligibilityAdmin/ChickasawNationBenefits'
import { ChildVerificationList } from './childVerification/ChildVerificationList'
import { ContactAdministrator } from './common/ContactAdministrator'
import { DomainSettings } from './domainSettings/DomainSettings'
import { EligibilityHistoryList } from './eligibilityHistory/EligibilityHistoryList'
import { IdentityList } from './identity/IdentityList'
import { ImsUploadList } from './imsUpload/ImsUploadList'
import { ImsUploadDetails } from './imsUpload/ImsUploadDetails'
import { IssuanceHistoryList } from './issuanceHistory/IssuanceHistoryList'
import { Edit } from './application/Edit'
import { Home } from './home/Home'
// import { Contact } from './home/Contact' // hiding for now - per https://app.clickup.com/t/86b46xnc9
import { PassiveEnrollmentDetail } from './passiveEnrollments/PassiveEnrollmentDetail'
import { PassiveEnrollmentEdit } from './passiveEnrollments/PassiveEnrollmentEdit'
import { PassiveEnrollmentList } from './passiveEnrollments/PassiveEnrollmentList'
import { PassiveEnrollmentUploadList } from './passiveEnrollmentUpload/PassiveEnrollmentUploadList'
import { PassiveEnrollmentUploadDetails } from './passiveEnrollmentUpload/PassiveEnrollmentUploadDetails'
import { PassiveEnrollmentParentEdit } from './passiveEnrollments/PassiveEnrollmentParentEdit'
import { RoleDetail } from './role/RoleDetail'
import { RoleEdit } from './role/RoleEdit'
import { RoleList } from './role/RoleList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'
import { UserList } from './user/UserList'
import { View } from './application/View'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

const appTitle = 'sEBT'
const navigationItems = [
  { type: 'link', text: 'Home', href: '/admin' },
  { type: 'link', text: 'Applications', href: '/admin/application' },
  { type: 'link', text: 'Eligibility Admin', href: '/admin/eligibility-admin' },
  { type: 'link', text: 'Child Verifications', href: '/admin/child-verification' },
  { type: 'link', text: 'Identities', href: '/admin/identity' },
  { type: 'link', text: 'IMS Uploads', href: '/admin/ims-upload' },
  { type: 'link', text: 'Issuance Admin', href: '/admin/issuance-admin' },
  { type: 'link', text: 'Passive Enrollments', href: '/admin/passive-enrollment' },
  { type: 'link', text: 'Passive Enrollment Uploads', href: '/admin/passive-enrollment-upload' },
  { type: 'link', text: 'Cards', href: '/admin/card' },
  { type: 'divider' },
  { type: 'link', text: 'Users', href: '/admin/user' },
  { type: 'link', text: 'Roles', href: '/admin/role' },
  { type: 'link', text: 'Site Banner', href: '/admin/banner-edit' },
  { type: 'link', text: 'Domain Settings', href: '/admin/domain-settings' }
]

export function App () {
  const [language, setLanguage] = useState('English')
  const [settings, setSettings] = useState()
  const [translations, setTranslations] = useState()
  const [missingTranslations, setMissingTranslations] = useState([])
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [contactAdministrator, setContactAdministrator] = useState(false)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const getSettings = async () => {
      const response = await fetch('/api/domain-settings')
      if (response.status === 200) {
        const json = await response.json()
        setSettings(json)
      } else {
        console.error('Failed to get settings')
        setSettings({})
      }
    }

    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    if (settings == null) {
      getSettings()
    }
    return () => unregisterAuthObserver()
  }, [])

  useEffect(() => {
    const addMissingTranslation = async (text) => {
      console.log('Adding missing translation for', text)
      const response = await fetch('/api/translation/missing', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ language, text })
      })
      const translation = await response.text()
      if (response.status === 200) {
        console.log(translation)
        setTranslations({ ...translations, [text]: translation })
        setMissingTranslations(missingTranslations => missingTranslations.filter(x => x !== text))
      }
    }
    if (missingTranslations[0] != null) {
      addMissingTranslation(missingTranslations[0])
    }
  }, [missingTranslations])

  useEffect(() => {
    const getTranslations = async () => {
      console.log('Getting translations for', language)
      try {
        const response = await fetch(`/api/translation/${language}`)
        const json = await response.json()
        const object = json.reduce((acc, cur) => {
          acc[cur.text] = cur.translation
          return acc
        }, {})
        setTranslations(object)
      } catch (e) {
        console.error(e)
      }
    }
    if (language !== 'English') {
      getTranslations()
    }
  }, [language])

  function translate (text) {
    if (language === 'English') {
      return text
    }
    if (translations != null) {
      if (translations[text] == null && !missingTranslations.includes(text)) {
        setMissingTranslations(missingTranslations => [...missingTranslations, text])
      }
      return translations[text] ?? text
    }
    return text
  }

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  if (
    window.location.pathname.split('/')[1] !== 'admin'
  ) {
    return (
      <BrowserRouter>
        <AppContext.Provider value={{
          appTitle,
          navigationItems,
          language,
          setLanguage,
          translate,
          user,
          loadingCount,
          setLoadingCount,
          notFound,
          setNotFound,
          permissionDenied,
          setPermissionDenied,
          contactAdministrator,
          setContactAdministrator,
          reloadCounter,
          setReloadCounter,
          error,
          setError,
          navigationOpen,
          setNavigationOpen,
          confirmModal,
          setConfirmModal,
          settings
        }}
        >
          <Routes>
            <Route path='/' element={<Home />} />
            {/* <Route path='/contact' element={<Contact />} /> // hiding for now - per https://app.clickup.com/t/86b46xnc9 */}
            <Route path='/apply' element={<Application />} />
            <Route path='/cherokee-nation-benefits' element={<CherokeeNationBenefits />} />
            <Route path='/chickasaw-nation-benefits' element={<ChickasawNationBenefits />} />
            <Route path='/confirmation' element={<Confirmation />} />
            <Route path='/view/:uuid' element={<View />} />
            <Route path='/edit/:uuid' element={<Edit />} />
            <Route path='/admin' element={<ApplicationList />} />
            <Route path='/pe/:uuid' element={<PassiveEnrollmentParentEdit />} />
            <Route path='*' element={<NotFound home='/' />} />
          </Routes>
          {loadingCount === 0 && notFound && <NotFound home='/' />}
          {permissionDenied != null && <PermissionDenied message={permissionDenied} />}
          {contactAdministrator && <ContactAdministrator home='/admin' />}
        </AppContext.Provider>
      </BrowserRouter>
    )
  }

  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user == null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  const providerValues = {
    appTitle,
    navigationItems,
    breadcrumbNameReplacements: { Admins: 'Admin', 'Ims Uploads': 'IMS Uploads' },
    language,
    setLanguage,
    translate,
    user,
    loadingCount,
    setLoadingCount,
    notFound,
    setNotFound,
    permissionDenied,
    setPermissionDenied,
    contactAdministrator,
    setContactAdministrator,
    reloadCounter,
    setReloadCounter,
    error,
    setError,
    navigationOpen,
    setNavigationOpen,
    confirmModal,
    setConfirmModal,
    settings
  }

  // TODO: It might be preferable to redirect instead of this

  if (
    (loadingCount === 0 && notFound) ||
    permissionDenied != null ||
    contactAdministrator
  ) {
    return (
      <BrowserRouter>
        <AppContext.Provider value={providerValues}>
          <TopNavigation />
          <Routes>
            <Route path='/admin' element={<ApplicationList />} />
          </Routes>
          {loadingCount === 0 && notFound && <NotFound home='/admin' />}
          {permissionDenied != null && <PermissionDenied message={permissionDenied} home='/admin' />}
          {contactAdministrator && <ContactAdministrator home='/admin' />}
        </AppContext.Provider>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={providerValues}>
        <TopNavigation />
        <Routes>
          <Route path='/pe/:uuid' element={<PassiveEnrollmentParentEdit />} />

          <Route path='/admin' element={<ApplicationList />} />

          <Route path='/admin/application' element={<ApplicationList />} />
          <Route path='/admin/application/:applicationId/edit' element={<ApplicationEdit />} />
          <Route path='/admin/application/:applicationId' element={<ApplicationDetail />} />

          <Route path='/admin/apply' element={<Application />} />

          <Route path='/admin/child-verification' element={<ChildVerificationList />} />
          <Route path='/admin/card' element={<CardList />} />
          <Route path='/admin/card/:cardId' element={<CardDetail />} />

          <Route path='/admin/domain-settings' element={<DomainSettings />} />

          <Route path='/admin/eligibility-admin' element={<EligibilityHistoryList />} />

          <Route path='/admin/identity' element={<IdentityList />} />

          <Route path='/admin/ims-upload' element={<ImsUploadList />} />
          <Route path='/admin/ims-upload/:imsUploadId' element={<ImsUploadDetails />} />

          <Route path='/admin/issuance-admin' element={<IssuanceHistoryList />} />

          <Route path='/admin/passive-enrollment' element={<PassiveEnrollmentList />} />
          <Route path='/admin/passive-enrollment/:passiveEnrollmentId/edit' element={<PassiveEnrollmentEdit />} />
          <Route path='/admin/passive-enrollment/:passiveEnrollmentId' element={<PassiveEnrollmentDetail />} />

          <Route path='/admin/passive-enrollment-upload' element={<PassiveEnrollmentUploadList />} />
          <Route path='/admin/passive-enrollment-upload/:passiveEnrollmentUploadId' element={<PassiveEnrollmentUploadDetails />} />

          <Route path='/admin/confirmation' element={<Confirmation />} />

          <Route path='/admin/role' element={<RoleList />} />
          <Route path='/admin/role/create' element={<RoleEdit />} />
          <Route path='/admin/role/:roleId' element={<RoleDetail />} />
          <Route path='/admin/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/admin/user' element={<UserList />} />
          <Route path='/admin/user/create' element={<UserEdit />} />
          <Route path='/admin/user/:userId' element={<UserDetail />} />
          <Route path='/admin/user/:userId/edit' element={<UserEdit />} />

          <Route path='/admin/banner-edit' element={<BannerEdit />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        <ConfirmationModal />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
