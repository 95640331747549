import React, { useState } from 'react'
import {
  useConfirm,
  useGet,
  usePost,
  usePut,
  useDelete,
  Avatar,
  Box,
  Button,
  Divider,
  FormField,
  Grid,
  Header,
  LineBreakRenderer,
  SpaceBetween,
  Textarea
} from 'rad-framework-ui'
import { formatDateTime } from './utilities'

export function Notes ({ entityType, entityId }) {
  const [isEditing, setIsEditing] = useState()
  const { data: notes, count } = useGet(`/api/note/${entityType}/${entityId}`)
  const { data: userInfo } = useGet('/api/user/current')

  if (
    notes != null && userInfo != null
  ) {
    const roles = userInfo.roles.map(x => x.name)
    const permissions = userInfo.roles.map(x => x.permissions).flat().map(x => x.name)
    const canAdd = roles.includes('Admin') ||
      permissions.includes('Administer Note') ||
      permissions.includes('Create Note')

    return (
      <Box padding='l'>
        <SpaceBetween size='xs'>
          <Box padding={{ bottom: 'l' }}>
            <Header variant='h2' counter={'(' + count + ')'}>Notes</Header>
          </Box>
          {notes && notes.map((x, i) =>
            <Note key={x.id} notes={notes} note={x} isEditing={isEditing} setIsEditing={setIsEditing} userInfo={userInfo} />)}
          {notes.length === 0 && <div>No notes found</div>}
          {canAdd && <AddNote entityType={entityType} entityId={entityId} />}
        </SpaceBetween>
      </Box>
    )
  }
}

function Note ({ notes, note, isEditing, setIsEditing, userInfo }) {
  const [text, setText] = useState(note.text)
  const update = usePut(`/api/note/${note.id}`, { text }, () => setIsEditing())
  const confirmDelete = useConfirm('Delete note?', 'Delete note permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/note/${note.id}`, () => { })

  const roles = userInfo.roles.map(x => x.name)
  const permissions = userInfo.roles.map(x => x.permissions).flat().map(x => x.name)
  const canEdit = roles.includes('Admin') ||
    permissions.includes('Administer Note') ||
    (permissions.includes('Create Note') && note.createdBy === userInfo.id)

  return (
    <div className='note'>
      <Grid
        gridDefinition={[
          { colspan: 2 },
          { colspan: 10 }
        ]}
        disableGutters
      >
        <Avatar
          src={note.creator.photoUrl}
          size='large'
          name={note.creator.name}
          referrerPolicy='no-referrer'
        />
        <SpaceBetween size='xxs'>
          {isEditing !== note.id &&
            <>
              {canEdit &&
                <div className='controls'>
                  <Button
                    onClick={() => {
                      setText(note.text)
                      setIsEditing(note.id)
                    }}
                    iconName='edit'
                    variant='inline-icon'
                  />
                  <Button onClick={() => confirmDelete()} iconName='remove' variant='inline-icon' />
                </div>}
              <LineBreakRenderer text={note.text} />
              <Box fontSize='body-s' color='text-status-inactive'>
                {formatDateTime(note.createdAt)}
                {note.updatedAt !== note.createdAt && ' (edited)'}
              </Box>
            </>}
          {isEditing === note.id &&
            <FormField stretch>
              <Textarea
                onChange={({ detail }) => setText(detail.value)}
                onKeyUp={({ detail }) => { if (detail.key === 'Enter' && !detail.shiftKey) update() }}
                value={text}
                place
                holder='Enter note'
                autoFocus
              />
              <Box float='right' padding={{ top: 'xs' }}>
                <SpaceBetween direction='horizontal' size='s'>
                  <Box padding={{ top: 'xxs' }}>
                    <Button
                      onClick={() => setIsEditing()}
                      variant='inline-link'
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    onClick={() => update()}
                    disabled={text.trim() === ''}
                    variant='primary'
                  >
                    Save Changes
                  </Button>
                </SpaceBetween>
              </Box>
            </FormField>}
        </SpaceBetween>
      </Grid>
      {note !== notes[notes.length - 1] && <Divider />}
    </div>
  )
}

function AddNote ({ entityType, entityId }) {
  const [text, setText] = useState('')
  const addNote = usePost('/api/note', { entityType, entityId, text }, () => setText(''))

  return (
    <SpaceBetween size='xs'>
      <Box padding={{ top: 'l' }}>
        <Textarea
          onChange={({ detail }) => setText(detail.value)}
          onKeyUp={({ detail }) => { if (detail.key === 'Enter' && !detail.shiftKey) addNote() }}
          value={text}
          placeholder='Enter note'
        />
      </Box>
      <Box float='right'>
        <Button
          onClick={() => addNote()}
          disabled={text.trim() === ''}
          variant='primary'
        >
          Add Note
        </Button>
      </Box>
    </SpaceBetween>
  )
}
