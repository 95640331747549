import React, { useContext } from 'react'
import { Alert, AppContext, Box, Button, Modal, SpaceBetween } from 'rad-framework-ui'

// This utilizes other RAD components
// See each for links to their documentation
export function ConfirmationModal () {
  const { confirmModal, setConfirmModal, error, setError } = useContext(AppContext)

  function dismiss () {
    if (error != null) setError(null)
    setConfirmModal({ visible: false })
  }

  function confirm () {
    if (confirmModal.onConfirm) confirmModal.onConfirm()
    if (confirmModal.autoDismiss === true) dismiss()
  }

  function cancel () {
    if (confirmModal.onCancel) confirmModal.onCancel()
    dismiss()
  }

  return (
    <Modal
      onDismiss={dismiss}
      visible={confirmModal.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={cancel}>Cancel</Button>
            <Button variant='primary' onClick={confirm}>{confirmModal.actionLabel}</Button>
          </SpaceBetween>
        </Box>
      }
      header={confirmModal.title ?? 'Title'}
    >
      {confirmModal.message ?? 'Message'}
      {confirmModal.showError && error != null &&
        <Box
          padding={{ top: 's' }}
          color='text-status-error'
        >
          <Alert
            statusIconAriaLabel='Error'
            type='error'
          >
            {error.message ?? error.code}
          </Alert>
        </Box>}
    </Modal>
  )
}
