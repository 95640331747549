import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGet,
  usePut,
  AppLayout,
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Link,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter
} from 'rad-framework-ui'
import { Multiselect } from '../common/Multiselect'
import { Form } from '../common/Form'
import { formatDate } from '../common/utilities'

const kindOptions = [
  { label: 'All Types', value: '' },
  { label: 'Standard Verification', value: 'standard' },
  { label: 'Extended Verification', value: 'extended' }
]

const gradeOptions = [
  { value: '', label: 'All Grades' },
  { value: 'Kindergarten', label: 'Kindergarten' },
  { value: '1st', label: '1st' },
  { value: '2nd', label: '2nd' },
  { value: '3rd', label: '3rd' },
  { value: '4th', label: '4th' },
  { value: '5th', label: '5th' },
  { value: '6th', label: '6th' },
  { value: '7th', label: '7th' },
  { value: '8th', label: '8th' },
  { value: '9th', label: '9th' },
  { value: '10th', label: '10th' },
  { value: '11th', label: '11th' },
  { value: '12th', label: '12th' }
]

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string

const acceptedMessage = (app) => (
  <div key={'message-' + app.id}>
    <Box color='text-status-success'>
      This application will become accepted when your changes are saved.
    </Box>
  </div>
)

const rejectedMessage = (app) => (
  <div key={'message-' + app.id}>
    <Box color='text-status-error'>
      This application will become rejected when your changes are saved.
    </Box>
  </div>
)

export function ChildVerificationList () {
  const PAGE_LENGTH = 3
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPageIndex = parseInt(searchParams.get('page') ?? '1')
  const tribe = searchParams.get('tribe') ?? ''
  const [formValues, setFormValues] = useState({})
  const [reloadCounter, setReloadCounter] = useState(0)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchKind, setSearchKind] = useState(searchParams.get('kind') ?? '')
  const [grade, setGrade] = useState(searchParams.get('grade') ?? '')
  const { data: applications, count } = useGet(
    '/api/child-verification' +
    `?search=${encodeURIComponent(searchText)}` +
    `&kind=${searchKind}` +
    `&grade=${grade}` +
    `&tribe=${tribe}` +
    `&limit=${PAGE_LENGTH}` +
    `&offset=${(currentPageIndex - 1) * PAGE_LENGTH}` +
    `&reload=${reloadCounter}`,
    { primary: true }
  )
  const { data: tribeOptions } = useGet('/api/option/tribe')
  const { data: userInfo } = useGet('/api/user/current')

  const update = usePut('/api/child-verification', formValues, (resp) => { reset() })

  if (applications == null || tribeOptions == null || userInfo == null) { return }

  function reset () {
    setFormValues({})
    setReloadCounter(reloadCounter + 1)
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  const originalValues = applications.reduce((acc, app) => {
    acc[app.id] = {
      extendedVerification: app.extendedVerification,
      verifiedIncome: app.verifiedIncome,
      updatedAt: app.updatedAt,
      children: app.children.reduce((acc, c) => {
        acc[c.id] = c.verified
        return acc
      }, {})
    }
    return acc
  }, {})

  const getChildVerified = (appId, childId) =>
    Object.hasOwn(formValues, appId) && Object.hasOwn(formValues[appId].children, childId)
      ? formValues[appId].children[childId]
      : originalValues[appId].children[childId]
  const getAppVerifiedIncome = (appId) =>
    Object.hasOwn(formValues, appId) ? formValues[appId].verifiedIncome : originalValues[appId].verifiedIncome
  const hasSelectionForEveryChild = (app) => app.children.every(c => getChildVerified(app.id, c.id) != null)

  function applyDiff (app, copy) {
    for (const childId in copy[app.id].children) {
      if (copy[app.id].children[childId] === originalValues[app.id].children[childId]) {
        delete copy[app.id].children[childId]
      }
    }
    if (copy[app.id].verifiedIncome === originalValues[app.id].verifiedIncome &&
          Object.keys(copy[app.id].children).length === 0) {
      // app is identical to version in originalValues
      delete copy[app.id]
    }

    setFormValues(copy)
  }

  function editAppState (app) {
    const copy = { ...formValues }

    if (copy[app.id] == null) {
      copy[app.id] = {
        extendedVerification: app.extendedVerification,
        verifiedIncome: app.verifiedIncome,
        updatedAt: app.updatedAt,
        children: {}
      }
    }

    return copy
  }

  function setChildVerification (app, child, verified) {
    const copy = editAppState(app)
    copy[app.id].children[child.id] = verified
    applyDiff(app, copy)
  }

  function setAppIncomeVerification (app, verified) {
    const copy = editAppState(app)
    copy[app.id].verifiedIncome = verified
    applyDiff(app, copy)
  }

  function renderAppTable (app) {
    const appVerifiedIncome = getAppVerifiedIncome(app.id)
    const { statusMessage, extraColumn } = (() => { // IIFE
      if (!app.extendedVerification) {
        // Standard Verification

        if (hasSelectionForEveryChild(app)) {
          return { statusMessage: acceptedMessage(app), extraColumn: null }
        }
      } else {
        // Extended Verification

        if (appVerifiedIncome === false) {
          return { statusMessage: rejectedMessage(app), extraColumn: null }
        } else if (hasSelectionForEveryChild(app) && getAppVerifiedIncome(app.id) != null) {
          if (app.children.some(c => getChildVerified(app.id, c.id) === true)) {
            if (app.children.some(c => getChildVerified(app.id, c.id) === false)) {
              const extraColumn = {
                id: 'info',
                header: 'Notes',
                minWidth: 20,
                cell: child =>
                  getChildVerified(app.id, child.id) === false
                    ? <Box color='text-status-warning'>This child will not receive benefits</Box>
                    : null
              }
              return { statusMessage: acceptedMessage(app), extraColumn }
            } else {
              return { statusMessage: acceptedMessage(app), extraColumn: null }
            }
          } else {
            return { statusMessage: rejectedMessage(app), extraColumn: null }
          }
        }
      }

      return { statusMessage: null, extraColumn: null }
    })()

    const extendedVerification = (
      <div key={'income-' + app.id}>
        <SpaceBetween direction='horizontal' size='l'>
          <Box fontSize='body-m'>Income Verified</Box>
          <Checkbox
            checked={appVerifiedIncome === true}
            onChange={({ detail }) => setAppIncomeVerification(app, detail.checked ? true : null)}
          >
            Yes
          </Checkbox>
          <Checkbox
            checked={appVerifiedIncome === false}
            onChange={({ detail }) => setAppIncomeVerification(app, detail.checked ? false : null)}
          >
            No
          </Checkbox>
        </SpaceBetween>
      </div>
    )

    const columns = [
      {
        id: 'fullName',
        header: 'Child',
        width: 15,
        cell: child => <div key={'full_name-' + child.id}>{highlightMatch(child.fullName)}</div>
      },
      {
        id: 'birthDate',
        header: 'Birth Date',
        width: 5,
        cell: child => <div key={'birth_date-' + child.id}>{formatDate(child.birthDate)}</div>
      },
      {
        id: 'schoolDistrict',
        header: 'School District',
        width: 10,
        cell: child => <div key={'school_district-' + child.id}>{child.schoolDistrict ?? '-'}</div>
      },
      {
        id: 'grade',
        header: 'Grade',
        width: 5,
        cell: child => <div key={'grade-' + child.id}>{child.grade}</div>
      },
      {
        id: 'verifications',
        header: app.extendedVerification ? 'Enrollment Verified' : 'Identity Verified',
        minWidth: 20,
        cell: child =>
          <div key={'verified-' + child.id}>
            <SpaceBetween direction='horizontal' size='l'>
              <Checkbox
                checked={getChildVerified(app.id, child.id) === true}
                onChange={({ detail }) => setChildVerification(app, child, detail.checked ? true : null)}
              >
                Yes
              </Checkbox>
              <Checkbox
                checked={getChildVerified(app.id, child.id) === false}
                onChange={({ detail }) => setChildVerification(app, child, detail.checked ? false : null)}
              >
                No
              </Checkbox>
            </SpaceBetween>
          </div>
      }
    ]

    if (extraColumn != null) {
      columns.push(extraColumn)
    }

    return (
      <Container key={'container-' + app.id}>
        <Table
          key={'table-' + app.id}
          variant='borderless'
          header={
            <div>
              <ColumnLayout columns={2}>
                <Box fontSize='heading-l' variant='span'>
                  Application # <Link fontSize='inherit' href={`/admin/application/${app.id}`}>{highlightMatch(app.id.toString())}</Link>
                </Box>
                {app.extendedVerification && <Box fontSize='heading-m' variant='span' float='right'>Extended Verification</Box>}
              </ColumnLayout>
              <SpaceBetween direction='horizontal' size='s'>
                <Box>{app.tribe}</Box>
                {statusMessage}
              </SpaceBetween>
              {app.extendedVerification && extendedVerification}
            </div>
          }
          columnDefinitions={columns}
          items={app.children}
        />
      </Container>
    )
  }

  const dirty = Object.keys(formValues).length > 0
  const filteredTribeOptions = tribeOptions.filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
  const selectedTribeOptions = filteredTribeOptions.filter(x => tribe.split('-').includes(x.value))

  const filters = (
    <SpaceBetween size='s'>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 2 } }
        ]}
      >
        <TextFilter
          stretch
          filteringPlaceholder='Search'
          filteringAriaLabel='Search participants'
          filteringText={filteringText}
          onChange={({ detail }) => setFilteringText(detail.filteringText)}
          onDelayedChange={({ detail }) => {
            searchParams.delete('page')
            setSearchText(detail.filteringText)
            if (detail.filteringText) {
              searchParams.set('search', detail.filteringText)
            } else {
              searchParams.delete('search')
            }
            setSearchParams(searchParams)
          }}
        />
        <Multiselect
          onChange={({ detail }) => {
            searchParams.delete('page')
            if (detail.selectedOptions.length === 0) {
              searchParams.delete('tribe')
            } else {
              searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
            }
            setSearchParams(searchParams)
          }}
          options={filteredTribeOptions}
          selectedOptions={selectedTribeOptions}
          placeholder='Choose a Tribe'
        />
        <Select
          filteringType='auto'
          selectedOption={gradeOptions.find(x => x.value === grade)}
          onChange={({ detail }) => {
            if (detail.selectedOption.value === '') {
              setGrade('')
              searchParams.delete('grade')
            } else {
              setGrade(detail.selectedOption.value)
              searchParams.set('grade', detail.selectedOption.value)
            }
            setSearchParams(searchParams)
          }}
          options={gradeOptions}
          enteredTextLabel={value => value}
          selectedAriaLabel='Selected'
          placeholder='Choose a grade'
          empty='No matches found'
        />
        <Select
          onChange={({ detail }) => {
            searchParams.delete('page')
            setSearchKind(detail.selectedOption.value)
            if (detail.selectedOption.value === '') {
              searchParams.delete('kind')
            } else {
              searchParams.set('kind', detail.selectedOption.value)
            }
            setSearchParams(searchParams)
          }}
          options={kindOptions}
          selectedOption={kindOptions.find((x) => x.value === searchKind) ?? kindOptions[0]}
          placeholder='Choose kind'
        />
        <Pagination
          currentPageIndex={currentPageIndex}
          pagesCount={Math.ceil(count / PAGE_LENGTH)}
          onChange={({ detail }) => {
            if (detail.currentPageIndex === 1) {
              searchParams.delete('page')
            } else {
              searchParams.set('page', detail.currentPageIndex)
            }
            setSearchParams(searchParams)
          }}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: pageNumber => `Page ${pageNumber} of all pages`
          }}
        />
      </Grid>
    </SpaceBetween>
  )

  return (
    <AppLayout
      contentHeader={
        <Header
          variant='h1'
          counter={`(${count})`}
          description='You can search by child name or application number.'
        >
          Child Verifications
        </Header>
      }
      content={
        <Form
          actions={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  onClick={() => reset()}
                  variant='link'
                  disabled={!dirty}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => update()}
                  variant='primary'
                  disabled={!dirty}
                >
                  Save Changes
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <SpaceBetween size='l'>
            {filters}
            {applications.map(app => renderAppTable(app))}
            {applications.length === 0 &&
              <Box textAlign='center' color='inherit'>
                No matches found.
              </Box>}
          </SpaceBetween>
        </Form>
      }
    />
  )
}
