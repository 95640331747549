import React, { useContext } from 'react'
import { AppContext, Flashbar, useGet } from 'rad-framework-ui'
import { applyTheme } from '@cloudscape-design/components/theming'

export function SiteBanner () {
  const { translate } = useContext(AppContext)
  const { data: banner } = useGet('/api/banner')

  if (banner == null) { return }

  const { message, bannerColor = '#FFFFFF', textColor = '#000000' } = banner
  const theme = {
    tokens: {},
    contexts: {
      flashbar: {
        tokens: {
          colorBackgroundNotificationBlue: bannerColor,
          colorTextNotificationDefault: textColor
        }
      }
    }
  }
  applyTheme({ theme })

  const items = [
    {
      type: 'info',
      content: translate(message),
      id: 'message_0'
    }
  ]

  return (
    (banner?.message != null && banner.message.trim().length > 0 && banner.showToUsers) && (
      <div className='site-banner'>
        <Flashbar items={items} />
      </div>
    )
  )
}
