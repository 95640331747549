import React from 'react'
import { toTitleCase } from 'titlecase'
import {
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween
} from 'rad-framework-ui'
import { getColor, formatDateTime } from '../common/utilities'

export function GeneralInformation ({ application }) {
  const hasStatuses = application.statuses.length > 0

  return (
    <Container
      header={
        <Header variant='h2'>
          General Information
        </Header>
      }
    >
      <SpaceBetween size='l'>
        <ColumnLayout
          columns={hasStatuses && application.statuses[0].status === 'accepted' ? 3 : 2}
          borders='vertical'
        >
          <div>
            <Box variant='awsui-key-label'>
              Tribe
            </Box>
            {application.tribe?.name ?? 'Unassigned'}
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Status
            </Box>
            {!hasStatuses && <div>Submitted</div>}
            {hasStatuses &&
              <Box fontWeight='heavy' display='inline' color={getColor(application.statuses[0]?.status)}>
                {toTitleCase(application.statuses[0].status)} - {formatDateTime(application.statuses[0].createdAt)}
                {application.statuses[0].reason.length > 0 &&
                  application.statuses[0].reason.map((x, i) => <div key={'reason-' + i}>{x}</div>)}
              </Box>}
          </div>
          {hasStatuses && application.statuses[0].status === 'accepted' &&
            <div>
              <SpaceBetween size='xxs'>
                <Box variant='awsui-key-label'>
                  Ineligible Children <Box display='inline' color='text-status-inactive'>({Object.keys(application.statuses[0].ineligibilityReasons).length}/{application.children.length})</Box>
                </Box>
                <SpaceBetween size='xxs'>
                  {Object
                    .keys(application.statuses[0].ineligibilityReasons)
                    .map(x => {
                      const child = application.children.find(y => y.id.toString() === x)
                      child.reason = application.statuses[0].ineligibilityReasons[x].join('. ')
                      return (
                        <div key={`ec-${x}`}>
                          {`${child.firstName} ${child.middleName ?? ''} ${child.lastName} ${child.suffix ?? ''}`} - {child.reason}
                        </div>
                      )
                    })}
                </SpaceBetween>
              </SpaceBetween>
            </div>}
        </ColumnLayout>
        <ColumnLayout columns={2} borders='vertical'>
          <div>
            <Box variant='awsui-key-label'>
              Created
            </Box>
            <SpaceBetween size='xxs'>
              <div>{formatDateTime(application.createdAt)} {application.signature}</div>
              {application.consentToApplyOnBehalfOf != null && <div>Consent to apply on behalf of applicant: {application.consentToApplyOnBehalfOf ? 'Yes' : 'No'}</div>}
            </SpaceBetween>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Updated
            </Box>
            <SpaceBetween size='xxs'>
              {application.updatedAt === application.createdAt && <div>-</div>}
              {application.updatedAt !== application.createdAt &&
                <div>{formatDateTime(application.updatedAt)} - {application.signature2}</div>}
              {application.consentToEditOnBehalfOf != null && <div>Consent to edit on behalf of applicant: {application.consentToEditOnBehalfOf ? 'Yes' : 'No'}</div>}
            </SpaceBetween>
          </div>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  )
}
