import React, { useContext, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  useGet,
  AppContext,
  AppLayout,
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter
} from 'rad-framework-ui'

export function ImsUploadDetails () {
  const { setError, user } = useContext(AppContext)
  const { imsUploadId } = useParams()
  const { data: imsUpload } = useGet(`/api/ims-upload/${imsUploadId}`, { primary: true })

  if (imsUpload == null) return null

  async function downloadFile () {
    const token = await user.getIdToken()
    const response = await fetch(`/api/ims-upload/file/${imsUpload.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/octet-stream'
      }
    })
    if (response.status !== 200) {
      setError({ message: 'Unable to download file. Please contact administrator.' })
      return
    }
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = imsUpload.outputPath.split('/').pop()
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
  }

  return (
    <AppLayout
      name={`IMS Upload # ${imsUploadId}`}
      contentHeader={
        <Header
          variant='h1'
          actions={
            <Button
              onClick={() => downloadFile()}
              iconName='download'
              iconAlign='right'
              variant='primary'
              disabled={imsUpload.committedCount == null}
            >
              Download File
            </Button>
          }
        >
          IMS Upload # {imsUploadId}
        </Header>
      }
      content={
        <SpaceBetween size='l'>
          <Details imsUpload={imsUpload} />
          <Failures imsUpload={imsUpload} />
        </SpaceBetween>
      }
    />
  )
}

function Details ({ imsUpload }) {
  if (imsUpload == null) return null

  return (
    <Container
      header={
        <Header
          variant='h2'
        >
          Details
        </Header>
      }
    >
      <SpaceBetween size='l'>
        <div>
          <Box variant='awsui-key-label'>
            File
          </Box>
          <div>{imsUpload.inputPath.split('/').pop()}</div>
        </div>
        <ColumnLayout columns={4} borders='vertical'>
          <div>
            <Box variant='awsui-key-label'>
              Tribe
            </Box>
            <div>{imsUpload.tribe.name}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Status
            </Box>
            <div>{imsUpload.committedCount != null ? 'Processed' : 'Uploaded'}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Date
            </Box>
            <div>{new Date(imsUpload.updatedAt).toLocaleString()}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              User
            </Box>
            <Link href={`/admin/user/${imsUpload.updater.id}`}>{imsUpload.updater.name}</Link>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Committed
            </Box>
            {imsUpload.committedCount != null ? Number(imsUpload.committedCount).toLocaleString() : '-'}
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Unavailable
            </Box>
            {imsUpload.unavailableCount != null ? Number(imsUpload.unavailableCount).toLocaleString() : '-'}
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Failures
            </Box>
            {imsUpload.failureCount != null ? Number(imsUpload.failureCount).toLocaleString() : '-'}
          </div>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  )
}

function Failures ({ imsUpload }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageLength = 20
  const searchText = searchParams.get('search') ?? ''
  const currentPageIndex = parseInt(searchParams.get('page') ?? 1)
  const [filteringText, setFilteringText] = useState(searchText)
  const { data: failures, count } = useGet(
    `/api/ims-upload/failures/${imsUpload.id}
    ?search=${searchText}
    &offset=${(currentPageIndex - 1) * pageLength}
    &limit=${pageLength}`
  )
  if (imsUpload == null || failures == null) return null

  return (
    <Container
      header={
        <Header
          variant='h2'
          counter={'(' + Number(count).toLocaleString() + ')'}
        >
          Failures
        </Header>
    }
    >
      <Box padding={{ left: 'xxs', right: 'xxs' }}>
        <Table
          columnDefinitions={[
            {
              id: 'rowNumber',
              header: 'Row',
              cell: item => item.rowNumber
            },
            {
              id: 'message',
              header: 'Message',
              cell: item => item.message
            }
          ]}
          items={failures}
          empty={
            <Box textAlign='center' padding={{ top: 'l' }}>
              <Box>
                No matches found.
              </Box>
            </Box>
    }
          filter={
            <TextFilter
              filteringPlaceholder='Search'
              filteringAriaLabel='Search projects'
              filteringText={filteringText}
              onChange={({ detail }) => setFilteringText(detail.filteringText)}
              onDelayedChange={({ detail }) => {
                searchParams.delete('page')
                if (detail.filteringText.trim() !== '') {
                  searchParams.set('search', detail.filteringText)
                } else {
                  searchParams.delete('search')
                }
                setSearchParams(searchParams)
              }}
            />
      }
          pagination={
            <Pagination
              currentPageIndex={currentPageIndex}
              pagesCount={Math.ceil(count / pageLength)}
              onChange={({ detail }) => {
                if (detail.currentPageIndex === 1) {
                  searchParams.delete('page')
                } else {
                  searchParams.set('page', detail.currentPageIndex)
                }
                setSearchParams(searchParams)
              }}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: pageNumber => `Page ${pageNumber} of all pages`
              }}
            />
      }
          variant='borderless'
        />
      </Box>
    </Container>
  )
}
