import React from 'react'

export default function Hero ({ translate, image, headline, subhead, text }) {
  return (
    <section className='hero' style={{ backgroundImage: `url("${image}")` }}>
      <span className='hero-overlay' />
      <div className='hero-text'>
        <h1>{translate(headline)}</h1>
        {subhead != null && <h2>{translate(subhead)}</h2>}
        {text != null && <h3>{text}</h3>}
      </div>
    </section>
  )
}
