import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useGet,
  usePut,
  AppContext,
  AppLayout,
  Box,
  Button,
  Checkbox,
  Container,
  Flashbar,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Textarea
} from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'
import { isValidDomain } from '../common/utilities'

export function DomainSettings () {
  const navigate = useNavigate()
  const { setPermissionDenied } = useContext(AppContext)
  const [formValues, setFormValues] = useState()
  const [showSuccess, setShowSuccess] = useState()
  const [showAddModal, setShowAddModal] = useState(false)
  const { data: settings } = useGet('/api/domain-settings', { primary: true })
  const { data: userInfo } = useGet('/api/user/current')
  const update = usePut('/api/domain-settings', formValues, (resp) => { setShowSuccess(true) })

  useEffect(() => {
    setFormValues(settings)
  }, [settings])

  if (formValues == null || userInfo == null) { return }

  if (!userInfo.isAdmin) {
    setPermissionDenied('If this is an error. Please contact your administrator.')
    return
  }

  const keys = Object.keys(formValues)
    .sort((a, b) => {
      // Always put 'default' at the top
      if (a === 'default') return -1
      if (b === 'default') return 1
      return a.localeCompare(b)
    })
  const isDirty = JSON.stringify(formValues) !== JSON.stringify(settings)

  return (
    <AppLayout
      contentHeader={
        <Header variant='h1'>
          Domain Settings
        </Header>
      }
      content={
        <>
          <form onSubmit={e => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={() => navigate('/admin')}>Cancel</Button>
                  <Button
                    onClick={() => update()}
                    disabled={!isDirty}
                    disabledReason='No changes to save'
                    formAction='submit'
                    variant='primary'
                  >
                    Save Changes
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size='l'>
                {keys.map(k => (
                  <Container key={k}>
                    <Grid
                      gridDefinition={[
                        { colspan: 9 },
                        { colspan: 3 }
                      ]}
                    >
                      <Header variant='h2'>{k}</Header>
                      <Box float='right'>
                        <Button
                          onClick={() => {
                            const newValues = formValues
                            delete newValues[k]
                            setFormValues({ ...newValues })
                          }}
                          variant='primary'
                          disabled={k === 'default'}
                          disabledReason='Cannot remove the default'
                        >
                          Remove
                        </Button>
                      </Box>
                    </Grid>
                    <SpaceBetween size='xs'>
                      <FormField field={`${k}.publicApplicationDisabled`}>
                        <Checkbox
                          onChange={({ detail }) => {
                            setFormValues({ ...formValues, [k]: { ...formValues[k], publicApplicationDisabled: detail.checked } })
                          }}
                          checked={formValues[k].publicApplicationDisabled === true}
                        >
                          Public Applications Disabled
                        </Checkbox>
                      </FormField>

                      {formValues[k].publicApplicationDisabled &&
                        <FormField field={`${k}.publicApplicationDisabledMessage`} stretch>
                          <Textarea
                            onChange={({ detail }) => {
                              setFormValues({ ...formValues, [k]: { ...formValues[k], publicApplicationDisabledMessage: detail.value === '' ? null : detail.value } })
                            }}
                            value={formValues[k].publicApplicationDisabledMessage ?? ''}
                            placeholder='Enter public applications disabled message'
                          />
                        </FormField>}
                    </SpaceBetween>
                  </Container>
                ))}
                <Button onClick={() => setShowAddModal(true)}>
                  Add Domain
                </Button>
              </SpaceBetween>
              <SuccessMessage showSuccess={showSuccess && !isDirty} setShowSuccess={setShowSuccess} />
            </Form>
          </form>
          <AddModal
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </>
      }
    />
  )
}

function AddModal ({ showAddModal, setShowAddModal, formValues, setFormValues }) {
  const [domain, setDomain] = useState('')
  const [error, setError] = useState()
  const isDirty = domain?.length > 0

  function dismiss () {
    setError(null)
    setDomain('')
    setShowAddModal(false)
  }

  function save () {
    if (!isValidDomain(domain)) {
      setError('Invalid domain name')
    } else if (formValues[domain] != null) {
      setError('Domain already exists')
    } else {
      setFormValues({ ...formValues, [domain]: { publicApplicationDisabled: false, publicApplicationDisabledMessage: '' } })
      dismiss()
    }
  }

  return (
    <Modal
      size='medium'
      onDismiss={() => dismiss()}
      visible={showAddModal}
      header='Admin Controls'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              onClick={() => dismiss()}
              variant='link'
            >
              Cancel
            </Button>
            <Button
              onClick={() => save()}
              variant='primary'
              disabled={!isDirty}
            >
              Add Domain
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <FormField errorText={error}>
          <Input
            onChange={({ detail }) => {
              setError(null)
              setDomain(detail.value)
            }}
            value={domain}
            ariaRequired
            placeholder='Enter domain (do not include http:// or https://, e.g. example.com) or localhost'
          />
        </FormField>
      </Form>
    </Modal>
  )
}

function SuccessMessage ({ showSuccess, setShowSuccess }) {
  return (
    showSuccess && (
      <Box padding={{ top: 'xl' }}>
        <Flashbar items={[{
          type: 'success',
          content: 'Domain Settings saved successfully.',
          id: 'message_0',
          onDismiss: () => setShowSuccess(false),
          dismissible: true
        }]}
        />
      </Box>
    )
  )
}
