import React, { useContext } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { AppContext } from 'rad-framework-ui'
import { cherokeeDomain } from '../common/branding'

export function TopMenu () {
  const { language, setLanguage, settings, translate } = useContext(AppContext)
  const { pathname: currentRoute } = useLocation()
  const [searchParams] = useSearchParams()
  const hostname = searchParams.get('cherokee') != null || window.location.hostname === cherokeeDomain
    ? cherokeeDomain
    : window.location.hostname

  if (settings == null) {
    return null
  }

  const domainSettings = settings[hostname] ?? settings.default

  const links = [
    { text: 'Home', destination: '/' },
    // { text: 'Contact', destination: '/contact' }, // hiding for now - per https://app.clickup.com/t/86b46xnc9
    { text: 'Application', destination: '/apply', hidden: domainSettings?.publicApplicationDisabled }
    // Note: Hiding these for now. They're slated to come back 20250217
    // { text: 'Cherokee Nation Benefits', destination: '/cherokee-nation-benefits' },
    // { text: 'Chickasaw Nation Benefits', destination: '/chickasaw-nation-benefits' }
  ]

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }

  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light sticky-top fw-bold'>
      <div className='container-fluid'>
        <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            {
              links.map((l, idx) => {
                const active = currentRoute === l.destination
                const linkProps = {
                  className: `nav-link ${active ? 'active' : ''}`,
                  to: l.destination,
                  onClick: () => scrollToTop()
                }
                if (active) { linkProps['aria-current'] = 'page' }

                return !l.hidden && (
                  <li key={idx} className='nav-item'>
                    <Link {...linkProps}>{translate(l.text)}</Link>
                  </li>
                )
              })
            }
            <li className='nav-item dropdown'>
              <a className='nav-link dropdown-toggle' href='#' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                {translate(language)}
              </a>
              <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <li>
                  <a
                    className='dropdown-item' onClick={(evt) => {
                      evt.preventDefault()
                      setLanguage('English')
                    }}
                    aria-current={language === 'English'}
                  >
                    {translate('English')}
                  </a>
                </li>
                <li>
                  <a
                    className='dropdown-item'
                    onClick={(evt) => {
                      evt.preventDefault()
                      setLanguage('Spanish')
                    }}
                    aria-current={language === 'Spanish'}
                  >
                    {translate('Español')}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
