import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toTitleCase } from 'titlecase'
import {
  useGet,
  usePut,
  AppContext,
  AppLayout,
  Alert,
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Header,
  Input,
  Select,
  SpaceBetween,
  TopNavigation,
  stateList
} from 'rad-framework-ui'
import { Form } from '../common/Form'
import { isValidDateString } from '../common/utilities'
import './PassiveEnrollmentParentEdit.scss'

export function PassiveEnrollmentParentEdit () {
  const { language, setLanguage, translate } = useContext(AppContext)
  const { uuid } = useParams()
  const [month, setMonth] = useState('')
  const [day, setDay] = useState('')
  const [year, setYear] = useState('')
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState()
  const [passiveEnrollment, setPassiveEnrollment] = useState()
  const [formValues, setFormValues] = useState()
  const [editing, setEditing] = useState(false)
  const { data: child } = useGet(`/api/passive-enrollment/parent-child/${uuid}`, { primary: true })
  const update = usePut(
    `/api/passive-enrollment/parent/${uuid}/${year}-${month}-${day}`,
    formValues,
    () => {
      setEditing(false)
      setPassiveEnrollment(null)
    }
  )

  useEffect(() => {
    if (passiveEnrollment != null) {
      setFormValues({ ...passiveEnrollment, declined: passiveEnrollment.declinedAt != null })
    }
  }, [passiveEnrollment])

  useEffect(() => {
    if (error != null) {
      setError(null)
    }
  }, [month, day, year])

  useEffect(() => {
    const fetchPE = async () => {
      try {
        setFetching(true)
        const response = await fetch(
          `/api/passive-enrollment/parent/${uuid}/${year}-${month}-${day}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          }
        )
        if (response.status === 200) {
          const data = await response.json()
          setError(null)
          setPassiveEnrollment(data)
        }
        if (response.status === 404) {
          setError('Enrollment Not Found')
        }
      } catch (err) {
        console.error(err)
        setError('An unknown error has occurred. Please try again later. If this problem persists, please contact support.')
      } finally {
        setFetching(false)
      }
    }

    if (!fetching && passiveEnrollment == null && error == null && month !== '' && day !== '' && year !== '' && uuid !== '') {
      if (isValidDateString(`${year}-${month}-${day}`)) {
        fetchPE()
      } else {
        setError('A valid Birth Date is required')
      }
    }
  }, [month, day, year, fetching, error, passiveEnrollment, uuid])

  const months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
  ]

  const days = [
    { label: '1', value: '01' },
    { label: '2', value: '02' },
    { label: '3', value: '03' },
    { label: '4', value: '04' },
    { label: '5', value: '05' },
    { label: '6', value: '06' },
    { label: '7', value: '07' },
    { label: '8', value: '08' },
    { label: '9', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' }
  ]

  const statusColors = {
    issued: 'text-status-success',
    accepted: 'text-status-success',
    rejected: 'text-status-error',
    escalated: 'text-status-warning',
    declined: 'text-status-warning'
  }

  const getStatusColor = (status) => statusColors[status] ?? null

  function cancel () {
    setError(null)
    setFormValues({ ...passiveEnrollment, declined: passiveEnrollment.declinedAt != null })
    setEditing(false)
  }

  const years = []
  const currentYear = new Date().getFullYear()
  for (let i = 0; i < 30; i++) {
    years.push({ label: `${currentYear - i}`, value: `${currentYear - i}` })
  }

  if (passiveEnrollment == null && child != null) {
    return (
      <div className='passive-enrollment-parent-edit'>
        <TopNav language={language} setLanguage={setLanguage} uuid={uuid} />
        <AppLayout
          breadcrumbsHide
          navigationHide
          maxContentWidth={480}
          content={
            <Box textAlign='center' padding={{ top: 'xxl' }}>
              <SpaceBetween size='l'>
                <Box fontSize='heading-xl' fontWeight='bold'>{translate('Check Your Child\'s Summer EBT Information')}</Box>
                <Box fontSize='heading-s' fontWeight='bold'><i>{translate('Enter the birth date of')} {child.name} {translate('to continue.').toLowerCase()}</i></Box>
                <Box display='inline-block'>
                  <SpaceBetween size='m' direction='horizontal'>
                    <Select
                      selectedOption={(() => {
                        const opt = months.find(x => x.value === month)
                        if (opt == null) {
                          return null
                        }
                        return { ...opt, label: translate(opt.label) }
                      })()}
                      onChange={({ detail }) => setMonth(detail.selectedOption.value)}
                      options={months.map(x => ({ ...x, label: translate(x.label) }))}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Month'
                    />
                    <Select
                      selectedOption={days.find(x => x.value === day)}
                      onChange={({ detail }) => setDay(detail.selectedOption.value)}
                      options={days}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Day'
                    />
                    <Select
                      selectedOption={years.find(x => x.value === year)}
                      onChange={({ detail }) => setYear(detail.selectedOption.value)}
                      options={years}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Year'
                    />
                  </SpaceBetween>
                </Box>
                {error != null &&
                  <Alert
                    statusIconAriaLabel='Error'
                    type='error'
                  >
                    {translate(error)}
                  </Alert>}
              </SpaceBetween>
            </Box>
          }
        />
      </div>
    )
  }

  if (formValues != null) {
    const currentStatus = passiveEnrollment.declinedAt != null ? 'declined' : passiveEnrollment.statuses[0]?.status ?? 'submitted'
    const canEditMailingAddress = !['issued', 'rejected', 'declined'].includes(currentStatus)
    const canDecline = currentStatus !== 'issued'

    return (
      <div className='passive-enrollment-parent-edit'>
        <TopNav language={language} setLanguage={setLanguage} uuid={uuid} />
        <AppLayout
          breadcrumbsHide
          navigationHide
          maxContentWidth={480}
          contentHeader={
            <Header
              variant='h1'
              description={translate(`This information was received by the ${passiveEnrollment.tribe.name} for automatic enrollment in Summer EBT benefits.`)}
            >
              <Box padding={{ top: 'xxl' }} />
              {translate('Review Your Child\'s Information')}
            </Header>
          }
          content={
            <Form
              actions={
                <SpaceBetween size='s'>
                  <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                      {(canEditMailingAddress || canDecline) && !editing &&
                        <Button
                          onClick={() => setEditing(true)}
                        >
                          {translate('Edit')}
                        </Button>}
                      {editing &&
                        <Button
                          onClick={() => cancel()}
                          variant='link'
                        >
                          {translate('Cancel')}
                        </Button>}
                      {editing &&
                        <Button
                          onClick={() => update()}
                          variant='primary'
                        >
                          {translate('Save Changes')}
                        </Button>}
                    </SpaceBetween>
                  </Box>
                </SpaceBetween>
              }
            >
              <Container>
                <Grid
                  gridDefinition={[
                    { colspan: 4 },
                    { colspan: 8 },
                    { colspan: 4 },
                    { colspan: 8 },
                    { colspan: 4 },
                    { colspan: 8 },
                    { colspan: 4 },
                    { colspan: 8 },
                    { colspan: 4 },
                    { colspan: 8 }
                  ]}
                >
                  <Box variant='awsui-key-label'>{translate('ID#:')}</Box>
                  <div>{passiveEnrollment.id}</div>

                  <Box variant='awsui-key-label'>{translate('Child:')}</Box>
                  <div>{passiveEnrollment.studentFirstName} {passiveEnrollment.studentMiddleName} {passiveEnrollment.studentLastName}</div>

                  <Box variant='awsui-key-label'>{translate('Mailing Address:')}</Box>
                  {(!canEditMailingAddress || !editing) &&
                    <div>
                      <div>{passiveEnrollment.mailingAddressLine1}</div>
                      <div>{passiveEnrollment.mailingAddressLine2}</div>
                      <div>{passiveEnrollment.mailingAddressCity}, {passiveEnrollment.mailingAddressState} {passiveEnrollment.mailingAddressZip}</div>
                    </div>}
                  {canEditMailingAddress && editing &&
                    <Grid
                      disableGutters
                      gridDefinition={[
                        { colspan: 12 },
                        { colspan: 12 },
                        { colspan: 12 },
                        { colspan: 6 },
                        { colspan: 5, offset: 1 }
                      ]}
                    >
                      <FormField field='mailingAddressLine1'>
                        <Input
                          onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressLine1: detail.value })}
                          value={formValues.mailingAddressLine1}
                          placeholder={translate('Address Line 1')}
                        />
                      </FormField>
                      <FormField field='mailingAddressLine2'>
                        <Input
                          onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressLine2: detail.value })}
                          value={formValues.mailingAddressLine2}
                          placeholder={translate('Address Line 2')}
                        />
                      </FormField>
                      <FormField field='mailingAddressCity'>
                        <Input
                          onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressCity: detail.value })}
                          value={formValues.mailingAddressCity}
                          placeholder={translate('City')}
                        />
                      </FormField>
                      <FormField field='mailingAddressState'>
                        <Select
                          filteringType='auto'
                          placeholder={translate('Choose a state')}
                          options={stateList}
                          selectedOption={stateList.find(x => x.value === formValues.mailingAddressState)}
                          onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressState: detail.selectedOption.value })}
                        />
                      </FormField>
                      <FormField field='mailingAddressZip' stretch>
                        <Input
                          onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressZip: detail.value })}
                          value={formValues.mailingAddressZip}
                          type='number'
                          placeholder={translate('ZIP')}
                        />
                      </FormField>
                    </Grid>}

                  <Box variant='awsui-key-label'>{translate('Status:')}</Box>
                  <Box color={getStatusColor(currentStatus)}>
                    {translate(toTitleCase(currentStatus.replace('escalated', 'Under Review')))}
                  </Box>

                  <Box variant='awsui-key-label'>{translate('Benefits Declined:')}</Box>
                  <Box>
                    {(!canDecline || !editing) && (passiveEnrollment.declinedAt != null ? <span>{translate('Yes')}</span> : <span>{translate('No')}</span>)}
                    {canDecline && editing &&
                      <Select
                        selectedOption={formValues.declined === true ? { label: translate('Yes'), value: 'true' } : { label: translate('No'), value: 'false' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value === 'true') {
                            setFormValues({ ...formValues, declined: true })
                          } else {
                            setFormValues({ ...formValues, declined: false })
                          }
                        }}
                        options={[{ label: translate('Yes'), value: 'true' }, { label: translate('No'), value: 'false' }]}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                      />}
                  </Box>
                </Grid>
              </Container>
              {!canEditMailingAddress &&
                <Box padding={{ top: 'l' }}>
                  <Alert statusIconAriaLabel='Info'>
                    {translate('Mailing address can no longer be edited.')}
                  </Alert>
                </Box>}
              {!canDecline && passiveEnrollment.declinedAt == null &&
                <Box padding={{ top: 'l' }}>
                  <Alert statusIconAriaLabel='Info'>
                    {translate('Benefits can no longer be declined.')}
                  </Alert>
                </Box>}
            </Form>
          }
        />
      </div>
    )
  }
}

function TopNav ({ language, setLanguage, uuid }) {
  return (
    <TopNavigation
      identity={{
        href: `/pe/${uuid}`
      }}
      utilities={[{
        type: 'menu-dropdown',
        text: language,
        onItemClick: event => { setLanguage(event.detail.id) },
        items: [
          { id: 'English', text: 'English' },
          { id: 'Spanish', text: 'Spanish' }
        ]
      }]}
      i18nStrings={{
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu'
      }}
    />
  )
}
