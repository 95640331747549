import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useConfirm, useGet, usePut, AppContext, AppLayout, Button, Checkbox, Container, Header, SpaceBetween } from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'

// Leaving this for now in case it's needed later

export function PassiveEnrollmentEdit () {
  const { language, translate } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { passiveEnrollmentId } = useParams()
  const { data: passiveEnrollment } = useGet(passiveEnrollmentId ? `/api/passive-enrollment/${passiveEnrollmentId}` : '', { primary: true })
  const { data: userInfo } = useGet('/api/user/current')
  const [formValues, setFormValues] = useState()
  const update = usePut(`/api/application/${passiveEnrollmentId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/admin/application/${passiveEnrollmentId}`) })
  const confirmUpdate = useConfirm('Confirm Save Changes', 'Submitting this edit will remove the application\'s current status. Are you sure you want to proceed?', 'Save Changes', () => { update() })

  useEffect(() => {
    if (passiveEnrollment != null) {
      setFormValues(passiveEnrollment)
    }
  }, [passiveEnrollment])

  useEffect(() => {
    if (formValues != null) {
      setFormValues({ ...formValues, language })
    }
  }, [language])

  if (
    formValues != null &&
    userInfo != null
  ) {
    // const hasStatuses = passiveEnrollment.statuses.length > 0
    // const currentStatus = hasStatuses && passiveEnrollment.statuses[0].status
    // const canDecline = currentStatus === 'escalated' && (userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Exclude Child from Benefit Recipients')) != null)

    // const saveChangesButton = (
    //   <Button
    //     onClick={() => {
    //       if (formValues.statuses.length > 0) {
    //         confirmUpdate()
    //       } else {
    //         update()
    //       }
    //     }}
    //     formAction='submit'
    //     variant='primary'
    //     disabled={
    //       (currentStatus === 'accepted') ||
    //       isNullOrWhitespace(formValues.signature2) ||
    //       formValues.agreeEsignature2 !== true ||
    //       formValues.concentToSignOnBehalfOf !== true
    //     }
    //   >
    //     Save Changes
    //   </Button>
    // )

    return (
      <AppLayout
        name={passiveEnrollment.id}
        contentHeader={
          <Header variant='h1'>
            {translate('Application')} # {passiveEnrollment.id}
          </Header>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={() => navigate(searchParams.get('redirectURL') ?? `/admin/passive-enrollment/${passiveEnrollmentId}`)}>Cancel</Button>
                  <Button
                    onClick={() => {
                      if (formValues.statuses.length > 0) {
                        confirmUpdate()
                      } else {
                        update()
                      }
                    }}
                    formAction='submit'
                    variant='primary'
                  >
                    {translate('Save Changes')}
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size='l'>
                <Container
                  header={
                    <Header
                      variant='h2'
                    >
                      {translate('Admin Controls')}
                    </Header>
}
                >
                  <SpaceBetween size='s'>
                    <FormField field='bypassMailingAddress'>
                      <Checkbox
                        checked={formValues.bypassMailingAddress ?? false}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, bypassMailingAddress: detail.checked })
                        }}
                      >
                        Mailing address outside of Oklahoma, application should proceed.
                      </Checkbox>
                    </FormField>
                    <FormField field='bypassPotentialDuplicate'>
                      <Checkbox
                        checked={formValues.bypassPotentialDuplicate ?? false}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, bypassPotentialDuplicate: detail.checked })
                        }}
                      >
                        Application is not a potential duplicate.
                      </Checkbox>
                    </FormField>
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        }
      />
    )
  }
}
