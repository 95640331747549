import React from 'react'
import { Link } from 'rad-framework-ui'
import { AdaptiveList } from '../common/AdaptiveList'

export function RoleList () {
  return (
    <AdaptiveList
      entity='role'
      rootHref='/admin'
      fields={[
        { header: 'Name', name: 'name', link: true },
        { header: 'Users', content: (item) => item.users.length > 0 ? <div>{item.users.map((x) => <div key={'user-' + x.id}><Link href={`/admin/user/${x.id}`}>{x.name} ({x.email})</Link></div>)}</div> : '-' },
        { header: 'Permissions', content: (item) => item.permissions.length > 0 ? <div>{item.permissions.map((x) => <div key={'user-' + x.id}>{x.name}</div>)}</div> : '-' }
      ]}
    />
  )
}
