import React from 'react'
import ProgressBar from '@cloudscape-design/components-themed/progress-bar'

// https://cloudscape.design/components/progress-bar
export function _ProgressBar ({ ...rest }) {
  return (
    <ProgressBar
      {...rest}
    />
  )
}

export { _ProgressBar as ProgressBar }
