import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGet,
  usePost,
  AppContext,
  Box,
  Button,
  FileUpload,
  FormField,
  Link,
  Modal,
  Select,
  SpaceBetween
} from 'rad-framework-ui'
import { AdaptiveList } from '../common/AdaptiveList'
import { Multiselect } from '../common/Multiselect'
import { toBase64 } from '../common/utilities'

export function ImsUploadList () {
  const defaultFormValues = {}
  const [searchParams, setSearchParams] = useSearchParams()
  const status = searchParams.get('status') ?? ''
  const tribe = searchParams.get('tribe') ?? ''
  const { error, setError, reloadCounter, setReloadCounter, user } = useContext(AppContext)
  const [files, setFiles] = useState([])
  const [formValues, setFormValues] = useState(defaultFormValues)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { data: userInfo } = useGet('/api/user/current')
  const { data: tribeOptions } = useGet('/api/option/tribe?imsOnly=true')
  const upload = usePost('/api/ims-upload/upload', formValues, () => { dismissUploadModal() })

  const statusOptions = [
    { label: 'All Statuses', value: '' },
    { label: 'Uploaded', value: 'uploaded' },
    { label: 'Processed', value: 'processed' }
  ]

  useEffect(() => {
    if (tribeOptions == null || userInfo == null) return
    const filteredTribeOptions = tribeOptions
      .filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
    if (filteredTribeOptions.length === 1) {
      defaultFormValues.tribeId = parseInt(filteredTribeOptions[0].value)
      setFormValues(defaultFormValues)
    }
  }, [userInfo, tribeOptions])

  useEffect(() => {
    const processFile = async () => {
      const file = files[0]
      if (file != null) {
        const fileData = await toBase64(file)
        setFormValues({ ...formValues, file: file.name, fileSize: file.size, fileData })
      } else {
        setFormValues(defaultFormValues)
      }
    }

    processFile()
  }, [files])

  function dismissUploadModal () {
    setError(null)
    setShowUploadModal(false)
    setFormValues(defaultFormValues)
    setFiles([])
  }

  function dismissErrorModal () {
    setError(null)
    setReloadCounter(reloadCounter + 1)
  }

  function truncateText (text, maxLength) {
    if (text.length <= maxLength) {
      return text
    }
    return text.slice(0, maxLength) + '...'
  }

  async function downloadFile (item) {
    const token = await user.getIdToken()
    const response = await fetch(`/api/ims-upload/file/${item.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/octet-stream'
      }
    })
    if (response.status !== 200) {
      setError({ message: 'Unable to download file. Please contact administrator.' })
      return
    }
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = item.outputPath.split('/').pop()
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
  }

  if (userInfo == null || tribeOptions == null) return null

  const filteredTribeOptions = tribeOptions
    .filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
  const selectedTribeOptions = filteredTribeOptions.filter(x => tribe.split('-').includes(x.value))

  return (
    <>
      <AdaptiveList
        entity='ims-upload'
        title='IMS Uploads'
        rootHref='/admin'
        description='You can search by file name. Uploads are limited to 50,000 records.'
        create={false}
        edit={false}
        actions={[
          { label: 'Upload', onClick: () => { setError(null); setShowUploadModal(true) } }
        ]}
        fields={[
          { header: '#', name: 'id' },
          {
            header: 'Input File',
            content: (item) => truncateText(item.inputPath.split('/').pop(), 40)
          },
          {
            header: 'Status',
            content: (item) => item.committedCount != null ? 'Processed' : 'Uploaded'
          },
          {
            header: 'Date',
            content: (item) => new Date(item.updatedAt).toLocaleString()
          },
          {
            header: 'User',
            content: (item) => <Link href={`/admin/user/${item.updater.id}`}>{item.updater.name}</Link>
          },
          'tribe.name',
          {
            header: 'Committed',
            content: (item) =>
              <Box textAlign='center'>
                {item.committedCount == null ? '-' : Number(item.committedCount).toLocaleString()}
              </Box>
          },
          {
            header: 'Unavailable',
            content: (item) =>
              <Box textAlign='center'>
                {item.unavailableCount == null ? '-' : Number(item.unavailableCount).toLocaleString()}
              </Box>
          },
          {
            header: 'Failures',
            content: (item) =>
              <Box textAlign='center'>
                {item.failureCount == null
                  ? '-'
                  : item.failureCount > 0
                    ? <Link href={`/admin/ims-upload/${item.id}`}>{Number(item.failureCount).toLocaleString()}</Link>
                    : Number(item.failureCount).toLocaleString()}
              </Box>
          },
          {
            header: 'Actions',
            content: (item) =>
              <Button
                onClick={() => downloadFile(item)}
                iconName='download'
                variant='inline-icon'
                disabled={item.committedCount == null}
              >
                Download
              </Button>
          }
        ]}
        filter={
          <SpaceBetween size='m' direction='horizontal'>
            <Select
              onChange={({ detail }) => {
                searchParams.delete('page')
                if (detail.selectedOption.value === '') {
                  searchParams.delete('status')
                } else {
                  searchParams.set('status', detail.selectedOption.value)
                }
                setSearchParams(searchParams)
              }}
              options={statusOptions}
              selectedOption={statusOptions.find(x => x.value === status)}
              placeholder='Choose a status'
            />
            <Multiselect
              onChange={({ detail }) => {
                searchParams.delete('page')
                if (detail.selectedOptions.length === 0) {
                  searchParams.delete('tribe')
                } else {
                  searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
                }
                setSearchParams(searchParams)
              }}
              options={filteredTribeOptions}
              selectedOptions={selectedTribeOptions}
              placeholder='Choose a Tribe'
            />
          </SpaceBetween>
        }
      />
      <Modal
        size='medium'
        onDismiss={() => { dismissUploadModal() }}
        visible={showUploadModal}
        header='Upload File'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='s'>
              <Box padding={{ top: 'xs' }}>
                <Button
                  onClick={() => dismissUploadModal()}
                  variant='inline-link'
                >
                  Cancel
                </Button>
              </Box>
              <Button
                onClick={() => upload()}
                variant='primary'
                disabled={formValues.tribeId == null || files.length === 0}
              >
                Upload
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween size='l'>
          <FormField label='Tribe' field='tribeId' required>
            <Select
              filteringType='none'
              selectedOption={tribeOptions.find(x => parseInt(x.value) === formValues.tribeId)}
              onChange={({ detail }) => setFormValues({ ...formValues, tribeId: parseInt(detail.selectedOption.value) })}
              options={tribeOptions.filter(x => userInfo.tribes.length === 0 || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a Tribe'
            />
          </FormField>
          <FormField label='CSV File' required>
            <FileUpload
              onChange={({ detail }) => setFiles(detail.value)}
              value={files}
              accept='.csv'
              i18nStrings={{
                uploadButtonText: e => e ? 'Choose files' : 'Choose file',
                dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
                removeFileAriaLabel: e => `Remove file ${e + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error',
                warningIconAriaLabel: 'Warning'
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={1}
              maxSize={10}
            />
          </FormField>
        </SpaceBetween>
      </Modal>
      <Modal
        size='medium'
        onDismiss={() => dismissErrorModal()}
        visible={error != null}
        header='Error'
        footer={
          <Box float='right'>
            <Button
              onClick={() => dismissErrorModal()}
              variant='primary'
            >
              Dismiss
            </Button>
          </Box>
        }
      >
        <Box color='text-status-error'>{error?.message}</Box>
      </Modal>
    </>
  )
}
