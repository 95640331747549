import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SiteBanner } from '../SiteBanner'
import { useGet, AppContext, Select } from 'rad-framework-ui'
import '../form.css'
import '../layout.scss'
import '../responsive.scss'
import Hero from '../common/Hero'
import Footer from '../common/Footer'
import { FormField } from '../common/FormField'
import { TopMenu } from '../common/TopMenu'
import { cherokeeDomain } from '../common/branding'

export function Home () {
  const { translate } = useContext(AppContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const hostname = searchParams.get('cherokee') != null || window.location.hostname === cherokeeDomain
    ? cherokeeDomain
    : window.location.hostname

  useEffect(() => {
    console.log('hostname', hostname)
    if (hostname === cherokeeDomain) {
      navigate('/apply')
    } else {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <>
      <TopMenu />

      <SiteBanner />

      <Hero
        {...{ translate }}
        image='assets/images/hero-01.webp'
        headline='NOURISHING OUR FUTURE'
        subhead='Ensuring Every Eligible Child Enjoys a Healthy Summer with EBT'
      />

      <SebtIntro {...{ translate }} />

      <GraphicBorder />

      <Eligibility {...{ translate }} />

      <ImageGrid />

      <KeyFeatures {...{ translate }} />

      <Footer {...{ translate }} />
    </>
  )
}

function PageSectionContainer ({ fluid, classes, children }) {
  return (
    <div className={`container${fluid ? '-fluid' : ''} px-4 pt-3 px-lg-5 pt-lg-4 ${classes.join(' ')}`}>
      {children}
    </div>
  )
}

function GraphicBorder () {
  return (
    <div className='graphic_border'>
      <img className='w-100 mt-3 mt-lg-5' src='assets/images/graphic_border_converted.webp' alt='graphic border' />
    </div>
  )
}

function SebtIntro ({ translate }) {
  return (
    <PageSectionContainer fluid classes={['sebt-intro']}>
      <div className='row'>
        <div className='col what-is-heading' style={{ }}>
          {translate('What Is')}&nbsp;
        </div>
      </div>
      <WhatIsSebt {...{ translate }} />
      <Sponsors {...{ translate }} />
    </PageSectionContainer>
  )
}

function WhatIsSebt ({ translate }) {
  const emphasisTitle = 'Summer EBT?'
  const subTitle = 'A Federal Program For Families'
  const bodyText = `
    Summer EBT is a federal program that provides benefits to buy groceries to
    qualifying families with school-aged children when schools are closed for
    the summer. Summer EBT benefits come in the form of pre-loaded cards (like a
    debit card) that families can use to purchase groceries at participating
    stores. Families can receive a food package valued at $120 per eligible
    child. These benefits work together with other available programs, such as
    Summer Meals, SNAP (formerly known as food stamps), WIC, and Food
    Distribution Program on Indian Reservation (FDPIR or commodities) to help
    ensure children have consistent access to critical nutrition when school is out.
  `
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <h2 className='fs-1 fw-bold'>{translate(emphasisTitle)}</h2>
        <h3>{translate(subTitle)}</h3>
        <p>{translate(bodyText)}</p>
      </div>
    </div>
  )
}

function Sponsors ({ translate }) {
  return (
    <div className='row gy-5'>
      <div className='col-lg-4 text-center'>
        <img height='144' src='assets/images/SEBT-vertical-cropped-t1s.jpg' alt='Otoe-Missouria SEBT' />
      </div>

      <div className='col-lg-4 text-center'>
        <img height='144' src='assets/images/MNSummerEBTLogo-c1s.png' alt='Muscogee (Creek) Nation SEBT' />
      </div>

      <div className='col-lg-4 text-center'>
        <img height='144' src='assets/images/hfo-1.png' alt='Hunger Free Oklahoma logo' />
      </div>
    </div>
  )
}

function Eligibility ({ translate }) {
  return (
    <PageSectionContainer fluid classes={['sebt-intro', 'pb-3', 'pb-lg-4']}>
      <div className='border p-2 p-lg-4' style={{ borderRadius: '15px' }}>
        <h2 className='section-green-heading'>{translate('Summer EBT Eligibility')}</h2>
        <CheckSchool {...{ translate }} />
        <SummerEligibility {...{ translate }} />
      </div>
    </PageSectionContainer>
  )
}

function CheckSchool ({ translate }) {
  const [schoolDistrict, setSchoolDistrict] = useState()
  const { data: schoolDistrictOptions } = useGet('/api/option/school-district')

  if (schoolDistrictOptions != null) {
    const filteredSchoolDistrictOptions = schoolDistrictOptions.filter(x => x.value !== '')
    return (
      <>
        <div className='pt-2 pt-lg-3 pb-3 pb-lg-4'>
          {translate('Select your student\'s school district to learn more about benefits available to you.')} <a href='https://nces.ed.gov/ccd/schoolmap/' target='_blank' rel='noopener noreferrer'>{translate('Don\'t know your school district? Use this tool.')}</a>
        </div>
        <FormField label='' field=''>
          <Select
            filteringType='auto'
            selectedOption={schoolDistrictOptions.find(x => x.value === schoolDistrict?.value)}
            onChange={({ detail }) => { setSchoolDistrict(detail.selectedOption) }}
            options={filteredSchoolDistrictOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Select a school district')}
            empty={translate('No matches found')}
          />
        </FormField>
        <div className='result pt-3 pt-lg-4 pb-3 pb-lg-4 fw-bold fs-6'>
          {schoolDistrict != null && (
            <>
              {schoolDistrict.tribeId != null
                ? ( // school district associated with any tribe is "eligible" for this check - decision 20250227
                  <>
                    {schoolDistrict.applicationIsOpen
                      ? ( // open applications
                        <>
                          {!schoolDistrict.dataSharing
                            ? ( // no data sharing
                              <div>
                                {translate(`Summer EBT benefits for this district are offered by the ${schoolDistrict.tribeName}. If your student meets the eligibility criteria,`)}&nbsp;
                                <a href={schoolDistrict.applicationsLink} target='_blank' rel='noopener noreferrer'>
                                  {translate('apply for benefits here')}
                                </a>.
                              </div>
                              )
                            : ( // data sharing
                              <div>
                                {translate(`
                                  If your child receives free and reduced-price lunch in this district, they are already
                                  enrolled in the Summer EBT program through the ${schoolDistrict.tribeName}, and you don't need
                                  to apply. If you're unsure about your enrollment, email
                                `)}
                                <a href='mailto:help@oksebt.org'>help@oksebt.org</a>.&nbsp;
                                {translate('If your student meets the eligibility criteria and does not receive free and reduced-price lunch,')}&nbsp;
                                <a href={schoolDistrict.applicationsLink} target='_blank' rel='noopener noreferrer'>
                                  {translate('apply for benefits here')}
                                </a>.
                              </div>
                              )}
                        </>
                        )
                      : ( // closed applications
                        <>
                          {!schoolDistrict.dataSharing
                            ? ( // no data sharing
                              <div>
                                {translate(`
                                  Summer EBT benefits for this district are offered by the ${schoolDistrict.tribeName}. If your
                                  student meets the eligibility criteria, apply for benefits when the application opens in
                                  mid April.
                                `)}
                              </div>
                              )
                            : ( // data sharing
                              <div>
                                {translate(`
                                  If your child receives free and reduced-price lunch in this district, they are already
                                  enrolled in the Summer EBT program through the ${schoolDistrict.tribeName}, and you don't need
                                  to apply. If you're unsure about your enrollment, email
                                `)}
                                <a href='mailto:help@oksebt.org'>help@oksebt.org</a>.&nbsp;
                                {translate(`
                                  If your student meets the eligibility criteria and does not receive free and reduced-price lunch,
                                  apply for benefits when the application opens in mid April.
                                `)}
                              </div>
                              )}
                        </>
                        )}
                  </>
                  )
                : ( // ineligible school district
                  <div>
                    {translate('The selected school is not eligible for Summer EBT benefits.')}&nbsp;
                    <a href='https://www.hungerfreeok.org/foodresourcetool' target='_blank' rel='noopener noreferrer'>
                      {translate('Learn about other nutrition assistance programs you may be eligible for')}
                    </a>.
                  </div>
                  )}
            </>
          )}
        </div>
      </>
    )
  }
}

function SummerEligibilityGreen ({ translate, bold, bullets, children }) {
  return (
    <div className='summer-eligibility-green p-3 p-md-4 h-100'>
      <div className='fw-bold'>{translate(bold)}</div>
      <ul className='d-block pt-3'>{bullets.map((x, idx) => <li key={idx}>{x}</li>)}</ul>
      {children}
    </div>
  )
}

function SummerEligibility ({ translate }) {
  return (
    <div className='container-fluid'>
      <div className='row g-3'>
        <div className='col-lg-6'>
          <SummerEligibilityGreen
            {...{ translate }}
            bold={
              translate(`To be eligible to receive Summer EBT benefits, a student must attend
              an eligible school district, and meet one of the following:`)
            }
            bullets={[
              translate('Participate in the Free and Reduced-Price Lunch Program at school'),
              translate('Meet the income guidelines for Free and Reduced-Price Lunch'),
              translate(`Participate in SNAP (food stamps), WIC, SoonerCare (Medicaid), Food
               Distribution on Indian Reservation (FDPIR or commodities), or TANF
               (Temporary Assistance for Needy Families)`),
              translate('Be enrolled in foster care')
            ]}
          />
        </div>
        <div className='col-lg-6'>
          <SummerEligibilityGreen
            {...{ translate }}
            bold={
              translate(`Children living within an eligible school district who attend virtual
              charter schools or are homeschooled might be eligible if they meet
              one of the following criteria:`)
            }
            bullets={[
              translate(`Participate in SNAP (food stamps), WIC, SoonerCare (Medicaid), Food
               Distribution on Indian Reservation (FDPIR or commodities), or TANF
               (Temporary Assistance for Needy Families)`),
              translate('Be enrolled in foster care')
            ]}
          >
            <div className='text-center pt-lg-2'>
              <div className='fst-italic'>
                {translate('If you are not eligible for Summer EBT')},&nbsp;
                <a className='fst-italic fw-bold' href='https://www.hungerfreeok.org/foodresourcetool/' target='_blank' rel='noopener noreferrer'>
                  {translate('learn about other nutrition assistance programs you may be eligible for')}
                </a>.
              </div>
            </div>
          </SummerEligibilityGreen>
        </div>
      </div>
    </div>
  )
}

function ImageGrid () {
  return (
    <div className='container-fluid p-0 image-grid-banner d-none d-lg-block'>
      <div className='row g-2'>
        <div className='col'><div className='item i-a' /></div>
        <div className='col'><div className='item i-b' /></div>
        <div className='col'><div className='item i-c' /></div>
        <div className='col'><div className='item i-d' /></div>
      </div>
    </div>
  )
}

function KeyFeatures ({ translate }) {
  return (
    <PageSectionContainer fluid classes={['key-program-features', 'pb-3', 'pb-lg-4']}>
      <div className='row'>
        <div className='col-lg-8'>
          <h2 className='section-green-heading'>{translate('Key Features of the Program')}</h2>
          <div className='pt-2 pt-lg-3'>
            {translate(`
              The Summer Electronic Benefit Transfer (EBT) Program provides a
              support system for families with school-aged children for the
              months of May to August, when access to free and
              reduced-price lunch is unavailable. This initiative is
              federally funded and designed to alleviate the nutritional gap
              experienced by children during the summer break.
            `)}
          </div>
          <ul className='pt-2 pt-lg-3'>
            <li>
              <strong>{translate('Eligibility Criteria')}:</strong> {
                translate(`
                  This program serves Native American and non-Native students
                  who meet the eligibility requirements listed under Summer
                  EBT Eligibility.
                `)
              }
            </li>
            <li>
              <strong>{translate('Financial Assistance')}:</strong> {
                translate(`
                  Qualified families will receive a food package valued at
                  $120 per eligible child for the summer. This financial
                  assistance is aimed at purchasing healthy food options.
                `)
              }
            </li>
            <li>
              <strong>{translate('EBT Cards')}:</strong> {
                translate(`
                  The benefits are loaded onto EBT cards, functioning similarly
                  to debit cards, making the purchase of groceries seamless at
                  participating stores.
                `)
              }
            </li>
            <li>
              <strong>{translate('No Impact on Other Assistance')}:</strong> {
                translate(`
                  Enrollment in the Summer EBT Program does not affect the
                  eligibility or benefits of other government assistance
                  programs that the family or child might be receiving.
                `)
              }
            </li>
            <li>
              <strong>{translate('Confidentiality and Privacy')}:</strong> {
                translate(`
                  The program guarantees privacy. Information is solely used
                  for the purpose of the Summer EBT Program.
                `)
              }
            </li>
            <li>
              <strong>{translate('Ease of Use')}:</strong> {
                translate(`
                  Upon approval, families will receive their Summer EBT cards
                  through mail along with a list of participating stores.
                `)
              }
            </li>
          </ul>
        </div>
        <div className='col-lg-4 text-center d-none d-lg-inline-block carrots' />
      </div>
    </PageSectionContainer>
  )
}
