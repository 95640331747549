import React from 'react'
import { useParams } from 'react-router-dom'
import {
  useGet,
  AppLayout,
  Box,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Link,
  SpaceBetween
} from 'rad-framework-ui'
import { formatDate, formatDateTime } from '../common/utilities'

export function CardDetail () {
  const { cardId } = useParams()
  const { data: card } = useGet(`/api/card/${cardId}`, { primary: true })

  if (card != null) {
    return (
      <AppLayout
        name={card.number ?? 'Not Available'}
        contentHeader={
          <Header
            variant='h1'
          >
            Card # {card.number ?? 'Not Available'}
          </Header>
        }
        content={
          <SpaceBetween size='l'>
            <Summary card={card} />
            <Benefit card={card} />
            <AssociatedCards card={card} />
          </SpaceBetween>
        }
      />
    )
  }
}

function Summary ({ card }) {
  return (
    <Container
      header={
        <Header
          variant='h2'
        >
          Summary Information
        </Header>
      }
    >
      <ColumnLayout columns={4} borders='vertical'>
        <div>
          <Box variant='awsui-key-label'>
            Tribe
          </Box>
          <div>{card.tribe}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Source
          </Box>
          {card.application_id != null &&
            <Link href={`/admin/application/${card.application_id}`}>Application # {card.application_id}</Link>}
          {card.passive_enrollments.map((x) => {
            return (
              <div key={x.id}><Link href={`/admin/passive-enrollment/${x.id}`}>Passive Enrollment # {x.id}</Link></div>
            )
          })}
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Status
          </Box>
          <div>{card.active ? <Box color='text-status-success'>Active</Box> : <Box color='text-status-error'>Deactivated</Box>}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Cardholder Name
          </Box>
          <div>{card.cardholder_name}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            PIN
          </Box>
          <div>{card.pin}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Mailing Address
          </Box>
          <div>{card.mailed_address_line1}</div>
          {card.mailed_address_line2 != null && <div>{card.mailed_address_line2}</div>}
          <div>{card.mailed_address_city}, {card.mailed_address_state} {card.mailed_address_zip}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Created
          </Box>
          <div>{formatDateTime(card.created_at)}</div>
        </div>
      </ColumnLayout>
    </Container>
  )
}

function Benefit ({ card }) {
  return (
    <Container
      header={
        <Header
          variant='h2'
        >
          Benefit Information
        </Header>
      }
    >
      <ColumnLayout columns={4} borders='vertical'>
        <div>
          <Box variant='awsui-key-label'>
            Beneficiaries ({card.beneficiary_count})
          </Box>
          {card.passive_enrollments.map((x, i) =>
            <div key={'passive-enrollment-' + x.id}>
              <Link href={`/admin/passive-enrollment/${x.id}`}>{x.student_name}</Link>
            </div>
          )}
          {card.children.map((x, i) =>
            <div key={'child-' + x.id}>
              <Link href={`/admin/application/${x.application_id}`}>{x.name}</Link>
            </div>
          )}
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Benefits
          </Box>
          {card.benefits != null &&
            <div>
              <Grid
                gridDefinition={[
                  { colspan: 4 },
                  { colspan: 4 },
                  { colspan: 4 }
                ]}
                disableGutters
              >
                <div>Category</div>
                <div>Subcategory</div>
                <div>Quantity</div>
              </Grid>
              <Grid
                gridDefinition={[
                  { colspan: 4 },
                  { colspan: 4 },
                  { colspan: 4 }
                ]}
                disableGutters
              >
                <div>
                  {card.benefits.map((x, i) => <div key={'category-' + i}>{x[0]}</div>)}
                </div>
                <div>
                  {card.benefits.map((x, i) => <div key={'subcategory-' + i}>{x[1]}</div>)}
                </div>
                <div>
                  {card.benefits.map((x, i) => <div key={'quantity-' + i}>{x[2]}</div>)}
                </div>
              </Grid>
            </div>}
          {card.benefits == null && <div>-</div>}
        </div>
        <div>
          <Box variant='awsui-key-label'>
            First Date to Spend
          </Box>
          <div>{formatDate(card.first_date_to_spend)}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Last Date to Spend
          </Box>
          <div>{formatDate(card.last_date_to_spend)}</div>
        </div>
      </ColumnLayout>
    </Container>
  )
}

function AssociatedCards ({ card }) {
  if (card.associated_cards.length === 0) {
    return null
  }

  return (
    <Container
      header={
        <Header
          variant='h2'
        >
          Cards Associated with this Application
        </Header>
      }
    >
      <Grid
        gridDefinition={[
          { colspan: 4 },
          { colspan: 4 },
          { colspan: 4 }
        ]}
        disableGutters
      >
        <div>
          <Box variant='awsui-key-label'>
            Number
          </Box>
          {card.associated_cards.map((x, i) =>
            <div key={'associated-card-number-' + x.id}>
              <Link href={`/admin/card/${x.id}`}>{x.number}</Link>
            </div>
          )}
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Cardholder Name
          </Box>
          {card.associated_cards.map((x, i) => <div key={'associated-card-number-name-' + i}>{x.cardholder_name}</div>)}
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Status
          </Box>
          {card.associated_cards.map((x, i) =>
            <div key={'associated-card-number-status-' + i}>
              {x.active === true ? <Box color='text-status-success'>Active</Box> : <Box color='text-status-error'>Deactivated</Box>}
            </div>)}
        </div>
      </Grid>
    </Container>
  )
}
