import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet, usePost, AppContext, AppLayout, Box, Button, Checkbox, Container, Header, Select, SpaceBetween } from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'
import { Children } from './Children'
import { HouseholdEditor } from './HouseholdEditor'
import { HouseholdMembers } from './HouseholdMembers'
import { AdditionalInfo } from './AdditionalInfo'
import { PrimaryContactEditor } from './PrimaryContactEditor'
import { SecondaryContact } from './SecondaryContact'
import { Footer } from './Footer'
import { TopMenu } from '../common/TopMenu'
import { isNullOrWhitespace, validateAdditionalInfo } from '../common/utilities'
import { brandingOptions, cherokeeDomain, getDefaultBranding, isChangeable, TribeLogo } from '../common/branding'
import { DisabledMessage } from '../common/DisabledMessage'
import './Application.scss'
import '../layout.scss'

export function Application () {
  const { language, user, settings, translate } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const hostname = searchParams.get('cherokee') != null ? cherokeeDomain : window.location.hostname
  const [formValues, setFormValues] = useState({
    language: 'English',
    host: hostname,
    branding: hostname === cherokeeDomain ? 'Cherokee Nation' : 'default',
    agreeEsignature: false,
    consentToApplyOnBehalfOf: null,
    iCertify: false,
    bypassMailingAddress: false,
    primaryContact: {
      mailingAddress: {},
      physicalAddress: {},
      phone1: {},
      phone2: {},
      otherTribe: false,
      physicalAddressSameAsMailing: false
    },
    household: { snap: false, tanf: false, fdpir: false, medicaid: false, none: false, otherLanguageSpoken: false },
    householdMembers: [],
    files: [],
    children: [{ uuid: uuidv4(), otherTribe: false }]
  })
  const { data: userInfo } = useGet('/api/user/current')
  const create = usePost('/api/application', formValues, (resp) => {
    if (userInfo == null) {
      navigate(`/confirmation?language=${formValues.language}&number=${resp.id}&uuid=${resp.uuid}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/admin/application')
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (userInfo == null) {
      return
    }

    const defaultBranding = getDefaultBranding(userInfo.tribes)
    if (defaultBranding != null) {
      setFormValues({ ...formValues, branding: defaultBranding })
    }
  }, [userInfo])

  useEffect(() => {
    setFormValues({ ...formValues, language })
  }, [language])

  function getSuffixOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Jr', label: translate('Jr') },
      { value: 'Sr', label: translate('Sr') },
      { value: 'II', label: translate('II') },
      { value: 'III', label: translate('III') },
      { value: 'IV', label: translate('IV') }
    ]
  }

  function getPhoneTypeOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Home', label: translate('Home') },
      { value: 'Work', label: translate('Work') },
      { value: 'Mobile', label: translate('Mobile') }
    ]
  }

  function getRaceEthnicityOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Asian', label: translate('Asian') },
      { value: 'Black', label: translate('Black') },
      { value: 'Hispanic', label: translate('Hispanic') },
      { value: 'White', label: translate('White') },
      { value: 'Multiple', label: translate('Multiple') },
      { value: 'Native American', label: translate('Native American') },
      { value: 'Other', label: translate('Other') }
    ]
  }

  const tribeOptions = [
    { value: '', label: '-' },
    { value: 'Alabama Quassarte Tribal Town', label: 'Alabama Quassarte Tribal Town' },
    { value: 'Absentee Shawnee Tribe', label: 'Absentee Shawnee Tribe' },
    { value: 'Caddo Nation', label: 'Caddo Nation' },
    { value: 'Cherokee Nation', label: 'Cherokee Nation' },
    { value: 'Chickasaw Nation', label: 'Chickasaw Nation' },
    { value: 'Choctaw Nation', label: 'Choctaw Nation' },
    { value: 'Citizen Potawatomi Nation', label: 'Citizen Potawatomi Nation' },
    { value: 'Comanche Nation', label: 'Comanche Nation' },
    { value: 'Delaware Nation', label: 'Delaware Nation' },
    { value: 'Eastern Shawnee Tribe', label: 'Eastern Shawnee Tribe' },
    { value: 'Fort Sill Apache Tribe', label: 'Fort Sill Apache Tribe' },
    { value: 'Iowa Tribe', label: 'Iowa Tribe' },
    { value: 'Kaw Nation', label: 'Kaw Nation' },
    { value: 'Kickapoo Tribe', label: 'Kickapoo Tribe' },
    { value: 'Kiowa Tribe', label: 'Kiowa Tribe' },
    { value: 'Miami Tribe', label: 'Miami Tribe' },
    { value: 'Muscogee (Creek) Nation', label: 'Muscogee (Creek) Nation' },
    { value: 'Osage Nation', label: 'Osage Nation' },
    { value: 'Otoe-Missouria Tribe', label: 'Otoe-Missouria Tribe' },
    { value: 'Ottawa Tribe', label: 'Ottawa Tribe' },
    { value: 'Pawnee Nation', label: 'Pawnee Nation' },
    { value: 'Peoria Tribe of Indians', label: 'Peoria Tribe of Indians' },
    { value: 'Ponca Nation', label: 'Ponca Nation' },
    { value: 'Quapaw Tribe', label: 'Quapaw Tribe' },
    { value: 'Sac & Fox Nation', label: 'Sac & Fox Nation' },
    { value: 'Seminole Nation', label: 'Seminole Nation' },
    { value: 'Seneca-Cayuga Tribe', label: 'Seneca-Cayuga Tribe' },
    { value: 'Shawnee Tribe', label: 'Shawnee Tribe' },
    { value: 'Tonkawa Tribe', label: 'Tonkawa Tribe' },
    { value: 'United Keetoowah Band of Cherokees', label: 'United Keetoowah Band of Cherokees' },
    { value: 'Wichita & Affiliated Tribes', label: 'Wichita & Affiliated Tribes' },
    { value: 'Wyandotte Nation', label: 'Wyandotte Nation' },
    { value: 'Other', label: 'Other' }
  ]

  const roleOptions = [
    { value: 'Parent / Guardian', label: translate('Parent / Guardian') },
    { value: 'Non-student child', label: translate('Non-student child') },
    { value: 'Other household member', label: translate('Other household member') }
  ]

  const canChangeBranding = isChangeable(formValues.tribeId, userInfo)

  const { invalidTotalSize, invalidExtension, invalidSize } = validateAdditionalInfo(formValues.files)

  const form = (
    <form onSubmit={e => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction='horizontal' size='xs'>
            {userInfo != null &&
              <Button
                variant='link'
                onClick={() => navigate(searchParams.get('redirectURL') ?? '/')}
              >
                Cancel
              </Button>}
            {userInfo == null || formValues.consentToApplyOnBehalfOf === true
              ? (
                <Button
                  formAction='submit'
                  onClick={() => create()}
                  variant='primary'
                  disabled={
                    invalidTotalSize ||
                    invalidExtension.length > 0 ||
                    invalidSize.length > 0 ||
                    formValues.iCertify !== true ||
                    formValues.agreeEsignature !== true ||
                    isNullOrWhitespace(formValues.signature)
                  }
                  disabledReason={
                    invalidTotalSize
                      ? translate('Total file size in additional info exceeds maximum allowed.')
                      : invalidExtension.length > 0
                        ? translate('One or more files in additional information has an invalid type.')
                        : invalidSize.length > 0
                          ? translate('One or more files in additional information exceeds the maximum file size.')
                          : formValues.iCertify !== true
                            ? 'You must certify that all information is true and correct'
                            : formValues.agreeEsignature !== true
                              ? 'You must agree to the eSignature'
                              : isNullOrWhitespace(formValues.signature)
                                ? 'Signature is required'
                                : null
                  }
                >
                  {translate('Submit Application')}
                </Button>
                )
              : null}
          </SpaceBetween>
        }
      >
        <SpaceBetween size='l'>
          {userInfo != null && formValues.consentToApplyOnBehalfOf !== true &&
            <div style={{ paddingTop: '60px' }}>
              <Box textAlign='center'>
                <FormField field='consentToApplyOnBehalfOf'>
                  <Checkbox
                    checked={formValues.consentToApplyOnBehalfOf ?? false}
                    onChange={({ detail }) => {
                      setFormValues({ ...formValues, consentToApplyOnBehalfOf: detail.checked })
                    }}
                  >
                    {translate('I have the applicant\'s permission to submit this application on their behalf.')}
                  </Checkbox>
                </FormField>
              </Box>
            </div>}

          {(userInfo == null || formValues.consentToApplyOnBehalfOf === true) &&
            <>
              <TribeLogo branding={formValues.branding} />

              {canChangeBranding &&
                <FormField label='Application Branding' field='branding' stretch>
                  <Select
                    filteringType='auto'
                    selectedOption={brandingOptions.find(x => x.value === formValues.branding)}
                    onChange={({ detail }) => setFormValues({ ...formValues, branding: detail.selectedOption.value })}
                    options={brandingOptions}
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Choose branding'
                  />
                </FormField>}

              <Container
                header={
                  <Header variant='h2'>
                    {translate('Primary Contact Information')}
                  </Header>
                }
              >
                <PrimaryContactEditor
                  primaryContact={formValues.primaryContact}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  suffixOptions={getSuffixOptions()}
                  phoneTypeOptions={getPhoneTypeOptions()}
                  raceEthnicityOptions={getRaceEthnicityOptions()}
                  roleOptions={roleOptions}
                  tribeOptions={tribeOptions}
                  translate={translate}
                />
              </Container>

              <SecondaryContact
                secondaryContact={formValues.secondaryContact}
                formValues={formValues}
                setFormValues={setFormValues}
                suffixOptions={getSuffixOptions()}
                phoneTypeOptions={getPhoneTypeOptions()}
                translate={translate}
              />

              <Container
                field='household'
                header={<Header variant='h2'>{translate('Household Information')}</Header>}
              >
                <HouseholdEditor
                  household={formValues.household}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  translate={translate}
                />
              </Container>

              <Children
                formValues={formValues}
                setFormValues={setFormValues}
                suffixOptions={getSuffixOptions()}
                raceEthnicityOptions={getRaceEthnicityOptions()}
                tribeOptions={tribeOptions}
                translate={translate}
              />

              <HouseholdMembers
                formValues={formValues}
                setFormValues={setFormValues}
                suffixOptions={getSuffixOptions()}
                raceEthnicityOptions={getRaceEthnicityOptions()}
                roleOptions={roleOptions}
                tribeOptions={tribeOptions}
                translate={translate}
              />

              <AdditionalInfo
                formValues={formValues}
                setFormValues={setFormValues}
                translate={translate}
              />

              <ConsentAndSignature
                formValues={formValues}
                setFormValues={setFormValues}
                translate={translate}
              />

              <Footer translate={translate} />
            </>}
        </SpaceBetween>
      </Form>
    </form>
  )

  if (settings == null) {
    return null
  }

  if (user == null) {
    const domainSettings = settings[hostname] ?? settings.default
    if (domainSettings?.publicApplicationDisabled ?? false) {
      return <DisabledMessage settings={domainSettings} />
    }

    return (
      <>
        {formValues.branding === 'default' && <TopMenu />}

        <section className='main_div eligibility_blue' style={{ margin: '0 0 50px 0' }}>
          <div className='container-fluid px-lg-5'>
            <h1 className='normal-case white' style={{ fontSize: '28px' }}>
              {translate('Welcome! Please complete one form for your entire household, listing all children and other adults living in the household.')}
            </h1>
          </div>
        </section>

        <section>
          <div className='container-fluid px-lg-5'>{form}</div>
        </section>
      </>
    )
  }

  if (userInfo != null) {
    return (
      <AppLayout
        name={translate('Application')}
        contentHeader={
          <Header variant='h1'>
            {translate('New Application')}
          </Header>
        }
        content={form}
      />
    )
  }
}

function ConsentAndSignature ({ formValues, setFormValues, translate }) {
  return (
    <Container
      header={
        <Header
          variant='h2'
        >
          {translate('Before submitting your application, please review the following information.')}
        </Header>
      }
    >
      <SpaceBetween size='s'>
        <Box color='text-body-secondary'>
          <SpaceBetween size='xxs'>
            <span>{translate('The Richard B. Russell National School Lunch Act requires the information on this application. You do not have to give the information, but if you do not, we cannot approve your child for free and reduced-price meals. We will use your information to determine if your child is eligible for Summer EBT benefits.')}</span>
            <span>{translate('The Summer EBT program MAY share your eligibility information with education, health, and nutrition programs to help them evaluate, fund, or determine benefits for their programs, auditors for program reviews, and law enforcement officials to help them look into violations of program rules.')}</span>
            <span>{translate('Foster, migrant, homeless, and runaway children, and children enrolled in a Head Start program, are categorically eligible for free meals and free milk. If you are completing an application for these children, contact the school for more information.')}</span>
            <span>{translate('Participation in this program will have no impact on other government assistance you and your child receive.')}</span>
          </SpaceBetween>
        </Box>
        <FormField field='iCertify' stretch label={translate('I certify that all information furnished in the application is true and correct, that the application is being made in connection with the receipt of Federal funds, that school officials may verify the information on the application, and that deliberate misrepresentation of the information may subject me to prosecution under applicable State and Federal criminal statutes.')} screenReader>
          <Checkbox
            checked={formValues.iCertify}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, iCertify: detail.checked })
            }}
          >
            {translate('I certify that all information furnished in the application is true and correct, that the application is being made in connection with the receipt of Federal funds, that school officials may verify the information on the application, and that deliberate misrepresentation of the information may subject me to prosecution under applicable State and Federal criminal statutes.')}
          </Checkbox>
        </FormField>
        <FormField field='agreeEsignature' stretch label={translate('Electronically signing this form shall be considered as valid as a physical signature.')} screenReader>
          <Checkbox
            checked={formValues.agreeEsignature}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, agreeEsignature: detail.checked })
            }}
          >
            {translate('Electronically signing this form shall be considered as valid as a physical signature.')}
          </Checkbox>
        </FormField>

        <FormField label={translate('eSignature')} field='signature' description={translate('Type your full name below to electronically sign.')}>
          <div className='signature-container'>
            <input
              id='formField:r44:'
              aria-labelledby='formField:r44:-label'
              className='signature'
              aria-required
              value={formValues.signature ?? ''}
              onChange={e => {
                setFormValues({ ...formValues, signature: e.target.value })
              }}
            />
          </div>
        </FormField>
      </SpaceBetween>
    </Container>
  )
}
