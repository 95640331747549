import React, { useContext, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGet, AppContext, Alert, Box, Button, ColumnLayout, Container, Divider, Header, SpaceBetween } from 'rad-framework-ui'
import { formatCurrency, formatDate, formatDateTime } from '../common/utilities'
import { Footer } from './Footer'
import { TopMenu } from '../common/TopMenu'
import { DisabledMessage } from '../common/DisabledMessage'
import { showLactoseFree, showPin, TribeLogo } from '../common/branding'

export function View () {
  const { uuid } = useParams()
  const navigate = useNavigate()
  const { settings, translate } = useContext(AppContext)
  const { data: application } = useGet(`/api/application/uuid/${uuid}`, { primary: true })

  if (application != null) {
    const domainSettings = settings[window.location.hostname] ?? settings.default
    if (domainSettings?.publicApplicationDisabled) {
      return <DisabledMessage settings={domainSettings} />
    }

    return (
      <>
        {application.branding === 'default' && <TopMenu />}

        <section className='main_div eligibility_blue' style={{ margin: '0 0 50px 0' }}>
          <div className='container-fluid px-lg-5'>
            <h1 className='normal-case white' style={{ fontSize: '28px' }}>
              {translate('Application')} # {application.id}
              {application.statuses.length === 0 && <Button className='edit' onClick={() => navigate(`/edit/${uuid}`)}>{translate('Edit')}</Button>}
              {application.statuses.length > 0 && application.statuses[0].status !== 'accepted' && application.statuses[0].status !== 'rejected' && <div className='edit'>{translate('This application is being processed and can no longer be edited.')}</div>}
              {application.statuses.length > 0 && application.statuses[0].status === 'accepted' && <div>{translate('Your application for Summer EBT benefits has been accepted. An EBT card will be mailed to your mailing address.')}</div>}
              {application.statuses.length > 0 && application.statuses[0].status === 'rejected' && <div>{translate('Your application for Summer EBT benefits has been processed. Unfortunately, you are not eligible for benefits through the Muscogee Nation.')}</div>}
            </h1>
          </div>
        </section>

        <section>
          <div className='container-fluid px-lg-5'>
            <SpaceBetween size='l'>

              <TribeLogo branding={application.branding} />

              {/* Primary Contact Information */}
              <Container
                header={
                  <Header variant='h2'>
                    {translate('Primary Contact Information')}
                  </Header>
                }
              >
                <ColumnLayout columns={4} borders='vertical'>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Name')}
                    </Box>
                    <div>{`${application.primaryContact.firstName} ${application.primaryContact.middleName ? application.primaryContact.middleName + ' ' : ''} ${application.primaryContact.lastName} ${application.primaryContact.suffix ?? ''}`}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Race / Ethnicity')}
                    </Box>
                    <div>{translate(application.primaryContact.raceEthnicity ?? '-')}</div>
                  </div>
                  {application.primaryContact.tribe != null &&
                    <div>
                      <Box variant='awsui-key-label'>
                        {translate('Tribe')}
                      </Box>
                      <div>{application.primaryContact.tribe}</div>
                    </div>}
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Birth Date')}
                    </Box>
                    <div>{formatDate(application.primaryContact.birthDate)}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Mailing Address')}
                    </Box>
                    <div>{application.primaryContact.mailingAddress.line1}</div>
                    <div>{application.primaryContact.mailingAddress.line2}</div>
                    <div>{application.primaryContact.mailingAddress.city}, {application.primaryContact.mailingAddress.state} {application.primaryContact.mailingAddress.zip}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Physical Address')}
                    </Box>
                    <div>{application.primaryContact.physicalAddress.line1}</div>
                    <div>{application.primaryContact.physicalAddress.line2}</div>
                    <div>{application.primaryContact.physicalAddress.city}, {application.primaryContact.physicalAddress.state} {application.primaryContact.physicalAddress.zip}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Email')}
                    </Box>
                    <div>{application.primaryContact.email ? <Link href={`mailto:${application.primaryContact.email}`}>{application.primaryContact.email}</Link> : '-'}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Phone(s)')}
                    </Box>
                    <div>{application.primaryContact.phone1.number} - {application.primaryContact.phone1.type}</div>
                    {application.primaryContact.phone2 != null && <div>{application.primaryContact.phone2?.number} - {application.primaryContact.phone2?.type}</div>}
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Consent to be Contacted')}
                    </Box>
                    <div>{application.primaryContact.consentToContact ? translate('Yes') : 'No'}</div>
                  </div>
                  {application.primaryContact.preferredContactMethod != null &&
                    <div>
                      <Box variant='awsui-key-label'>
                        {translate('Preferred Contact Method')}
                      </Box>
                      <div>{application.primaryContact.preferredContactMethod}</div>
                    </div>}
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Member of Household') + ' / ' + translate('Role')}
                    </Box>
                    <div>{application.primaryContact.householdMember ? `Yes - ${application.primaryContact.role}` : 'No'}</div>
                  </div>
                </ColumnLayout>
              </Container>

              {/* Secondary Contact Information */}
              <Container
                header={
                  <Header variant='h2'>
                    {translate('Secondary Contact Information')}
                  </Header>
                }
              >
                {application.secondaryContact == null && <Box color='text-status-inactive'>No secondary contact information provided.</Box>}
                {application.secondaryContact != null &&
                  <ColumnLayout columns={3} borders='vertical'>
                    <div>
                      <Box variant='awsui-key-label'>
                        {translate('Name')}
                      </Box>
                      <div>{`${application.secondaryContact.firstName} ${application.secondaryContact.middleName ? application.secondaryContact.middleName + ' ' : ''} ${application.secondaryContact.lastName} ${application.secondaryContact.suffix ?? ''}`}</div>
                    </div>
                    <div>
                      <Box variant='awsui-key-label'>
                        {translate('Email')}
                      </Box>
                      <div>{application.secondaryContact.email ? <Link href={`mailto:${application.secondaryContact.email}`}>{application.secondaryContact.email}</Link> : '-'}</div>
                    </div>
                    <div>
                      <Box variant='awsui-key-label'>
                        {translate('Phone')}
                      </Box>
                      <div>{application.secondaryContact.phone?.number} - {application.secondaryContact.phone?.type}</div>
                    </div>
                  </ColumnLayout>}
              </Container>

              {/* Household Information */}
              <Container
                header={
                  <Header variant='h2'>
                    {translate('Household Information')}
                  </Header>
                }
              >
                <ColumnLayout columns={4} borders='vertical'>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Primary Language Spoken')}
                    </Box>
                    <div>{application.household.primaryLanguageSpoken}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Program(s)')}
                    </Box>
                    <div>{application.household.snap && 'SNAP'}</div>
                    <div>{application.household.tanf && 'TANF'}</div>
                    <div>{application.household.fdpir && 'FDPIR'}</div>
                    <div>{application.household.medicaid && 'Medicaid'}</div>
                    <div>{!application.household.snap && !application.household.tanf && !application.household.fdpir && !application.household.medicaid && '-'}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Income')}
                    </Box>
                    <div>{formatCurrency(application.household.incomeAmount)} / {application.household.incomeFrequency}</div>
                  </div>
                  {showPin(application.branding) &&
                    <div>
                      <Box variant='awsui-key-label'>
                        {translate('PIN')}
                      </Box>
                      <div>{application.household.pin ?? '-'}</div>
                    </div>}
                </ColumnLayout>
              </Container>

              {/* Child Information */}
              <Container
                header={
                  <Header variant='h2'>
                    {translate('Child Information')}
                  </Header>
                }
              >
                {application.children.map((child, index) => (
                  <div key={'child-' + child.id}>
                    {index > 0 && <br />}
                    {index > 0 && <Divider />}
                    <ColumnLayout columns={4} borders='vertical'>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Name')}
                        </Box>
                        <div>{`${child.firstName} ${child.middleName ? child.middleName + ' ' : ''} ${child.lastName} ${child.suffix ?? ''}`}</div>
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Race / Ethnicity')}
                        </Box>
                        <div>{translate(child.raceEthnicity ?? '-')}</div>
                      </div>
                      {child.tribe != null &&
                        <div>
                          <Box variant='awsui-key-label'>
                            {translate('Tribe')}
                          </Box>
                          <div>{child.tribe}</div>
                        </div>}
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Birth Date')}
                        </Box>
                        <div>{formatDate(child.birthDate)}</div>
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Grade')}
                        </Box>
                        <div>{child.grade}</div>
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('School')}
                        </Box>
                        {child.schoolDistrict != null && <div>{child.schoolDistrict.name} SCHOOL DISTRICT<br />{child.schoolDistrict.county != null ? `${child.schoolDistrict.county} County` : ''} </div>}
                        {child.homeSchool && <div>Home School</div>}
                        {child.otherSchool && <div>Other</div>}
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Foster Child?')}
                        </Box>
                        <div>{child.fosterChild ? translate('Yes') : 'No'}</div>
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Homeless / Migrant / Runaway?')}
                        </Box>
                        <div>{child.homelessMigrantRunaway ? translate('Yes') : 'No'}</div>
                      </div>
                      {showLactoseFree(application.branding) &&
                        <div>
                          <Box variant='awsui-key-label'>
                            Lactose Free
                          </Box>
                          <div>{child.lactoseFree === true ? 'Yes' : child.lactoseFree === false ? 'No' : '-'}</div>
                        </div>}
                    </ColumnLayout>
                  </div>
                ))}
              </Container>

              {/* Household Member Information */}
              <Container
                header={
                  <Header variant='h2'>
                    {translate('Household Member Information')}
                  </Header>
                }
              >
                {application.householdMembers.length === 0 && <Box color='text-status-inactive'>{translate('No other household members provided')}.</Box>}
                {application.householdMembers.map((member, index) => (
                  <div key={'member-' + member.id}>
                    {index > 0 && <br />}
                    {index > 0 && <Divider />}
                    <ColumnLayout columns={3} borders='vertical'>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Name')}
                        </Box>
                        <div>{`${member.firstName} ${member.middleName ? member.middleName + ' ' : ''} ${member.lastName} ${member.suffix ?? ''}`}</div>
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Race / Ethnicity')}
                        </Box>
                        <div>{translate(member.raceEthnicity ?? '-')}</div>
                      </div>
                      {member.tribe != null &&
                        <div>
                          <Box variant='awsui-key-label'>
                            {translate('Tribe')}
                          </Box>
                          <div>{member.tribe}</div>
                        </div>}
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Birth Date')}
                        </Box>
                        <div>{formatDate(member.birthDate)}</div>
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Role')}
                        </Box>
                        <div>{translate(member.role)}</div>
                      </div>
                    </ColumnLayout>
                  </div>
                ))}
              </Container>

              <AdditionalInformation application={application} />

              {/* E-Signature(s) */}
              <Container
                header={
                  <Header variant='h2'>
                    {translate('eSignature')}(s)
                  </Header>
                }
              >
                <SpaceBetween size='l'>
                  <div className='signature-container'>
                    <div className='signature' style={{ lineHeight: '38px' }}>{application.signature ?? ''}</div>
                    <div className='date'>{formatDateTime(application.createdAt)}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      {translate('Electronically signing this form shall be considered as valid as a physical signature.')}
                    </Box>
                    <div>{application.agreeEsignature === true ? translate('Yes') : 'No'}</div>
                  </div>
                </SpaceBetween>
                {application.signature2 != null &&
                  <>
                    <Divider />
                    <SpaceBetween size='l'>
                      <div className='signature-container'>
                        <div className='signature' style={{ lineHeight: '38px' }}>{application.signature2 ?? ''}</div>
                        <div className='date'>{formatDateTime(application.updatedAt)}</div>
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>
                          {translate('Electronically signing this form shall be considered as valid as a physical signature.')}
                        </Box>
                        <div>{application.agreeEsignature2 === true ? translate('Yes') : 'No'}</div>
                      </div>
                    </SpaceBetween>
                  </>}
              </Container>

              <Footer translate={translate} />
            </SpaceBetween>
          </div>
        </section>
      </>
    )
  }
}

function AdditionalInformation ({ application }) {
  const { translate } = useContext(AppContext)
  const [error, setError] = useState()

  function truncateText (text) {
    const MAX_LENGTH = 35
    if (text.length <= MAX_LENGTH) {
      return text
    }
    return text.slice(0, MAX_LENGTH) + '...' + text.split('.').pop()
  }

  async function downloadFile (item) {
    const response = await fetch(`/api/application/uuid/${application.uuid}/file/${item.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/octet-stream'
      }
    })
    if (response.status !== 200) {
      setError({ message: 'Unable to download file. Please contact administrator.' })
      return
    }
    if (error != null) setError(null)
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = item.name
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
  }

  return (
    <Container
      header={
        <Header variant='h2'>
          {translate('Additional Information')}
        </Header>
      }
    >
      <SpaceBetween size='l'>
        {application.files.length === 0 && <Box color='text-status-inactive'>{translate('No additional information provided')}.</Box>}
        {application.files.length > 0 &&
          <SpaceBetween size='xs'>
            {application.files.map((file, index) => (
              <SpaceBetween direction='horizontal' size='s' key={`file-${file.uuid}-${file.id}`}>
                <Box>
                  {truncateText(file.name)}
                </Box>
                <Button
                  onClick={() => downloadFile(file)}
                  iconName='download'
                  variant='inline-icon'
                >
                  Download
                </Button>
              </SpaceBetween>
            ))}
          </SpaceBetween>}
        {error != null &&
          <Alert
            statusIconAriaLabel='Error'
            type='error'
          >
            {translate(error.message)}
          </Alert>}
      </SpaceBetween>
    </Container>
  )
}
