import * as XLSX from 'xlsx/xlsx.mjs'

export async function exportToExcel ({
  uri,
  columnNames,
  mapper,
  filenamePrefix,
  worksheetName,
  user,
  error,
  setError,
  setProgress,
  cancelRef
}) {
  if (error != null) {
    setError(null)
  }

  const data = [columnNames]
  const batchSize = 1000

  async function fetchBatch (batch) {
    try {
      const token = await user.getIdToken()
      const response = await fetch(`${uri}&limit=${batchSize}&offset=${(batch - 1) * batchSize}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response.status !== 200) {
        throw new Error(`Unexpected status from exportToExcel (${uri}): ${response.status}`)
      } else {
        const rowCount = parseInt(response.headers.get('count'))
        const json = await response.json()
        setProgress((batch - 1) * batchSize / rowCount * 100)
        if (json.length === 0) {
          return true // success
        } else {
          data.push(...mapper(json))
          return await fetchBatch(batch + 1)
        }
      }
    } catch (e) {
      setError({ message: 'Error fetching applications. Please try again later.' })
      console.error(e)
      return false // failure
    }
  }

  const success = await fetchBatch(1)

  if (!success) {
    return null
  }

  setProgress(null) // Dismiss progress bar (we're done!)

  if (cancelRef.current) {
    cancelRef.current = false
  }

  const now = new Date()
  const year = now.getFullYear()
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const date = now.getDate().toString().padStart(2, '0')
  const fileName = `${filenamePrefix}_${year}_${month}_${date}.xlsx`

  const worksheet = XLSX.utils.aoa_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName)
  XLSX.writeFile(workbook, fileName, { compression: true })
}
