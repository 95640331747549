
    import './styles.scoped.css';
    export default {
  "direction-button-wrapper": "awsui_direction-button-wrapper_155yk_1x7ys_170",
  "direction-button-wrapper-motion-enter": "awsui_direction-button-wrapper-motion-enter_155yk_1x7ys_180",
  "direction-button-wrapper-motion-entering": "awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181",
  "direction-button-wrapper-motion-exit": "awsui_direction-button-wrapper-motion-exit_155yk_1x7ys_182",
  "direction-button-wrapper-motion-exiting": "awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183",
  "direction-button-wrapper-block-start": "awsui_direction-button-wrapper-block-start_155yk_1x7ys_206",
  "slide-up": "awsui_slide-up_155yk_1x7ys_1",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_155yk_1x7ys_1",
  "slide-up-exit": "awsui_slide-up-exit_155yk_1x7ys_1",
  "awsui-motion-fade-out-0": "awsui_awsui-motion-fade-out-0_155yk_1x7ys_1",
  "direction-button-wrapper-block-end": "awsui_direction-button-wrapper-block-end_155yk_1x7ys_233",
  "slide-down": "awsui_slide-down_155yk_1x7ys_1",
  "slide-down-exit": "awsui_slide-down-exit_155yk_1x7ys_1",
  "direction-button-wrapper-inline-start": "awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260",
  "slide-left": "awsui_slide-left_155yk_1x7ys_1",
  "direction-button-wrapper-rtl": "awsui_direction-button-wrapper-rtl_155yk_1x7ys_273",
  "slide-right": "awsui_slide-right_155yk_1x7ys_1",
  "slide-left-exit": "awsui_slide-left-exit_155yk_1x7ys_1",
  "slide-right-exit": "awsui_slide-right-exit_155yk_1x7ys_1",
  "direction-button-wrapper-inline-end": "awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313",
  "drag-handle-wrapper": "awsui_drag-handle-wrapper_155yk_1x7ys_430",
  "portal-overlay": "awsui_portal-overlay_155yk_1x7ys_435",
  "portal-overlay-contents": "awsui_portal-overlay-contents_155yk_1x7ys_443",
  "drag-handle": "awsui_drag-handle_155yk_1x7ys_430",
  "direction-button-wrapper-hidden": "awsui_direction-button-wrapper-hidden_155yk_1x7ys_460",
  "direction-button": "awsui_direction-button_155yk_1x7ys_170",
  "direction-button-disabled": "awsui_direction-button-disabled_155yk_1x7ys_503"
};
  