import React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGet,
  Box,
  Link,
  SpaceBetween
} from 'rad-framework-ui'
import { AdaptiveList } from '../common/AdaptiveList'
import { Multiselect } from '../common/Multiselect'
import { formatDate, formatDateTime } from '../common/utilities'

export function CardList () {
  const [searchParams, setSearchParams] = useSearchParams()
  const status = searchParams.get('status') ?? ''
  const tribe = searchParams.get('tribe') ?? ''
  const { data: userInfo } = useGet('/api/user/current')
  const { data: tribeOptions } = useGet('/api/option/tribe')

  const statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Deactivated', value: 'deactivated' }
  ]

  if (userInfo == null || tribeOptions == null) return null

  const selectedStatusOptions = statusOptions.filter(x => status.split('-').includes(x.value))
  const filteredTribeOptions = tribeOptions
    .filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
  const selectedTribeOptions = filteredTribeOptions.filter(x => tribe.split('-').includes(x.value))

  return (
    <AdaptiveList
      entity='card'
      rootHref='/admin'
      description='You can search by card number, cardholder name, cardholder birth date, mailing address, passive enrollment number, or application number.'
      create={false}
      edit={false}
      fields={[
        { header: 'Card Number', name: 'number', link: true },
        { header: 'Cardholder Name', name: 'cardholder_name' },
        { header: 'Birth Date', content: (item) => formatDate(item.cardholder_birth_date) },
        {
          header: 'Address',
          content: (item) =>
            <SpaceBetween>
              <div>{item.mailed_address_line1}</div>
              {item.mailed_address_line2 && <div>{item.mailed_address_line2}</div>}
              <div>{item.mailed_address_city}, {item.mailed_address_state} {item.mailed_address_zip}</div>
            </SpaceBetween>
        },
        { header: 'PIN', name: 'pin' },
        {
          header: 'Status',
          content: (item) => item.active ? <Box color='text-status-success'>Active</Box> : <Box color='text-status-error'>Deactivated</Box>
        },
        {
          header: 'Source',
          content: (item) =>
            <SpaceBetween size='xxs'>
              {item.application_id && <Link href={`/admin/application/${item.application_id}`}>App #{item.application_id}</Link>}
              {item.passive_enrollments.map(x => <Link key={'pe-' + x.id} href={`/admin/passive-enrollment/${x.id}`}>PE #{x.id}</Link>)}
            </SpaceBetween>
        },
        'tribe',
        {
          header: 'Created',
          content: (item) => formatDateTime(item.created_at)
        }
      ]}
      filter={
        <SpaceBetween size='m' direction='horizontal'>
          <Multiselect
            onChange={({ detail }) => {
              searchParams.delete('page')
              searchParams.set('status', detail.selectedOptions.map(x => x.value).join('-'))
              setSearchParams(searchParams)
            }}
            options={statusOptions}
            selectedOptions={selectedStatusOptions}
            placeholder='Choose a status'
          />
          <Multiselect
            onChange={({ detail }) => {
              searchParams.delete('page')
              if (detail.selectedOptions.length === 0) {
                searchParams.delete('tribe')
              } else {
                searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
              }
              setSearchParams(searchParams)
            }}
            options={filteredTribeOptions}
            selectedOptions={selectedTribeOptions}
            placeholder='Choose a Tribe'
          />
        </SpaceBetween>
        }
    />
  )
}
