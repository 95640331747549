
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_nyka2_153",
  "button": "awsui_button_vjswe_nyka2_157",
  "variant-normal": "awsui_variant-normal_vjswe_nyka2_205",
  "disabled": "awsui_disabled_vjswe_nyka2_223",
  "variant-primary": "awsui_variant-primary_vjswe_nyka2_230",
  "variant-link": "awsui_variant-link_vjswe_nyka2_255",
  "variant-icon": "awsui_variant-icon_vjswe_nyka2_280",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_nyka2_305",
  "variant-inline-icon-pointer-target": "awsui_variant-inline-icon-pointer-target_vjswe_nyka2_330",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_nyka2_355",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_nyka2_384",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_nyka2_409",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_nyka2_434",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_nyka2_464",
  "button-no-text": "awsui_button-no-text_vjswe_nyka2_556",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_nyka2_560",
  "full-width": "awsui_full-width_vjswe_nyka2_563",
  "icon-left": "awsui_icon-left_vjswe_nyka2_585",
  "icon-right": "awsui_icon-right_vjswe_nyka2_590",
  "icon": "awsui_icon_vjswe_nyka2_585",
  "link": "awsui_link_vjswe_nyka2_609",
  "disabled-with-reason": "awsui_disabled-with-reason_vjswe_nyka2_609"
};
  