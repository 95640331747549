import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGet,
  usePost,
  AppContext,
  Box,
  Button,
  FormField,
  Link,
  Modal,
  Select,
  SpaceBetween
} from 'rad-framework-ui'
import { AdaptiveList } from '../common/AdaptiveList'
import { Multiselect } from '../common/Multiselect'

export function IssuanceHistoryList () {
  const [searchParams, setSearchParams] = useSearchParams()
  const { error, setError, reloadCounter, setReloadCounter, user } = useContext(AppContext)
  const [tribeId, setTribeId] = useState(null)
  const filterTribe = searchParams.get('tribe') ?? ''
  const [showRunModal, setShowRunModal] = useState(false)
  const { data: userInfo } = useGet('/api/user/current')
  const { data: tribeOptions } = useGet('/api/option/tribe')

  useEffect(() => {
    if (userInfo?.tribes.length === 1) {
      setTribeId(userInfo.tribes[0].id.toString())
    }
  }, [userInfo, tribeOptions])

  function dismissRunModal () {
    setError(null)
    setShowRunModal(false)
    setTribeId(null)
  }

  function dismissErrorModal () {
    setError(null)
    setReloadCounter(reloadCounter + 1)
    setTribeId(null)
  }

  async function downloadFile (item) {
    const token = await user.getIdToken()
    const response = await fetch(`/api/issuance-history/file/${item.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/octet-stream'
      }
    })
    if (response.status !== 200) {
      setError({ message: 'Unable to download file. Please contact administrator.' })
      return
    }
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = item.gcsPath.split('/').pop()
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
  }

  if (userInfo == null || tribeOptions == null) return null

  const filteredTribeOptions = tribeOptions
    .filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
  const selectedTribeOptions = filteredTribeOptions.filter(x => filterTribe.split('-').includes(x.value))

  return (
    <>
      <AdaptiveList
        title='Issuance Admin'
        entity='issuance-history'
        rootHref='/admin'
        create={false}
        edit={false}
        search={false}
        filter={
          <Multiselect
            onChange={({ detail }) => {
              if (detail.selectedOptions.length === 0) {
                searchParams.delete('tribe')
              } else {
                searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
              }
              setSearchParams(searchParams)
            }}
            options={filteredTribeOptions}
            selectedOptions={selectedTribeOptions}
            placeholder='Choose a Tribe'
          />
        }
        actions={[
          { label: 'Run Issuance', onClick: () => { setError(null); setShowRunModal(true) } }
        ]}
        fields={[
          { header: '#', name: 'id' },
          {
            header: 'Cards',
            content: (item) => Number(item.cards).toLocaleString()
          },
          {
            header: 'Beneficiaries',
            content: (item) => Number(item.beneficiaries).toLocaleString()
          },
          {
            header: 'Passive Enrollments',
            content: (item) => Number(item.passiveEnrollments).toLocaleString()
          },
          {
            header: 'Applications',
            content: (item) => Number(item.applications).toLocaleString()
          },
          {
            header: 'User',
            content: (item) => <Link href={`/admin/user/${item.creator.id}`}>{item.creator.name}</Link>
          },
          'tribe.name',
          {
            header: 'Date',
            content: (item) => new Date(item.createdAt).toLocaleString()
          },
          {
            header: 'Actions',
            content: (item) =>
              <Button
                onClick={() => downloadFile(item)}
                iconName='download'
                variant='inline-icon'
              >
                Download
              </Button>
          }
        ]}
      />
      <RunModal
        showRunModal={showRunModal}
        dismissRunModal={dismissRunModal}
        tribeId={tribeId}
        setTribeId={setTribeId}
        filteredTribeOptions={filteredTribeOptions}
      />

      <DismissErrorModal dismissErrorModal={dismissErrorModal} error={error} />
    </>
  )
}

function RunModal ({ showRunModal, dismissRunModal, tribeId, setTribeId, filteredTribeOptions }) {
  const run = usePost(`/api/issuance-history/run/${tribeId}`, {}, () => { dismissRunModal() })

  return (
    <Modal
      size='medium'
      onDismiss={() => { dismissRunModal() }}
      visible={showRunModal}
      header='Run Issuance'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='s'>
            <Box padding={{ top: 'xs' }}>
              <Button
                onClick={() => dismissRunModal()}
                variant='inline-link'
              >
                Cancel
              </Button>
            </Box>
            <Button
              onClick={() => run()}
              variant='primary'
              disabled={tribeId == null}
            >
              Run
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size='l'>
        <FormField label='Tribe' field='tribeId' required>
          <Select
            filteringType='auto'
            selectedOption={filteredTribeOptions.find(x => x.value === tribeId)}
            onChange={({ detail }) => setTribeId(detail.selectedOption.value)}
            options={filteredTribeOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a Tribe'
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  )
}

function DismissErrorModal ({ dismissErrorModal, error }) {
  return (
    <Modal
      size='medium'
      onDismiss={() => dismissErrorModal()}
      visible={error != null}
      header='Error'
      footer={
        <Box float='right'>
          <Button
            onClick={() => dismissErrorModal()}
            variant='primary'
          >
            Dismiss
          </Button>
        </Box>
      }
    >
      <Box color='text-status-error'>{error?.message}</Box>
    </Modal>
  )
}
