import moment from 'moment'

export function concatWs (arr, separator) {
  if (arr == null) {
    return ''
  }
  // filter out nulls/undefined
  const filtered = arr.filter(x => x != null)
  return filtered.join(separator)
}

export function format (data, format, classes) {
  let pos = 0
  const acc = []
  const done = () => `${data.slice(0, pos)}${acc.join('')}`

  // Step 1: Take correctly-formatted prefix
  const matches = () => Object.hasOwn(classes, format[pos])
    ? classes[format[pos]](data[pos])
    : format[pos] === data[pos]
  const maxLen = Math.min(format.length, data.length)
  while (pos < maxLen && matches()) { pos++ }
  if (pos === maxLen) { return done() }

  // Step 2: Consume the rest according to the format
  let posF = pos
  let posD = pos
  const findNext = (f) => {
    while (posD < data.length) {
      const c = data[posD]
      posD++
      if (f(c)) { return c }
    }
    return null
  }
  while (posF < format.length && posD < data.length) {
    // if (classes.hasOwnProperty(format[posF])) {
    if (Object.hasOwn(classes, format[posF])) {
      const c = findNext(classes[format[posF]])
      if (c == null) { return done() }
      acc.push(c)
    } else {
      acc.push(format[posF])
    }
    posF++
  }
  return done()
}

export const getColor = (text) => {
  switch (text) {
    case 'accepted':
      return 'text-status-success'
    case 'rejected':
    case 'declined':
      return 'text-status-error'
    case 'issued':
      return 'text-status-info'
    case 'escalated':
      return 'text-status-warning'
    default:
      return null
  }
}

export function trimStringsAndRemoveEmpty (obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      const trimmed = obj[key].trim()
      if (trimmed === '') {
        obj[key] = null
      } else {
        obj[key] = trimmed
      }
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map(item => {
        if (typeof item === 'string') {
          return item.trim()
        } else if (typeof item === 'object' && item !== null) {
          return trimStringsAndRemoveEmpty(item)
        }
        return item
      }).filter(item => item !== '') // Filter out empty strings
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = trimStringsAndRemoveEmpty(obj[key])
    }
  })
  return obj
}

export function formatCurrency (inputString) {
  if (inputString == null) {
    return null
  }
  const number = parseFloat(inputString)
  return `$${number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

export function formatDate (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY')
}

export function formatDateTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY h:mm A')
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export function isNullOrWhitespace (value) {
  return value == null || (typeof value === 'string' && value.trim() === '')
}

export function isValidDateString (value, allowFuture = false) {
  // null
  if (value == null) {
    return false
  }

  // not a string
  if (typeof value !== 'string') {
    return false
  }

  // YYYY-MM-DD format & date must be valid (February 29th, April 31st, etc. not allowed)
  const date = moment(value, 'YYYY-MM-DD', true)
  if (!date.isValid()) {
    return false
  }

  // before 1900 or in the future
  if (date.isBefore('1900-01-01')) {
    return false
  }

  // future date
  if (!allowFuture && date.isAfter(moment())) {
    return false
  }

  return true
}

export function isValidDomain (value) {
  if (value === 'localhost') return true
  return /^[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,}$/.test(value)
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
  reader.readAsDataURL(file)
})

export function toTitleCase (str) {
  return str.replace(/([A-Z])/g, ' $1').replace(str[0], str[0].toUpperCase())
}

export function validateAdditionalInfo (files) {
  const maxTotalFileSizeMB = 20
  const maxFileSizeMB = 5
  const totalMB = files.reduce((acc, curr) => acc + curr.size / 1000 / 1000, 0.0)
  const validFileExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx']
  const invalidExtension = files.filter(x => !validFileExtensions.includes(x.name.split('.').pop().toLowerCase()))
  const invalidSize = files.filter(x => x.size / 1024 / 1024 > maxFileSizeMB)
  return {
    invalidExtension,
    invalidSize,
    invalidTotalSize: totalMB > maxTotalFileSizeMB,
    maxFileSizeMB,
    maxTotalFileSizeMB,
    totalMB,
    validFileExtensions
  }
}
