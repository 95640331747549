import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import pluralize from 'pluralize'
import { toTitleCase } from 'titlecase'
import {
  useGet,
  useIsMobile,
  AppLayout,
  Box,
  Button,
  Cards,
  Grid,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter
} from 'rad-framework-ui'

export function AdaptiveList ({
  entity,
  description,
  fields,
  actions,
  emptyText,
  create = true,
  edit = true,
  search = true,
  rootHref,
  filter,
  onQueryChanged,
  title
}) {
  const limit = 20
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const windowLocationSearch = window.location.search === '' ? '' : window.location.search.substring(1)
  const offset = (parseInt(searchParams.get('page') == null ? '1' : searchParams.get('page')) - 1) * limit
  const { data: items, count } = useGet(`/api/${entity}?limit=${limit}&offset=${offset}&${windowLocationSearch}`, { primary: true })
  const redirectURL = encodeURIComponent(`${rootHref ?? ''}/${entity}?limit=${limit}&${windowLocationSearch}`)

  const header = (field) => {
    if (typeof field === 'string') {
      if (field.includes('.')) {
        return field.split('.')[0]
      } else {
        return field
      }
    }
    if (typeof field === 'object') {
      return field.header ?? field.name
    }
  }

  const content = (item, field) => {
    if (field === 'address') {
      return (
        <>
          <div>{item.address?.line1}</div>
          {item.address?.line2 && <div>{item.address?.line2}</div>}
          <div>{item.address?.city} {item.address?.state} {item.address?.zip}</div>
        </>
      )
    }

    let value
    if (typeof field === 'string') {
      value = field
      if (value.includes('.')) {
        const parts = value.split('.')
        value = parts.reduce((o, i) => (o ? o[i] : null), item)
      } else {
        value = item[value]
      }
    }
    if (typeof field === 'object') {
      if (typeof field.name === 'string') {
        value = field.name.includes('.') ? field.name.split('.').reduce((o, i) => (o ? o[i] : null), item) : item[field.name]
      }
      if (typeof field.content === 'function') {
        return field.content(item)
      }
    }
    if (field.link === true) {
      return <Link fontSize='inherit' href={`${rootHref ?? ''}/${entity}/${item.id}`}>{value}</Link>
    } else {
      return value
    }
  }

  const definitions = () => {
    const results = []
    for (const field of fields) {
      results.push({
        id: header(field),
        header: field.header ?? toTitleCase(header(field)),
        content: item => content(item, field) ?? '-'
      })
    }
    if (!isMobile && edit !== false) {
      results.push({
        id: 'actions',
        content: item => <Button variant='inline-icon' iconName='edit' href={`${rootHref ?? ''}/${entity}/${item.id}/edit?redirectURL=${redirectURL}`} />
      })
    }
    return results
  }

  const contentHeader = () => {
    return (
      <Header
        variant='h1'
        counter={count != null ? `(${Number(count).toLocaleString()})` : ''}
        description={description}
        actions={
          <SpaceBetween direction='horizontal' size='xs'>
            {create !== false && <Button onClick={() => navigate(`${rootHref ?? ''}/${entity}/create?redirectURL=${redirectURL}`)}>Create</Button>}
            {actions != null && actions.map(x => <Button key={x.label} onClick={() => x.onClick({ search: filteringText })}>{x.label}</Button>)}
          </SpaceBetween>
        }
      >
        {title ?? pluralize(toTitleCase(entity)).replaceAll('-', ' ')}
      </Header>
    )
  }

  const gridDefinition = []
  if (search && filter != null) {
    gridDefinition.push({ colspan: { default: 12, xs: 6 } })
    gridDefinition.push({ colspan: { default: 12, xs: 6 } })
  } else {
    gridDefinition.push({ colspan: 12 })
  }
  const _filter = (
    <Grid
      gridDefinition={gridDefinition}
    >
      {search &&
        <TextFilter
          filteringPlaceholder='Search'
          filteringAriaLabel='Search'
          filteringText={filteringText}
          onChange={({ detail }) => setFilteringText(detail.filteringText)}
          onDelayedChange={({ detail }) => {
            searchParams.delete('page')
            if (detail.filteringText) {
              searchParams.set('search', detail.filteringText)
            } else {
              searchParams.delete('search')
            }
            setSearchParams(searchParams)
          }}
        />}
      {filter}
    </Grid>
  )

  const currentPageIndex = parseInt(searchParams.get('page') ?? '1')

  const pagination = (
    <Pagination
      currentPageIndex={currentPageIndex}
      pagesCount={Math.ceil(count / limit)}
      onChange={({ detail }) => {
        if (detail.currentPageIndex === 1) {
          searchParams.delete('page')
        } else {
          searchParams.set('page', detail.currentPageIndex)
        }
        setSearchParams(searchParams)
      }}
      ariaLabels={{
        nextPageLabel: 'Next page',
        previousPageLabel: 'Previous page',
        pageLabel: pageNumber => `Page ${pageNumber} of all pages`
      }}
    />
  )

  const appContent = () => {
    if (isMobile) {
      return (
        <Cards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item => (
              <Box fontWeight='bold' fontSize='heading-l' padding={{ bottom: 's' }}>
                {definitions()[0].content(item)}
              </Box>
            ),
            sections: definitions().filter((x, i) => i > 0)
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={items}
          filter={_filter}
          pagination={pagination}
          variant='full-page'
          empty={empty}
        />
      )
    } else {
      return (
        <Table
          variant='full-page'
          items={items}
          columnDefinitions={definitions().map(x => ({ ...x, cell: x.content }))}
          filter={_filter}
          pagination={pagination}
          empty={empty}
        />
      )
    }
  }

  const empty = (
    <Box textAlign='center' padding={{ top: 'l' }}>
      <Box>
        {emptyText ?? 'No matches found.'}
      </Box>
    </Box>
  )

  if (items != null) {
    return (
      <AppLayout
        contentHeader={contentHeader()}
        content={appContent()}
      />
    )
  }
}
