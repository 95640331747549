
    import './styles.scoped.css';
    export default {
  "progress": "awsui_progress_11huc_1iefd_361",
  "result-state": "awsui_result-state_11huc_1iefd_375",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_11huc_1iefd_1",
  "root": "awsui_root_11huc_1iefd_397",
  "with-result-button": "awsui_with-result-button_11huc_1iefd_429",
  "result-text": "awsui_result-text_11huc_1iefd_433",
  "result-container-error": "awsui_result-container-error_11huc_1iefd_437",
  "result-container-success": "awsui_result-container-success_11huc_1iefd_437",
  "result-button": "awsui_result-button_11huc_1iefd_441",
  "word-wrap": "awsui_word-wrap_11huc_1iefd_447",
  "label-flash": "awsui_label-flash_11huc_1iefd_452",
  "label-key-value": "awsui_label-key-value_11huc_1iefd_456",
  "flash": "awsui_flash_11huc_1iefd_464",
  "progress-container": "awsui_progress-container_11huc_1iefd_468",
  "percentage-container": "awsui_percentage-container_11huc_1iefd_475",
  "percentage": "awsui_percentage_11huc_1iefd_475",
  "complete": "awsui_complete_11huc_1iefd_521",
  "progress-in-flash": "awsui_progress-in-flash_11huc_1iefd_534",
  "additional-info": "awsui_additional-info_11huc_1iefd_547"
};
  