
    import './styles.scoped.css';
    export default {
  "tabs-header": "awsui_tabs-header_14rmt_11l2w_290",
  "tab-header-scroll-container": "awsui_tab-header-scroll-container_14rmt_11l2w_299",
  "tabs-header-list": "awsui_tabs-header-list_14rmt_11l2w_305",
  "pagination-button": "awsui_pagination-button_14rmt_11l2w_323",
  "pagination-button-left": "awsui_pagination-button-left_14rmt_11l2w_330",
  "pagination-button-left-scrollable": "awsui_pagination-button-left-scrollable_14rmt_11l2w_333",
  "pagination-button-right": "awsui_pagination-button-right_14rmt_11l2w_337",
  "pagination-button-right-scrollable": "awsui_pagination-button-right-scrollable_14rmt_11l2w_340",
  "actions-container": "awsui_actions-container_14rmt_11l2w_345",
  "tabs-tab": "awsui_tabs-tab_14rmt_11l2w_353",
  "tabs-tab-label": "awsui_tabs-tab-label_14rmt_11l2w_363",
  "tabs-tab-header-container": "awsui_tabs-tab-header-container_14rmt_11l2w_374",
  "tabs-tab-dismiss": "awsui_tabs-tab-dismiss_14rmt_11l2w_385",
  "tabs-tab-action": "awsui_tabs-tab-action_14rmt_11l2w_385",
  "refresh": "awsui_refresh_14rmt_11l2w_390",
  "tabs-tab-disabled": "awsui_tabs-tab-disabled_14rmt_11l2w_394",
  "tabs-tab-link": "awsui_tabs-tab-link_14rmt_11l2w_436",
  "tabs-tab-active": "awsui_tabs-tab-active_14rmt_11l2w_514",
  "tabs-header-with-divider": "awsui_tabs-header-with-divider_14rmt_11l2w_521",
  "tabs-tab-focusable": "awsui_tabs-tab-focusable_14rmt_11l2w_525",
  "root": "awsui_root_14rmt_11l2w_529",
  "tabs": "awsui_tabs_14rmt_11l2w_290",
  "tabs-content": "awsui_tabs-content_14rmt_11l2w_567",
  "fit-height": "awsui_fit-height_14rmt_11l2w_571",
  "tabs-content-active": "awsui_tabs-content-active_14rmt_11l2w_577",
  "tabs-content-wrapper": "awsui_tabs-content-wrapper_14rmt_11l2w_591",
  "with-paddings": "awsui_with-paddings_14rmt_11l2w_591",
  "tabs-container-content-wrapper": "awsui_tabs-container-content-wrapper_14rmt_11l2w_602",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_14rmt_11l2w_613",
  "tabs-tab-focused": "awsui_tabs-tab-focused_14rmt_11l2w_617"
};
  