
    import './styles.scoped.css';
    export default {
  "header-cell": "awsui_header-cell_1spae_13sne_149",
  "header-cell-fake-focus": "awsui_header-cell-fake-focus_1spae_13sne_183",
  "header-cell-sticky": "awsui_header-cell-sticky_1spae_13sne_204",
  "header-cell-stuck": "awsui_header-cell-stuck_1spae_13sne_207",
  "header-cell-variant-full-page": "awsui_header-cell-variant-full-page_1spae_13sne_207",
  "header-cell-hidden": "awsui_header-cell-hidden_1spae_13sne_213",
  "header-cell-variant-embedded": "awsui_header-cell-variant-embedded_1spae_13sne_216",
  "is-visual-refresh": "awsui_is-visual-refresh_1spae_13sne_216",
  "sticky-cell": "awsui_sticky-cell_1spae_13sne_216",
  "header-cell-variant-borderless": "awsui_header-cell-variant-borderless_1spae_13sne_216",
  "header-cell-sortable": "awsui_header-cell-sortable_1spae_13sne_219",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_1spae_13sne_240",
  "has-selection": "awsui_has-selection_1spae_13sne_240",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_1spae_13sne_243",
  "resize-divider": "awsui_resize-divider_1spae_13sne_248",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_1spae_13sne_255",
  "sorting-icon": "awsui_sorting-icon_1spae_13sne_265",
  "edit-icon": "awsui_edit-icon_1spae_13sne_273",
  "header-cell-content": "awsui_header-cell-content_1spae_13sne_279",
  "header-cell-content-expandable": "awsui_header-cell-content-expandable_1spae_13sne_285",
  "header-cell-disabled": "awsui_header-cell-disabled_1spae_13sne_337",
  "header-cell-sorted": "awsui_header-cell-sorted_1spae_13sne_337",
  "header-cell-text": "awsui_header-cell-text_1spae_13sne_351",
  "header-cell-text-wrap": "awsui_header-cell-text-wrap_1spae_13sne_355",
  "header-cell-ascending": "awsui_header-cell-ascending_1spae_13sne_361",
  "header-cell-descending": "awsui_header-cell-descending_1spae_13sne_362",
  "has-striped-rows": "awsui_has-striped-rows_1spae_13sne_476",
  "sticky-cell-pad-inline-start": "awsui_sticky-cell-pad-inline-start_1spae_13sne_476"
};
  