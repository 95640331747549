import React, { useContext, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toTitleCase } from 'titlecase'
import {
  useGet,
  AppContext,
  AppLayout,
  Box,
  Button,
  Grid,
  Header,
  Link,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter
} from 'rad-framework-ui'
import { Multiselect } from '../common/Multiselect'
import { ProgressModal } from '../common/ProgressModal'
import { concatWs, formatDate, formatDateTime, getColor } from '../common/utilities'
import { exportToExcel } from '../common/exportToExcel'

export function ApplicationList () {
  const pageLength = 20
  const cancelRef = useRef(false)
  const { user } = useContext(AppContext)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [progress, setProgress] = useState()
  const [error, setError] = useState()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') ?? '')
  const tribe = searchParams.get('tribe') ?? ''
  const { data: tribeOptions } = useGet('/api/option/tribe')
  const { data: userInfo } = useGet('/api/user/current')
  const { data: applications, count } = useGet(
    '/api/application' +
    `?search=${encodeURIComponent(searchText)}` +
    `&status=${searchStatus}` +
    `&tribe=${tribe}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`,
    { primary: true }
  )

  const statusOptions = [
    { label: 'All statuses', value: '' },
    { label: 'Submitted', value: 'submitted' },
    { label: 'Needs Verification', value: 'needs verification' },
    { label: 'Escalated', value: 'escalated' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Issued', value: 'issued' }
  ]

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === searchText.toLowerCase()
            ? <span key={i} className='highlight'>{part}</span>
            : part
        )}
      </span>
    )
  }

  async function exportApplicationsToExcel () {
    const uri = '/api/application' +
      `?search=${encodeURIComponent(searchText)}` +
      `&status=${searchStatus}` +
      `&tribe=${tribe}` +
      '&secondaryContact=true' +
      '&notes=true'

    const columnNames = [
      'Tribe',
      'Case Number',
      'Date Submitted',
      'Status',
      'Status Date',
      'Child(ren) Name and Birthdate',
      'Primary Contact Name',
      'Primary Contact Email',
      'Primary Contact Phone',
      'Primary Contact Consent to Contact',
      'Secondary Contact Name',
      'Secondary Contact Email',
      'Secondary Contact Phone',
      'Address',
      'Case Notes'
    ]

    const mapper = (rows) => rows.map(x => [
      x.tribe,
      x.id.toString(),
      formatDate(x.created_at),
      x.status != null ? toTitleCase(x.status) : 'Submitted',
      x.status_date != null ? formatDate(x.status_date) : formatDate(x.created_at),
      x.children.map(y =>
        [y.first_name, y.middle_name, y.last_name].filter(z => z != null).join(' ') + ' - ' + formatDate(y.birth_date))
        .join('\n'),
      concatWs([x.primary_contact_first_name, x.primary_contact_middle_name, x.primary_contact_last_name], ' '),
      x.primary_contact_email,
      concatWs([x.primary_contact_phone1, x.primary_contact_phone2], '\n'),
      x.primary_contact_consent_to_contact ? 'Yes' : 'No',
      concatWs([x.secondary_contact_first_name, x.secondary_contact_middle_name, x.secondary_contact_last_name], ' '),
      x.secondary_contact_email ?? '',
      x.secondary_contact_phone ?? '',
      [x.line1, x.line2].filter(y => y != null).join('\n') + '\n' + [x.city, x.state, x.zip].join(' '),
      x.notes.map(y => `${y.text};${y.createdBy};${formatDateTime(y.createdAt)}`).join('\n')
    ])

    await exportToExcel({
      uri,
      columnNames,
      mapper,
      filenamePrefix: 'sebt_applications',
      worksheetName: 'Applications',
      user,
      error,
      setError,
      setProgress,
      cancelRef
    })
  }

  async function exportContactsToExcel () {
    const uri = '/api/application' +
      `?search=${encodeURIComponent(searchText)}` +
      `&status=${searchStatus}` +
      `&tribe=${tribe}` +
      '&secondaryContact=true'
    const columnNames = [
      'Tribe',
      'Case Number',
      'Status',
      'Primary Contact First Name',
      'Primary Contact Middle Name',
      'Primary Contact Last Name',
      'Secondary Contact First Name',
      'Secondary Contact Middle Name',
      'Secondary Contact Last Name',
      'Oldest Child Name',
      'Oldest Child DOB',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'Zip',
      'Primary Contact Phone 1',
      'Primary Contact Phone 2',
      'Primary Contact Consent to Contact',
      'Primary Contact Email',
      'Secondary Contact Phone ',
      'Secondary Contact Email'
    ]
    const mapper = (rows) => rows.map(x => {
      const oldestChild = x.children.filter(y => !y.excluded).reduce((a, b) => a.birth_date < b.birth_date ? a : b)
      return ([
        x.tribe,
        x.id.toString(),
        x.status != null ? toTitleCase(x.status) : 'Submitted',
        x.primary_contact_first_name ?? '',
        x.primary_contact_middle_name ?? '',
        x.primary_contact_last_name ?? '',
        x.secondary_contact_first_name ?? '',
        x.secondary_contact_middle_name ?? '',
        x.secondary_contact_last_name ?? '',
        concatWs([oldestChild.first_name, oldestChild.middle_name, oldestChild.last_name], ' '),
        formatDate(oldestChild.birth_date),
        x.line1 ?? '',
        x.line2 ?? '',
        x.city ?? '',
        x.state ?? '',
        x.zip ?? '',
        x.primary_contact_phone1 ?? '',
        x.primary_contact_phone2 ?? '',
        x.primary_contact_consent_to_contact ? 'Yes' : 'No',
        x.primary_contact_email ?? '',
        x.secondary_contact_phone ?? '',
        x.secondary_contact_email ?? ''
      ])
    })

    await exportToExcel({
      uri,
      columnNames,
      mapper,
      filenamePrefix: 'sebt_application_contacts',
      worksheetName: 'Contacts',
      user,
      error,
      setError,
      setProgress,
      cancelRef
    })
  }

  if (
    applications != null &&
    userInfo != null &&
    tribeOptions != null
  ) {
    const filteredTribeOptions = [{ value: '0', label: 'Unassigned' }]
      .concat(tribeOptions.filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value))))
    const selectedTribeOptions = filteredTribeOptions.filter(x => tribe.split('-').includes(x.value))

    return (
      <AppLayout
        contentHeader={
          <Header
            variant='h1'
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button onClick={() => navigate(`/admin/apply?redirectURL=${window.location.pathname}${encodeURIComponent(window.location.search)}`)}>Create</Button>
                <Button onClick={() => exportApplicationsToExcel()}>Export Applications</Button>
                <Button onClick={() => exportContactsToExcel()}>Export Application Contacts</Button>
              </SpaceBetween>
            }
            counter={'(' + Number(count).toLocaleString() + ')'}
            description='You can search by application number, primary contact name, primary contact birthdate, primary contact phone number, child name, child birthdate or school district.'
          >
            Applications
          </Header>
        }
        content={
          <>
            <Table
              columnDefinitions={[
                {
                  id: 'id',
                  header: '#',
                  cell: item => <Link href={`/admin/application/${item.id}`}>{highlightMatch(item.id.toString())}</Link>
                },
                {
                  id: 'primaryContact',
                  header: 'Primary Contact',
                  cell: item => <div>{highlightMatch(item.primary_contact_first_name + ' ' + (item.primary_contact_middle_name ?? '') + ' ' + item.primary_contact_last_name)} - {highlightMatch(formatDate(item.primary_contact_birth_date))}</div>
                },
                {
                  id: 'primaryContactPhone',
                  header: 'Primary Contact Phone / Email',
                  cell: item =>
                    <div>
                      <div>{highlightMatch(item.primary_contact_phone1)}{(item.primary_contact_phone2 != null ? highlightMatch(' | ' + item.primary_contact_phone2) : '')}</div>
                      <div>{highlightMatch(item.primary_contact_email)}</div>
                    </div>
                },
                {
                  id: 'children',
                  header: 'Children',
                  cell: item =>
                    item.children.map((x, index) =>
                      <Box key={'child-' + index}>{highlightMatch(x.first_name + ' ' + (x.middle_name ?? '') + ' ' + x.last_name)} - {highlightMatch(formatDate(x.birth_date))}</Box>)
                },
                {
                  id: 'status',
                  header: 'Status',
                  cell: item => <Box color={getColor(item.status)}>{toTitleCase(item.status ?? 'submitted')}</Box>
                },
                {
                  id: 'tribe',
                  header: 'Tribe',
                  cell: item => item.tribe ?? '-'
                },
                {
                  id: 'createdAt',
                  header: 'Created',
                  cell: item => formatDateTime(item.created_at)
                },
                {
                  id: 'actions',
                  cell: item =>
                    <Button
                      disabled={
                        !['submitted', 'escalated', 'rejected'].includes(item.status ?? 'submitted') ||
                        (!userInfo.isAdmin && !userInfo.roles.flatMap(x => x.permissions).map(x => x.name).includes('Update Application'))
                      }
                      variant='inline-icon'
                      iconName='edit'
                      href={`/admin/application/${item.id}/edit?redirectURL=${window.location.pathname}${encodeURIComponent(window.location.search)}`}
                    />
                }
              ]}
              items={applications}
              variant='full-page'
              filter={
                <Grid
                  gridDefinition={[
                    { colspan: { default: 12, xs: 6 } },
                    { colspan: { default: 12, xs: 3 } },
                    { colspan: { default: 12, xs: 3 } }
                  ]}
                >
                  <TextFilter
                    filteringPlaceholder='Search'
                    filteringAriaLabel='Search participants'
                    filteringText={filteringText}
                    onChange={({ detail }) => setFilteringText(detail.filteringText)}
                    onDelayedChange={({ detail }) => {
                      setSearchText(detail.filteringText)
                      setCurrentPageIndex(1)
                      searchParams.delete('page')
                      if (detail.filteringText) {
                        searchParams.set('search', detail.filteringText)
                      } else {
                        searchParams.delete('search')
                      }
                      setSearchParams(searchParams)
                    }}
                  />
                  <Select
                    onChange={({ detail }) => {
                      setSearchStatus(detail.selectedOption.value)
                      setCurrentPageIndex(1)
                      if (detail.selectedOption.value === '') {
                        searchParams.delete('status')
                      } else {
                        searchParams.set('status', detail.selectedOption.value)
                      }
                      setSearchParams(searchParams)
                    }}
                    options={statusOptions}
                    selectedOption={statusOptions.find((x) => x.value === searchStatus) ?? statusOptions[0]}
                    placeholder='Choose status'
                  />
                  <Multiselect
                    onChange={({ detail }) => {
                      if (detail.selectedOptions.length === 0) {
                        searchParams.delete('tribe')
                      } else {
                        searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
                      }
                      setSearchParams(searchParams)
                    }}
                    options={filteredTribeOptions}
                    selectedOptions={selectedTribeOptions}
                    placeholder='Choose Tribes'
                  />
                </Grid>
              }
              pagination={
                <Pagination
                  currentPageIndex={currentPageIndex}
                  pagesCount={Math.ceil(count / pageLength)}
                  onChange={({ detail }) => {
                    searchParams.set('page', detail.currentPageIndex)
                    setSearchParams(searchParams)
                    setCurrentPageIndex(detail.currentPageIndex)
                  }}
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                  }}
                />
              }
              empty={
                <Box textAlign='center' color='inherit'>
                  No matches found.
                </Box>
              }
            />
            <ProgressModal
              label='Exporting'
              progress={progress}
              cancel={() => { cancelRef.current = true; setProgress(null) }}
              error={error}
            />
          </>
        }
      />
    )
  }
}
