import React, { useContext, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toTitleCase } from 'titlecase'
import {
  useGet,
  AppLayout,
  AppContext,
  Box,
  Button,
  Grid,
  Header,
  Link,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter
} from 'rad-framework-ui'
import { Multiselect } from '../common/Multiselect'
import { ProgressModal } from '../common/ProgressModal'
import { concatWs, formatDate, formatDateTime, getColor } from '../common/utilities'
import { exportToExcel } from '../common/exportToExcel'

export function PassiveEnrollmentList () {
  const pageLength = 20
  const cancelRef = useRef(false)
  const { user } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [progress, setProgress] = useState()
  const [error, setError] = useState()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') ?? '')
  const tribe = searchParams.get('tribe') ?? ''
  const { data: passiveEnrollments, count } = useGet(
    '/api/passive-enrollment' +
    `?search=${encodeURIComponent(searchText)}` +
    `&status=${searchStatus}` +
    `&tribe=${tribe}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`,
    { primary: true }
  )
  const { data: tribeOptions } = useGet('/api/option/tribe')
  const { data: userInfo } = useGet('/api/user/current')

  const statusOptions = [
    { label: 'All Statuses', value: '' },
    { label: 'Submitted', value: 'submitted' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Declined', value: 'declined' },
    { label: 'Escalated', value: 'escalated' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Issued', value: 'issued' }
  ]

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  async function exportEnrollmentsToExcel () {
    const uri = '/api/passive-enrollment' +
    `?search=${encodeURIComponent(searchText)}` +
    `&status=${searchStatus}` +
    `&tribe=${tribe}` +
    '&notes=true'
    const columnNames = [
      'Tribe',
      'Case Number',
      'Date Submitted',
      'Status',
      'Status Date',
      'Child(ren) Name and Birthdate',
      'Primary Guardian Name',
      'Primary Guardian Email',
      'Primary Guardian Phone',
      'Primary Guardian Permission to Text',
      'Secondary Guardian Name',
      'Secondary Guardian Email',
      'Secondary Guardian Phone',
      'Secondary Guardian Permission to Text',
      'Address',
      'School District',
      'Case Notes'
    ]
    const mapper = (rows) => rows.map(x => [
      x.tribe_name,
      x.id.toString(),
      formatDate(x.created_at),
      x.status != null ? x.status.charAt(0).toUpperCase() + x.status.slice(1) : 'Submitted',
      formatDate(x.status_date),
      concatWs([x.student_first_name, x.student_middle_name, x.student_last_name], ' ') + ' - ' + formatDate(x.student_birthdate),
      concatWs([x.primary_guardian_first_name, x.primary_guardian_middle_name, x.primary_guardian_last_name], ' '),
      x.primary_guardian_emails.join('\n'),
      x.primary_guardian_phones.join('\n'),
      x.primary_guardian_permission_to_text ? 'Yes' : 'No',
      concatWs([x.secondary_guardian_first_name, x.secondary_guardian_middle_name, x.secondary_guardian_last_name], ' '),
      x.secondary_guardian_emails.join('\n'),
      x.secondary_guardian_phones.join('\n'),
      x.secondary_guardian_permission_to_text ? 'Yes' : 'No',
      concatWs([x.mailing_address_line1, x.mailing_address_line2], '\n') + '\n' + concatWs([x.mailing_address_city, x.mailing_address_state, x.mailing_address_zip], ' '),
      x.district_name,
      x.notes.map(y => `${y.text};${y.createdBy};${formatDateTime(y.createdAt)}`).join('\n')
    ])

    await exportToExcel({
      uri,
      columnNames,
      mapper,
      filenamePrefix: 'sebt_passive_enrollments_',
      worksheetName: 'Passive Enrollments',
      user,
      error,
      setError,
      setProgress,
      cancelRef
    })
  }

  async function exportContactsToExcel () {
    const uri = '/api/passive-enrollment' +
    `?search=${encodeURIComponent(searchText)}` +
    `&status=${searchStatus}` +
    `&tribe=${tribe}`
    const columnNames = [
      'Tribe',
      'Case Number',
      'Status',
      'Primary Guardian First Name',
      'Primary Guardian Middle Name',
      'Primary Guardian Last Name',
      'Secondary Guardian First Name',
      'Secondary Guardian Middle Name',
      'Secondary Guardian Last Name',
      'Child Name',
      'Child DOB',
      'District',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'Zip',
      'Primary Guardian Phone 1',
      'Primary Guardian Phone 2',
      'Primary Guardian Phone 3',
      'Primary Guardian Permission to Text',
      'Primary Guardian Email 1',
      'Primary Guardian Email 2',
      'Primary Guardian Email 3',
      'Secondary Guardian Phone 1',
      'Secondary Guardian Phone 2',
      'Secondary Guardian Phone 3',
      'Secondary Guardian Permission to Text',
      'Secondary Guardian Email 1',
      'Secondary Guardian Email 2',
      'Secondary Guardian Email 3'
    ]
    const mapper = (rows) => rows.map(x => [
      x.tribe_name,
      x.id.toString(),
      x.status != null ? x.status.charAt(0).toUpperCase() + x.status.slice(1) : 'Submitted',
      x.primary_guardian_first_name ?? '',
      x.primary_guardian_middle_name ?? '',
      x.primary_guardian_last_name ?? '',
      x.secondary_guardian_first_name ?? '',
      x.secondary_guardian_middle_name ?? '',
      x.secondary_guardian_last_name ?? '',
      [x.student_first_name, x.student_middle_name, x.student_last_name].filter(z => z != null).join(' '),
      formatDate(x.student_birthdate),
      x.district_name,
      x.mailing_address_line1 ?? '',
      x.mailing_address_line2 ?? '',
      x.mailing_address_city ?? '',
      x.mailing_address_state ?? '',
      x.mailing_address_zip ?? '',
      x.primary_guardian_phones[0] ?? '',
      x.primary_guardian_phones[1] ?? '',
      x.primary_guardian_phones[2] ?? '',
      x.primary_guardian_permission_to_text ? 'Yes' : 'No',
      x.primary_guardian_emails[0] ?? '',
      x.primary_guardian_emails[1] ?? '',
      x.primary_guardian_emails[2] ?? '',
      x.secondary_guardian_phones[0] ?? '',
      x.secondary_guardian_phones[1] ?? '',
      x.secondary_guardian_phones[2] ?? '',
      x.secondary_guardian_permission_to_text ? 'Yes' : 'No',
      x.secondary_guardian_emails[0] ?? '',
      x.secondary_guardian_emails[1] ?? '',
      x.secondary_guardian_emails[2] ?? ''
    ])

    await exportToExcel({
      uri,
      columnNames,
      mapper,
      filenamePrefix: 'sebt_passive_enrollment_contacts',
      worksheetName: 'Contacts',
      user,
      error,
      setError,
      setProgress,
      cancelRef
    })
  }

  if (
    passiveEnrollments != null &&
    tribeOptions != null &&
    userInfo != null
  ) {
    const filteredTribeOptions = tribeOptions.filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
    const selectedTribeOptions = filteredTribeOptions.filter(x => tribe.split('-').includes(x.value))

    return (
      <AppLayout
        contentHeader={
          <Header
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button onClick={() => exportEnrollmentsToExcel()}>Export Passive Enrollments</Button>
                <Button onClick={() => exportContactsToExcel()}>Export Passive Enrollment Contacts</Button>
              </SpaceBetween>
            }
            variant='h1'
            counter={'(' + Number(count).toLocaleString() + ')'}
            description='You can search by passive enrollment ID, state testing number, student name, student birthdate, school district, guardian name, guardian phone, and mailing address.'
          >
            Passive Enrollments
          </Header>
        }
        content={
          <>
            <Table
              columnDefinitions={[
                {
                  id: 'id',
                  header: '#',
                  cell: item => <Link href={`/admin/passive-enrollment/${item.id}`}>{highlightMatch(item.id.toString())}</Link>
                },
                {
                  id: 'studentTestingNumber',
                  header: 'State Testing Number',
                  cell: item => <div>{highlightMatch(item.state_testing_number ?? '-')}</div>
                },
                {
                  id: 'student',
                  header: 'Student',
                  cell: item => <div>{highlightMatch(item.student_first_name + ' ' + (item.student_middle_name ?? '') + ' ' + item.student_last_name)} - {highlightMatch(formatDate(item.student_birthdate))}</div>
                },
                {
                  id: 'districtName',
                  header: 'School District',
                  cell: item => <div>{highlightMatch(item.district_name ?? '-')}</div>
                },
                {
                  id: 'status',
                  header: 'Status',
                  cell: item => <Box color={getColor(item.status)}>{toTitleCase(item.status ?? 'submitted')}</Box>
                },
                {
                  id: 'tribeId',
                  header: 'Tribe',
                  cell: item => item.tribe_name
                },
                {
                  id: 'createdAt',
                  header: 'Created',
                  cell: item => formatDateTime(item.created_at)
                }
              ]}
              items={passiveEnrollments}
              variant='full-page'
              filter={
                <Grid
                  gridDefinition={[
                    { colspan: { default: 12, xs: 4 } },
                    { colspan: { default: 12, xs: 3 } },
                    { colspan: { default: 12, xs: 5 } }
                  ]}
                >
                  <TextFilter
                    filteringPlaceholder='Search'
                    filteringAriaLabel='Search participants'
                    filteringText={filteringText}
                    onChange={({ detail }) => setFilteringText(detail.filteringText)}
                    onDelayedChange={({ detail }) => {
                      setSearchText(detail.filteringText)
                      setCurrentPageIndex(1)
                      searchParams.delete('page')
                      if (detail.filteringText) {
                        searchParams.set('search', detail.filteringText)
                      } else {
                        searchParams.delete('search')
                      }
                      setSearchParams(searchParams)
                    }}
                  />
                  <Select
                    onChange={({ detail }) => {
                      setSearchStatus(detail.selectedOption.value)
                      setCurrentPageIndex(1)
                      if (detail.selectedOption.value === '') {
                        searchParams.delete('status')
                      } else {
                        searchParams.set('status', detail.selectedOption.value)
                      }
                      setSearchParams(searchParams)
                    }}
                    options={statusOptions}
                    selectedOption={statusOptions.find((x) => x.value === searchStatus) ?? statusOptions[0]}
                    placeholder='Choose status'
                  />
                  <Multiselect
                    onChange={({ detail }) => {
                      if (detail.selectedOptions.length === 0) {
                        searchParams.delete('tribe')
                      } else {
                        searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
                      }
                      setSearchParams(searchParams)
                    }}
                    options={filteredTribeOptions}
                    selectedOptions={selectedTribeOptions}
                    placeholder='Choose tribes'
                  />
                </Grid>
            }
              pagination={
                <Pagination
                  currentPageIndex={currentPageIndex}
                  pagesCount={Math.ceil(count / pageLength)}
                  onChange={({ detail }) => {
                    searchParams.set('page', detail.currentPageIndex)
                    setSearchParams(searchParams)
                    setCurrentPageIndex(detail.currentPageIndex)
                  }}
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                  }}
                />
            }
              empty={
                <Box textAlign='center' color='inherit'>
                  No matches found.
                </Box>
            }
            />
            <ProgressModal
              label='Exporting'
              progress={progress}
              cancel={() => { cancelRef.current = true; setProgress(null) }}
              error={error}
            />
          </>
        }
      />
    )
  }
}
