import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGet,
  usePost,
  AppContext,
  Box,
  Button,
  FileUpload,
  FormField,
  Link,
  Modal,
  Select,
  SpaceBetween
} from 'rad-framework-ui'
import { AdaptiveList } from '../common/AdaptiveList'
import { Multiselect } from '../common/Multiselect'
import { toBase64, toTitleCase } from '../common/utilities'

export function PassiveEnrollmentUploadList () {
  const defaultFormValues = {}
  const [searchParams, setSearchParams] = useSearchParams()
  const status = searchParams.get('status')
  const tribe = searchParams.get('tribe') ?? ''
  const dataSource = searchParams.get('data-source') ?? ''
  const { error, setError, reloadCounter, setReloadCounter } = useContext(AppContext)
  const [files, setFiles] = useState([])
  const [formValues, setFormValues] = useState(defaultFormValues)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [runId, setRunId] = useState()
  const [dismissId, setDismissId] = useState()
  const [publishId, setPublishId] = useState()
  const { data: userInfo } = useGet('/api/user/current')
  const { data: tribeOptions } = useGet('/api/option/tribe')
  const upload = usePost('/api/passive-enrollment-upload/upload', formValues, () => { dismissUploadModal() })
  const run = usePost(`/api/passive-enrollment-upload/run/${runId}`, formValues, () => { setDismissId(null) })
  const dismiss = usePost(`/api/passive-enrollment-upload/dismiss/${dismissId}`, formValues, () => { setDismissId(null) })
  const publish = usePost(`/api/passive-enrollment-upload/publish/${publishId}`, formValues, () => { setPublishId(null) })

  const statusOptions = [
    { label: 'Uploaded', value: 'uploaded' },
    { label: 'Processed', value: 'processed' },
    { label: 'Published', value: 'published' },
    { label: 'Dismissed', value: 'dismissed' }
  ]

  const dataSourceOptions = [
    { label: 'School Data', value: 'School Data' },
    { label: 'FDPIR', value: 'FDPIR' },
    { label: 'TANF', value: 'TANF' },
    { label: 'SNAP', value: 'SNAP' }
  ]

  useEffect(() => {
    if (status == null) {
      searchParams.set('status', 'uploaded-processed')
      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    if (runId != null) {
      run()
    }
  }, [runId])

  useEffect(() => {
    if (dismissId != null) {
      dismiss()
    }
  }, [dismissId])

  useEffect(() => {
    if (publishId != null) {
      publish()
    }
  }, [publishId])

  useEffect(() => {
    if (userInfo?.tribes.length === 1) {
      defaultFormValues.tribeId = userInfo.tribes[0].id
      setFormValues(defaultFormValues)
    }
  }, [userInfo, tribeOptions])

  useEffect(() => {
    const processFile = async () => {
      const file = files[0]
      if (file != null) {
        const fileData = await toBase64(file)
        setFormValues({ ...formValues, file: file.name, fileSize: file.size, fileData })
      } else {
        setFormValues(defaultFormValues)
      }
    }

    processFile()
  }, [files])

  function dismissUploadModal () {
    setError(null)
    setShowUploadModal(false)
    setFormValues(defaultFormValues)
    setFiles([])
  }

  function dismissErrorModal () {
    setError(null)
    setReloadCounter(reloadCounter + 1)
    setRunId(null)
    setDismissId(null)
    setPublishId(null)
  }

  function truncateText (text, maxLength) {
    if (text.length <= maxLength) {
      return text
    }
    return text.slice(0, maxLength) + '...'
  }

  if (userInfo == null || tribeOptions == null) return null

  const selectedStatusOptions = statusOptions.filter(x => status?.split('-').includes(x.value))
  const filteredTribeOptions = tribeOptions
    .filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
  const selectedTribeOptions = filteredTribeOptions.filter(x => tribe.split('-').includes(x.value))
  const selectedDataSourceOptions = dataSourceOptions.filter(x => dataSource.split('-').includes(x.value))

  return (
    <>
      <AdaptiveList
        entity='passive-enrollment-upload'
        rootHref='/admin'
        description='You can search by file name.'
        create={false}
        edit={false}
        actions={[
          { label: 'Upload', onClick: () => { setError(null); setShowUploadModal(true) } }
        ]}
        fields={[
          { header: '#', name: 'id' },
          {
            header: 'File',
            content: (item) => truncateText(item.path.split('/').pop(), 40)
          },
          {
            header: 'Status',
            content: (item) => toTitleCase(item.status)
          },
          {
            header: 'Date',
            content: (item) => new Date(item.updatedAt).toLocaleString()
          },
          {
            header: 'User',
            content: (item) => <Link href={`/admin/user/${item.updater.id}`}>{item.updater.name}</Link>
          },
          'tribe.name',
          { header: 'Data Source', content: (item) => item.dataSource },
          {
            header: 'Successes',
            content: (item) =>
              <Box textAlign='center'>
                {item.successCount == null ? '-' : Number(item.successCount).toLocaleString()}
              </Box>
          },
          {
            header: 'Failures',
            content: (item) =>
              <Box textAlign='center'>
                {item.failureCount == null
                  ? '-'
                  : item.failureCount > 0
                    ? <Link href={`/admin/passive-enrollment-upload/${item.id}`}>{Number(item.failureCount).toLocaleString()}</Link>
                    : Number(item.failureCount).toLocaleString()}
              </Box>
          },
          {
            header: 'Actions',
            content: (item) => (
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  onClick={() => setRunId(item.id)}
                  disabled={item.status !== 'uploaded'}
                  variant='inline-link'
                >
                  Run
                </Button>
                <Button
                  onClick={() => setPublishId(item.id)}
                  disabled={item.status !== 'processed' || item.successCount < 1 || item.failureCount > 0}
                  disabledReason={
                    item.successCount < 1 || item.failureCount > 0 ? 'Cannot publish without successes or with failures' : null
                  }
                  variant='inline-link'
                >
                  Publish
                </Button>
                <Button
                  onClick={() => setDismissId(item.id)}
                  disabled={!['uploaded', 'processed'].includes(item.status)}
                  disabledReason='Already dismissed'
                  variant='inline-link'
                >
                  Dismiss
                </Button>
              </SpaceBetween>
            )
          }

        ]}
        filter={
          <SpaceBetween size='m' direction='horizontal'>
            <Multiselect
              onChange={({ detail }) => {
                searchParams.delete('page')
                searchParams.set('status', detail.selectedOptions.map(x => x.value).join('-'))
                setSearchParams(searchParams)
              }}
              options={statusOptions}
              selectedOptions={selectedStatusOptions}
              placeholder='Choose a status'
            />
            <Multiselect
              onChange={({ detail }) => {
                searchParams.delete('page')
                if (detail.selectedOptions.length === 0) {
                  searchParams.delete('tribe')
                } else {
                  searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
                }
                setSearchParams(searchParams)
              }}
              options={filteredTribeOptions}
              selectedOptions={selectedTribeOptions}
              placeholder='Choose a Tribe'
            />
            <Multiselect
              onChange={({ detail }) => {
                searchParams.delete('page')
                searchParams.set('data-source', detail.selectedOptions.map(x => x.value).join('-'))
                setSearchParams(searchParams)
              }}
              options={dataSourceOptions}
              selectedOptions={selectedDataSourceOptions}
              placeholder='Choose a data source'
            />
          </SpaceBetween>
        }
      />
      <Modal
        size='medium'
        onDismiss={() => { dismissUploadModal() }}
        visible={showUploadModal}
        header='Upload File'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='s'>
              <Box padding={{ top: 'xs' }}>
                <Button
                  onClick={() => dismissUploadModal()}
                  variant='inline-link'
                >
                  Cancel
                </Button>
              </Box>
              <Button
                onClick={() => upload()}
                variant='primary'
                disabled={formValues.tribeId == null || files.length === 0 || formValues.dataSource == null}
              >
                Upload
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween size='l'>
          <FormField label='Tribe' field='tribeId' required>
            <Select
              filteringType='none'
              selectedOption={tribeOptions.find(x => parseInt(x.value) === formValues.tribeId)}
              onChange={({ detail }) => setFormValues({ ...formValues, tribeId: parseInt(detail.selectedOption.value) })}
              options={tribeOptions.filter(x => userInfo.tribes.length === 0 || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a Tribe'
            />
          </FormField>
          <FormField label='Data Source' field='dataSource' required>
            <Select
              filteringType='none'
              selectedOption={dataSourceOptions.find(x => x.value === formValues.dataSource)}
              onChange={({ detail }) => setFormValues({ ...formValues, dataSource: detail.selectedOption.value })}
              options={dataSourceOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a data source'
            />
          </FormField>
          <FormField label='CSV File' required>
            <FileUpload
              onChange={({ detail }) => setFiles(detail.value)}
              value={files}
              accept='.csv'
              i18nStrings={{
                uploadButtonText: e => e ? 'Choose files' : 'Choose file',
                dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
                removeFileAriaLabel: e => `Remove file ${e + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error',
                warningIconAriaLabel: 'Warning'
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={1}
              maxSize={10}
            />
          </FormField>
        </SpaceBetween>
      </Modal>
      <Modal
        size='medium'
        onDismiss={() => dismissErrorModal()}
        visible={error != null}
        header='Error'
        footer={
          <Box float='right'>
            <Button
              onClick={() => dismissErrorModal()}
              variant='primary'
            >
              Dismiss
            </Button>
          </Box>
        }
      >
        <Box color='text-status-error'>{error?.message}</Box>
      </Modal>
    </>
  )
}
