import React from 'react'

export default function Footer ({ translate }) {
  return (
    <footer className='footer_wrap pt-2'>
      <div className='container-fluid px-lg-5 margin-top-med'>
        <div className='first_info_para'>
          <p>{translate(`
          In accordance with federal civil rights law and U.S. Department of
          Agriculture (USDA) civil rights regulations and policies, this
          institution is prohibited from discriminating on the basis of race,
          color, national origin, sex (including gender identity and sexual
          orientation), disability, age, or reprisal or retaliation for prior
          civil rights activity. This institution is an equal opportunity
          provider.
        `)}
          </p>
        </div>
      </div>
      <div className='admin-link'>
        <a className='white' href='/admin' role='button'>{translate('Administrator Access')}</a>
      </div>
    </footer>
  )
}
