import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AppLayout,
  Box,
  Button,
  Checkbox,
  Container,
  Header,
  Input,
  Select,
  SpaceBetween,
  useGet,
  usePost,
  usePut
} from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'

export function UserEdit () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { userId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: user } = useGet(userId ? `/api/user/${userId}` : null, { primary: true })
  const { data: roles } = useGet('/api/role')
  const { data: tribes } = useGet('/api/option/tribe')
  const create = usePost('/api/user', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/admin/user/${resp.id}`) })
  const update = usePut(`/api/user/${userId}`, formValues, () => { navigate(searchParams.get('redirectURL') ?? `/admin/user/${userId}`) })

  useEffect(() => {
    const defaultFormValues = { roles: [], tribes: [] }
    setFormValues(user ?? defaultFormValues)
  }, [user])

  async function cancel () {
    if (userId) {
      navigate(searchParams.get('redirectURL') ?? `/admin/user/${userId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/admin/user')
    }
  }

  async function saveChanges () {
    if (userId) { update() } else { create() }
  }

  if (
    formValues != null &&
    roles != null &&
    tribes != null
  ) {
    const roleOptions = roles.map(role => ({ value: role.id.toString(), label: role.name }))

    return (
      <AppLayout
        name={formValues.name}
        contentHeader={
          <Header variant='h1'>
            {formValues.id ? 'Edit User' : 'New User'}
          </Header>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={cancel}>Cancel</Button>
                  <Button formAction='submit' variant='primary' onClick={saveChanges}>
                    {userId == null ? 'Add New User' : 'Save Changes'}
                  </Button>
                </SpaceBetween>
              }
            >
              <Container>
                <SpaceBetween size='l'>
                  <FormField label='Full Name' field='name' required>
                    <Input
                      ariaRequired
                      placeholder='Enter full name'
                      value={formValues.name ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                    />
                  </FormField>
                  <FormField label='Email' field='email' required>
                    <Input
                      ariaRequired
                      type='email'
                      inputMode='email'
                      placeholder='Enter email address'
                      disabled={formValues.id}
                      value={formValues.email ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, email: detail.value })}
                    />
                  </FormField>
                  <FormField label='Title' field='title'>
                    <Input
                      placeholder='Enter title'
                      value={formValues.title ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, title: detail.value })}
                    />
                  </FormField>
                  <FormField label='Phone' field='phone'>
                    <Input
                      inputMode='tel'
                      placeholder='Enter phone'
                      value={formValues.phone ?? ''}
                      pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                      onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
                    />
                  </FormField>
                  <FormField label='Role' field='role' required>
                    <Select
                      filteringType='auto'
                      selectedOption={roleOptions.find(x => parseInt(x.value) === formValues.roles[0]?.id)}
                      onChange={({ detail }) => {
                        if (detail.selectedOption.label === 'Admin') {
                          setFormValues({ ...formValues, roles: [{ id: parseInt(detail.selectedOption.value) }], tribes: [] })
                        } else {
                          setFormValues({ ...formValues, roles: [{ id: parseInt(detail.selectedOption.value) }] })
                        }
                      }}
                      options={roleOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a role'
                      empty='No matches found'
                    />
                  </FormField>
                  <FormField label='Tribes' field='tribes' required>
                    {formValues.roles[0]?.id !== 1 && tribes.map(x => (
                      <Checkbox
                        key={x.value}
                        checked={formValues.tribes.map(y => y.id).includes(parseInt(x.value))}
                        onChange={({ detail }) => {
                          if (detail.checked) {
                            setFormValues({ ...formValues, tribes: [...formValues.tribes, { id: parseInt(x.value) }] })
                          } else {
                            setFormValues({ ...formValues, tribes: formValues.tribes.filter(y => y.id !== parseInt(x.value)) })
                          }
                        }}
                      >
                        {x.label}
                      </Checkbox>
                    ))}
                    {formValues.roles[0]?.id === 1 && <Box disabled>All tribes</Box>}
                  </FormField>
                </SpaceBetween>
              </Container>
            </Form>
          </form>
        }
      />
    )
  }
}
