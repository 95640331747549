import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AppLayout, Header, SpaceBetween, BreadcrumbGroup, Flashbar,
  useGet, usePut,
  Button, Checkbox, Container, Input, Select
} from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'

export function BannerEdit () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState()
  const [showSuccess, setShowSuccess] = useState()
  const { data: banner } = useGet('/api/banner')
  const { data: colors } = useGet('/api/banner/colors')
  const update = usePut('/api/banner', formValues, (resp) => { setShowSuccess(true) })

  useEffect(() => {
    setFormValues(banner)
  }, [banner])

  if (formValues == null || colors == null) { return }

  const bannerColorOptions = colors.bannerColors
    .map(color => ({ value: color.value, label: color.name }))
  const bannerTextColorOptions = colors.textColors
    .map(color => ({ value: color.value, label: color.name }))

  return (
    <AppLayout
      name={formValues.name}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Home', href: '/' },
            { text: 'Admin', href: '/admin' },
            { text: 'Banner', href: '/admin/banner' }
          ]}
        />
      }
      contentHeader={<Header variant='h1'>Edit Site Banner</Header>}
      content={
        <>
          {
            showSuccess && (
              <>
                <Flashbar items={[{
                  type: 'success',
                  content: 'Banner saved successfully.',
                  id: 'message_0',
                  onDismiss: () => setShowSuccess(false),
                  dismissible: true
                }]}
                />
                <br />
              </>
            )
          }
          <form onSubmit={e => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={() => navigate('/admin')}>Cancel</Button>
                  <Button formAction='submit' variant='primary' onClick={() => update()}>Save Changes</Button>
                </SpaceBetween>
              }
            >
              <Container>
                <SpaceBetween size='l'>
                  <FormField label='Message' field='message' required>
                    <Input
                      ariaRequired
                      placeholder='Enter message'
                      value={formValues.message ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, message: detail.value })}
                    />
                  </FormField>
                  <FormField label='Banner Color' field='bannerColor' required>
                    <Select
                      filteringType='auto'
                      selectedOption={bannerColorOptions.find(x => x.value === formValues.bannerColor)}
                      onChange={({ detail }) => {
                        setFormValues({ ...formValues, bannerColor: detail.selectedOption.value })
                      }}
                      options={bannerColorOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a banner color'
                      empty='No matches found'
                    />
                  </FormField>
                  <FormField label='Text Color' field='textColor' required>
                    <Select
                      filteringType='auto'
                      selectedOption={bannerTextColorOptions.find(x => x.value === formValues.textColor)}
                      onChange={({ detail }) => {
                        setFormValues({ ...formValues, textColor: detail.selectedOption.value })
                      }}
                      options={bannerTextColorOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a text color'
                      empty='No matches found'
                    />
                  </FormField>
                  <FormField field='showToUsers'>
                    <Checkbox
                      checked={formValues.showToUsers}
                      onChange={({ detail }) => {
                        setFormValues({ ...formValues, showToUsers: detail.checked })
                      }}
                    >
                      Show To Users
                    </Checkbox>
                  </FormField>
                </SpaceBetween>
              </Container>
            </Form>
          </form>
        </>
      }
    />
  )
}
