import React from 'react'
import Popover from '@cloudscape-design/components-themed/popover'

// https://cloudscape.design/components/popover
export function _Popover ({ ...rest }) {
  return (
    <Popover
      {...rest}
    />
  )
}

export { _Popover as Popover }
