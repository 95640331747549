import React from 'react'
import { ProgressBar } from '../common/ProgressBar'
import {
  Alert,
  Box,
  Button,
  Modal
} from 'rad-framework-ui'

export function ProgressModal ({ label, description, additionalInfo, progress, cancel, error }) {
  return (
    <Modal
      visible={progress != null}
      onDismiss={cancel}
      closeAriaLabel='Close modal'
      header={label}
      footer={
        <Box float='right'>
          <Button variant='primary' onClick={cancel}>Cancel</Button>
        </Box>
      }
    >
      <ProgressBar
        value={progress}
        additionalInfo
        description
        label
      />
      {error != null &&
        <Box
          padding={{ top: 's' }}
          color='text-status-error'
        >
          <Alert
            statusIconAriaLabel='Error'
            type='error'
          >
            {error.message ?? error.code}
          </Alert>
        </Box>}
    </Modal>
  )
}
