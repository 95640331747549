import React, { useContext } from 'react'
import { AppContext } from 'rad-framework-ui'
import '../form.css'
import '../layout.scss'
import '../responsive.scss'
import Hero from '../common/Hero'
import Footer from '../common/Footer'
import { TopMenu } from '../common/TopMenu'

export function ChickasawNationBenefits () {
  const { translate } = useContext(AppContext)

  return (
    <div>
      <TopMenu />

      <Hero
        {...{ translate }}
        image='assets/images/hero-01.webp'
        headline='CHICKASAW NATION BENEFITS'
        text='Information about the items eligible for purchase with Summer EBT benefits and participating retailers can be found at the links below.'
      />

      <section className='main_div index_benefits_wrap'>
        <div className='container-fluid pt-lg-5 px-lg-5'>
          <div className='row align-items-center px-lg-5'>
            <h2 style={{ whiteSpace: 'nowrap' }}>{translate('LINKS')}</h2>
            <br />
            <br />
            <br />
            <br />
            <p>
              <a href='FAQs English 2024 Creek Approved.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('FAQ')}
              </a>
            </p>
            <p>
              <a href='SEBT FAQ Chickasaw SPANISH.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('FAQ')} - {translate('Spanish')}
              </a>
            </p>
            <p>
              <a href='SEBT Helpful Hints Chickasaw.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Helpful Hints')}
              </a>
            </p>
            <p>
              <a href='SEBT Helpful Hints Chickasaw SPANISH.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Helpful Hints')} - {translate('Spanish')}
              </a>
            </p>
            <p>
              <a href='2024 Larger Food Card Creek.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Food Card')}
              </a>
            </p>
            <p>
              <a href='WICShopper-App-Flyer-English.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('WIC Shopper App Flyer')}
              </a>
            </p>
            <p>
              <a href='Muscogee (Creek) Nation Grocery Store V2 approved.pdf' target='_blank' rel='noreferrer' className='pdf'>
                {translate('Approved Stores')}
              </a>
            </p>
          </div>
        </div>
      </section>

      <Footer {...{ translate }} />
    </div>
  )
}
