import React from 'react'
import { Checkbox, Grid, Input, Select, SpaceBetween } from 'rad-framework-ui'
import { FormField } from '../common/FormField'
import { showPin } from '../common/branding'

export function HouseholdEditor ({ household, formValues, setFormValues, translate }) {
  const incomeFrequencyOptions = [
    { value: 'Weekly', label: translate('Weekly') },
    { value: 'Biweekly', label: translate('Biweekly') },
    { value: 'Twice a Month', label: translate('Twice a Month') },
    { value: 'Monthly', label: translate('Monthly') },
    { value: 'Annually', label: translate('Annually') }
  ]

  const languageOptions = [
    { value: 'English', label: translate('English') },
    { value: 'Spanish', label: translate('Spanish') },
    { value: 'Other', label: translate('Other') }
  ]

  return (
    <SpaceBetween size='l'>
      <FormField label={translate('Primary Language Spoken')} field={'household.primaryLanguageSpoken' + (household.otherLanguageSpoken === true ? '-disable' : '')} required>
        <Select
          filteringType='auto'
          selectedOption={household.otherLanguageSpoken ? { value: 'Other', label: translate('Other') } : languageOptions.find(x => x.value === household.primaryLanguageSpoken)}
          onChange={({ detail }) => {
            if (detail.selectedOption.value === 'Other') {
              household.otherLanguageSpoken = true
              household.primaryLanguageSpoken = null
            } else {
              household.otherLanguageSpoken = false
              household.primaryLanguageSpoken = detail.selectedOption.value
            }
            setFormValues({ ...formValues, household })
          }}
          options={languageOptions}
          enteredTextLabel={value => value}
          selectedAriaLabel={translate('Selected')}
          placeholder={translate('Choose a language')}
          empty={translate('No matches found')}
        />
      </FormField>
      {household.otherLanguageSpoken &&
        <FormField label={translate('If other language please specify')} field='household.primaryLanguageSpoken' required>
          <Input
            placeholder={translate('Enter language')}
            value={household.primaryLanguageSpoken}
            onChange={({ detail }) => {
              household.primaryLanguageSpoken = detail.value
              setFormValues({ ...formValues, household })
            }}
          />
        </FormField>}
      <div>
        <FormField
          label={translate('Which of the following programs is any member of your household currently participating in?')}
          description={translate('SNAP (also known as food stamps), TANF (Temporary Assistance for Needy Families), FDPIR (commodities or Food Distribution Program on Indian Reservations), Medicaid (SoonerCare).')}
          required
        />
        <FormField field='snap' label='SNAP' screenReader>
          <Checkbox
            checked={household.snap}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.snap = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            SNAP
          </Checkbox>
        </FormField>
        <FormField field='tanf' label='TANF' screenReader>
          <Checkbox
            checked={household.tanf}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.tanf = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            TANF
          </Checkbox>
        </FormField>
        <FormField field='fdpir' label='FDPIR' screenReader>
          <Checkbox
            checked={household.fdpir}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.fdpir = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            FDPIR
          </Checkbox>
        </FormField>
        <FormField field='medicaid' label='Medicaid' screenReader>
          <Checkbox
            checked={household.medicaid}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.none = false
              }
              household.medicaid = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            Medicaid
          </Checkbox>
        </FormField>
        <FormField field='none' label='None' screenReader>
          <Checkbox
            checked={household.none}
            onChange={({ detail }) => {
              if (detail.checked) {
                household.snap = false
                household.tanf = false
                household.fdpir = false
                household.medicaid = false
              }
              household.none = detail.checked
              setFormValues({ ...formValues, household })
            }}
          >
            {translate('None')}
          </Checkbox>
        </FormField>
        <FormField field='household.directCertification' label='Used for displaying validation errors' screenReader />
      </div>
      <SpaceBetween size='l' direction='horizontal'>
        <FormField label={translate('About how much do you get paid after taxes and other deductions?')} field='household.incomeAmount' required>
          <Input
            ariaRequired
            placeholder={translate('Enter income amount')}
            value={household.incomeAmount}
            type='number'
            onChange={({ detail }) => {
              household.incomeAmount = detail.value
              setFormValues({ ...formValues, household })
            }}
          />
        </FormField>
        <FormField label={translate('How often are you paid this amount?')} field='household.incomeFrequency' required>
          <Select
            filteringType='auto'
            placeholder={translate('Choose a frequency')}
            options={incomeFrequencyOptions}
            selectedOption={incomeFrequencyOptions.find(x => x.value === household.incomeFrequency)}
            onChange={({ detail }) => {
              household.incomeFrequency = detail.selectedOption.value
              setFormValues({ ...formValues, household })
            }}
          />
        </FormField>
      </SpaceBetween>
      {showPin(formValues.branding) &&
        <FormField
          label={translate('PIN')}
          field='household.pin'
          description={translate('A pin will only be used if an application is approved for benefits. If no pin is entered, one will be generated automatically for eligible applications.')}
        >
          <Grid
            gridDefinition={[
              { colspan: 3 }
            ]}
          >
            <Input
              placeholder={translate('Enter PIN')}
              type='number'
              value={household.pin}
              onChange={({ detail }) => {
                household.pin = detail.value
                setFormValues({ ...formValues, household })
              }}
            />
          </Grid>
        </FormField>}
    </SpaceBetween>
  )
}
