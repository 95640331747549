import React from 'react'
import {
  Box,
  ColumnLayout,
  Container,
  Header
} from 'rad-framework-ui'

export function Admin ({ application, currentStatus }) {
  return (
    <Container
      header={
        <Header variant='h2'>
          Admin
        </Header>
        }
    >
      <ColumnLayout columns={3}>
        <div>
          <Box variant='awsui-key-label'>
            Bypass Mailing Address
          </Box>
          <div>{application.bypassMailingAddress ? 'Yes' : 'No'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Bypass Tribe Calculation
          </Box>
          <div>{application.bypassTribeCalculation ? 'Yes' : 'No'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Assign School District Applicant Lives In
          </Box>
          <div>{application.overrideSchoolDistrict?.name ?? '-'}</div>
        </div>
      </ColumnLayout>
    </Container>
  )
}
