import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toTitleCase } from 'titlecase'
import {
  useDelete,
  useGet,
  usePost,
  AppContext,
  Alert,
  AppLayout,
  Box,
  Button,
  ColumnLayout,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Link,
  Modal,
  RadioGroup,
  Select,
  SpaceBetween
} from 'rad-framework-ui'
import { useConfirm } from '../common/useConfirm'
import { FormField } from '../common/FormField'
import { Popover } from '../common/Popover'
import { Admin } from '../application/Admin'
import { GeneralInformation } from '../application/GeneralInfo'
import { Notes } from '../common/Notes'
import { formatCurrency, formatDate, formatDateTime, getColor } from '../common/utilities'
import { showLactoseFree, showPin, TribeLogo } from '../common/branding'

export function ApplicationDetail () {
  const navigate = useNavigate()
  const { applicationId } = useParams()
  const [reloadCounter, setReloadCounter] = useState(0)
  const [showAdminModal, setShowAdminModal] = useState(false)
  const { data: application } = useGet(`/api/application/${applicationId}?reload=${reloadCounter}`, { primary: true })
  const { data: userInfo } = useGet('/api/user/current')
  const [confirmResend] = useConfirm({
    title: 'Resend confirmation email?',
    message: 'Please confirm you would like to resend the confirmation email to the application primary contact?',
    actionLabel: 'Resend Confirmation Email',
    onConfirm: () => { resend() },
    autoDismiss: true
  })
  const resend = usePost(`/api/application/send-confirmation/${applicationId}`, {})
  const [confirmReject, dismissReject] = useConfirm({
    title: 'Reject Application?',
    message: 'Please confirm you would like to reject the application?',
    actionLabel: 'Reject',
    onConfirm: () => { reject() }
  })
  const reject = usePost(
    `/api/application/reject/${applicationId}/${application?.updatedAt}`,
    null,
    () => { dismissReject() }
  )
  const [confirmDelete, dismissDelete] = useConfirm({
    title: 'Delete application?',
    message: 'Please confirm you would like to delete the application?',
    actionLabel: 'Delete',
    onConfirm: () => { remove() }
  })
  const remove = useDelete(
    `/api/application/${applicationId}/${application?.updatedAt}`,
    () => { dismissDelete(); navigate('/admin/application') }
  )

  if (
    application != null &&
    userInfo != null
  ) {
    const currentStatus = application.statuses?.[0] ?? { status: 'submitted', createdAt: application?.createdAt }
    const submitted = currentStatus.status === 'submitted'
    const escalated = currentStatus.status === 'escalated'
    const rejected = currentStatus.status === 'rejected'
    const showAdmin = application.bypassMailingAddress ||
      application.bypassTribeCalculation ||
      application.overrideSchoolDistrictId != null ||
      application.children.some(x => x.bypassPotentialDuplicate || x.bypassAge || x.excluded)
    const permissions = userInfo.roles.flatMap(x => x.permissions).map(x => x.name)

    return (
      <AppLayout
        name={application.id}
        contentHeader={
          <Header
            variant='h1'
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  onClick={() => setShowAdminModal(true)}
                  disabled={
                    (!userInfo.isAdmin && !permissions.some(x => x === 'Administer Application')) ||
                    (!submitted && !escalated && !rejected)
                  }
                  disabledReason={
                    !userInfo.isAdmin && !permissions.some(x => x === 'Administer Application')
                      ? 'You do not have permission to administer this application.'
                      : 'Only applications in submitted, escalated, or rejected status can be administered.'
                  }
                >
                  Admin Controls
                </Button>
                <Button onClick={confirmResend}>Resend Confirmation Email</Button>
                <Button
                  onClick={() => navigate('edit')}
                  disabled={!submitted && !escalated && !rejected}
                  disabledReason='Only applications in submitted, escalated, or rejected status can be edited.'
                >
                  Edit
                </Button>
                <Button
                  onClick={confirmReject}
                  disabled={
                    (!userInfo.isAdmin && !permissions.some(x => x === 'Reject Application')) ||
                    (!submitted && !escalated)
                  }
                  disabledReason={
                    !userInfo.isAdmin && !permissions.some(x => x === 'Reject Application')
                      ? 'You do not have permission to reject this application.'
                      : 'Only applications in submitted or escalated status can be rejected.'
                  }
                >
                  Reject
                </Button>
                <Button
                  onClick={confirmDelete}
                  disabled={
                    (!userInfo.isAdmin && !permissions.some(x => x === 'Delete Application')) ||
                    (!submitted)
                  }
                  disabledReason={
                    !userInfo.isAdmin && !permissions.some(x => x === 'Delete Application')
                      ? 'You do not have permission to delete this application.'
                      : 'Only applications in submitted status can be deleted.'
                  }
                >
                  Delete
                </Button>
              </SpaceBetween>
            }
          >
            Application # {application.id}
          </Header>
        }
        content={
          <SpaceBetween size='l'>
            <TribeLogo branding={application.branding} />

            <AdminControls
              application={application}
              showAdminModal={showAdminModal}
              setShowAdminModal={setShowAdminModal}
              currentStatus={currentStatus}
              getColor={getColor}
              reloadCounter={reloadCounter}
              setReloadCounter={setReloadCounter}
            />
            {showAdmin && <Admin application={application} currentStatus={currentStatus} />}
            <GeneralInformation application={application} />
            <PrimaryContact application={application} />
            <SecondaryContact application={application} />
            <HouseholdInformation application={application} />
            <Children application={application} currentStatus={currentStatus} showAdmin={showAdmin} />
            <HouseholdMembers application={application} />
            <AdditionalInformation application={application} />
            <Signature application={application} />
          </SpaceBetween>
        }
        tools={<Notes entityType='application' entityId={applicationId} />}
        toolsWidth='390'
      />
    )
  }
}

function AdminControls ({ application, showAdminModal, setShowAdminModal, currentStatus, getColor, reloadCounter, setReloadCounter }) {
  const { error, setError } = useContext(AppContext)
  const [formValues, setFormValues] = useState()
  const { data: userInfo } = useGet('/api/user/current')
  const { data: schoolOptions } = useGet('/api/option/school-district')
  const { data: tribeOptions } = useGet('/api/option/tribe?imsOnly=false&any=true')
  const admin = usePost(
    `/api/application/${application.id}/${application.updatedAt}/admin`,
    formValues,
    (resp) => {
      setReloadCounter(reloadCounter + 1) // TODO: do I need this?
      dismiss()
    }
  )
  const submitted = currentStatus.status === 'submitted'
  const escalated = currentStatus.status === 'escalated'
  const applicationValues = {
    bypassMailingAddress: application.bypassMailingAddress,
    bypassTribeCalculation: application.bypassTribeCalculation,
    tribeId: application.tribeId,
    overrideSchoolDistrictId: application.overrideSchoolDistrictId,
    children: application.children.map(x => ({
      id: x.id,
      bypassPotentialDuplicate: x.bypassPotentialDuplicate,
      bypassAge: x.bypassAge,
      excluded: x.excluded
    }))
  }
  const isDirty = JSON.stringify(formValues) !== JSON.stringify(applicationValues)

  useEffect(() => {
    if (application != null) {
      setFormValues(applicationValues)
    }
  }, [application])

  if (
    formValues == null ||
    tribeOptions == null ||
    userInfo == null ||
    schoolOptions == null
  ) return null

  function dismiss () {
    setFormValues(applicationValues)
    setShowAdminModal(false)
    if (error != null) setError(null)
  }

  return (
    <Modal
      size='max'
      onDismiss={() => dismiss()}
      visible={showAdminModal}
      header='Admin Controls'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              onClick={() => dismiss()}
              variant='link'
            >
              Cancel
            </Button>
            <Button
              onClick={() => admin()}
              variant='primary'
              disabled={!isDirty}
            >
              Save Changes
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <SpaceBetween size='xxs'>
          <SpaceBetween size='m'>
            <div>
              <Header variant='h4'>Application</Header>
              {currentStatus != null &&
                <Box
                  padding={{ top: 'xxs' }}
                  color={getColor(currentStatus.status)}
                  fontWeight='heavy'
                >
                  {toTitleCase(currentStatus.status)} - {formatDateTime(currentStatus.createdAt)}
                  {currentStatus.reason?.length > 0 &&
                    currentStatus.reason.map((x, i) => <div key={'reason-' + i}>{x}</div>)}
                </Box>}
            </div>
            <Grid
              gridDefinition={[
                { colspan: 3 },
                { colspan: 3 },
                { colspan: 3 },
                { colspan: 3 }
              ]}
            >
              <FormField label='Bypass Mailing Address' field='bypassMailingAddress'>
                <RadioGroup
                  onChange={({ detail }) => setFormValues({ ...formValues, bypassMailingAddress: detail.value })}
                  ariaRequired
                  value={formValues.bypassMailingAddress}
                  items={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ]}
                />
              </FormField>
              <FormField label='Bypass Tribe Calculation' field='bypassTribeCalculation'>
                <RadioGroup
                  onChange={({ detail }) => setFormValues({ ...formValues, bypassTribeCalculation: detail.value })}
                  ariaRequired
                  value={formValues.bypassTribeCalculation}
                  items={[
                    { value: true, label: 'Yes', disabled: !escalated && formValues.bypassTribeCalculation === false },
                    { value: false, label: 'No', disabled: !submitted && !escalated }
                  ]}
                />
                {!escalated && formValues.bypassTribeCalculation === false &&
                  <Box padding={{ top: 's' }} color='text-status-inactive'>Application must be escalated to bypass Tribe calculation.</Box>}
              </FormField>
              <FormField label='Reassign Tribe' field='tribeId'>
                <Select
                  filteringType='auto'
                  selectedOption={tribeOptions.find(x => parseInt(x.value) === formValues.tribeId)}
                  onChange={({ detail }) => setFormValues({ ...formValues, tribeId: parseInt(detail.selectedOption.value) })}
                  options={tribeOptions.filter(x => userInfo.tribes.length === 0 || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))}
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Choose a Tribe'
                />
              </FormField>
              <FormField label='Assign School District Applicant Lives In' field='overrideSchoolDistrictId'>
                <Select
                  filteringType='auto'
                  selectedOption={schoolOptions.find(x => parseInt(x.value) === formValues.overrideSchoolDistrictId)}
                  onChange={({ detail }) => setFormValues({ ...formValues, overrideSchoolDistrictId: parseInt(detail.selectedOption.value) })}
                  options={schoolOptions}
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Choose a school district'
                  disabled={!application.children.some(x => x.homeSchool || x.otherSchool) || application.schoolDistrictId != null}
                />
                {!application.children.some(x => x.homeSchool || x.otherSchool) &&
                  <Box padding={{ top: 's' }} color='text-status-inactive'>No children are home or other schooled.</Box>}
                {application.schoolDistrictId != null &&
                  <Box padding={{ top: 's' }} color='text-status-inactive'>Application already has a school district assigned.</Box>}
              </FormField>
            </Grid>
          </SpaceBetween>
          <br />
          <Header variant='h4'>Children</Header>
          <SpaceBetween size='l'>
            {application.children.map((child, index) => (
              <div key={'admin-child-' + child.id}>
                <Grid
                  key={'admin-child-' + child.id}
                  gridDefinition={[
                    { colspan: 3 },
                    { colspan: 3 },
                    { colspan: 3 },
                    { colspan: 3 }
                  ]}
                >
                  <div>
                    <Box variant='awsui-key-label'>
                      Name
                    </Box>
                    <SpaceBetween direction='horizontal' size='xs'>
                      <div>{child.fullName}</div>
                      <Popover
                        position='right'
                        triggerType='custom'
                        header='Child Info'
                        content={
                          <SpaceBetween size='xxs'>
                            <div>
                              <Box variant='awsui-key-label'>
                                Birth Date
                              </Box>
                              <div>{formatDate(child.birthDate)}</div>
                            </div>
                            <div>
                              <Box variant='awsui-key-label'>
                                School
                              </Box>
                              {child.schoolDistrict != null && <div>{child.schoolDistrict.name} School District<br />{child.schoolDistrict.county != null ? `${child.schoolDistrict.county} County` : ''}</div>}
                              {child.schoolDistrict == null && application.schoolDistrict != null && <div>{application.schoolDistrict.name} School District<br />{application.schoolDistrict.county != null ? `${application.schoolDistrict.county} County` : ''}</div>}
                              {child.homeSchool && <div>Home School</div>}
                              {child.otherSchool && <div>Other School</div>}
                            </div>
                            <div>
                              <Box variant='awsui-key-label'>
                                Grade
                              </Box>
                              <div>{child.grade}</div>
                            </div>
                            <div>
                              <Box variant='awsui-key-label'>
                                Foster Child
                              </Box>
                              <div>{child.fosterChild ? 'Yes' : 'No'}</div>
                            </div>
                            <div>
                              <Box variant='awsui-key-label'>
                                Homeless / Migrant / Runaway
                              </Box>
                              <div>{child.homelessMigrantRunaway ? 'Yes' : 'No'}</div>
                            </div>
                            {showLactoseFree(application.branding) &&
                              <div>
                                <Box variant='awsui-key-label'>
                                  Lactose Free
                                </Box>
                                <div>{child.lactoseFree === true ? 'Yes' : child.lactoseFree === false ? 'No' : '-'}</div>
                              </div>}
                          </SpaceBetween>
                      }
                      >
                        <Icon variant='link' name='status-info' />
                      </Popover>
                    </SpaceBetween>
                    {currentStatus?.ineligibilityReasons?.[child.id] != null &&
                      <Box
                        fontWeight='heavy'
                        color='text-status-error'
                      >
                        {currentStatus?.ineligibilityReasons[child.id].map((x, i) => <div key={'child-ineligibility-reasons-1-' + i}>{x}</div>)}
                      </Box>}
                  </div>
                  <FormField label='Bypass Potential Duplicate Checks' field={`child.${child.id}.bypassPotentialDuplicate`}>
                    <RadioGroup
                      onChange={({ detail }) => {
                        const children = formValues.children
                        const theChild = children.find(x => x.id === child.id)
                        theChild.bypassPotentialDuplicate = detail.value
                        setFormValues({ ...formValues, children })
                      }}
                      ariaRequired
                      value={formValues.children.find(x => x.id === child.id).bypassPotentialDuplicate}
                      items={[
                        { value: true, label: 'Yes', disabled: !escalated && formValues.children.find(x => x.id === child.id).bypassPotentialDuplicate === false },
                        { value: false, label: 'No' }
                      ]}
                    />
                  </FormField>
                  <FormField label={<span>Bypass Age Related Checks<br /></span>} field={`child.${child.id}.bypassAge`}>
                    <RadioGroup
                      onChange={({ detail }) => {
                        const children = formValues.children
                        const theChild = children.find(x => x.id === child.id)
                        theChild.bypassAge = detail.value
                        setFormValues({ ...formValues, children })
                      }}
                      ariaRequired
                      value={formValues.children.find(x => x.id === child.id).bypassAge}
                      items={[
                        { value: true, label: 'Yes', disabled: !submitted && !escalated },
                        { value: false, label: 'No', disabled: !submitted && !escalated }
                      ]}
                    />
                  </FormField>
                  <FormField label='Exclude Child from Benefit Recipients' field={`child.${child.id}.excluded`}>
                    <RadioGroup
                      onChange={({ detail }) => {
                        const children = formValues.children
                        const theChild = children.find(x => x.id === child.id)
                        theChild.excluded = detail.value
                        setFormValues({ ...formValues, children })
                      }}
                      ariaRequired
                      value={formValues.children.find(x => x.id === child.id).excluded}
                      items={[
                        { value: true, label: 'Yes', disabled: !escalated && formValues.children.find(x => x.id === child.id).excluded === false },
                        { value: false, label: 'No' }
                      ]}
                    />
                  </FormField>
                </Grid>
              </div>
            ))}
            <Grid
              gridDefinition={[
                { colspan: 3 },
                { colspan: 3 },
                { colspan: 3 },
                { colspan: 3 }
              ]}
            >
              <div />
              <div>
                {!escalated &&
                  <Box color='text-status-inactive'>Application must be escalated to bypass potential duplicate checks.</Box>}
              </div>
              <div>
                {!submitted && !escalated &&
                  <Box color='text-status-inactive'>Application must be submitted or escalated add or remove bypass age related checks.</Box>}
              </div>
              <div>
                {!escalated &&
                  <Box color='text-status-inactive'>Application must be escalated to exclude child from benefit recipients.</Box>}
              </div>
            </Grid>
            {formValues.children.filter(x => x.excluded).length === formValues.children.length &&
              <Alert
                statusIconAriaLabel='Error'
                type='error'
              >
                All children on this application have been excluded from benefits. If this is accurate, click Cancel and then Reject the application.
                If this is not accurate, ensure the appropriate child(ren) are marked "No" under "Exclude child from benefit recipients" and click Save Changes.
              </Alert>}
          </SpaceBetween>
        </SpaceBetween>
      </Form>
    </Modal>
  )
}

function PrimaryContact ({ application }) {
  return (
    <Container
      header={
        <Header variant='h2'>
          Primary Contact Information
        </Header>
      }
    >
      <ColumnLayout columns={4}>
        <div>
          <Box variant='awsui-key-label'>
            Name
          </Box>
          <div>{`${application.primaryContact.firstName} ${application.primaryContact.middleName ? application.primaryContact.middleName + ' ' : ''} ${application.primaryContact.lastName} ${application.primaryContact.suffix ?? ''}`}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Race / Ethnicity
          </Box>
          <div>{application.primaryContact.raceEthnicity ?? '-'}</div>
        </div>
        {application.primaryContact.tribe != null &&
          <div>
            <Box variant='awsui-key-label'>
              Tribe
            </Box>
            <div>{application.primaryContact.tribe}</div>
          </div>}
        <div>
          <Box variant='awsui-key-label'>
            Birth Date
          </Box>
          <div>{formatDate(application.primaryContact.birthDate)}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Mailing Address
          </Box>
          <div>{application.primaryContact.mailingAddress.line1}</div>
          <div>{application.primaryContact.mailingAddress.line2}</div>
          <div>{application.primaryContact.mailingAddress.city}, {application.primaryContact.mailingAddress.state} {application.primaryContact.mailingAddress.zip}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Physical Address
          </Box>
          <div>{application.primaryContact.physicalAddress.line1}</div>
          <div>{application.primaryContact.physicalAddress.line2}</div>
          <div>{application.primaryContact.physicalAddress.city}, {application.primaryContact.physicalAddress.state} {application.primaryContact.physicalAddress.zip}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Email
          </Box>
          <div>{application.primaryContact.email ? <Link href={`mailto:${application.primaryContact.email}`}>{application.primaryContact.email}</Link> : '-'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Phone(s)
          </Box>
          <div>{application.primaryContact.phone1.number} - {application.primaryContact.phone1.type}</div>
          {application.primaryContact.phone2 != null && <div>{application.primaryContact.phone2?.number} - {application.primaryContact.phone2?.type}</div>}
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Consent to be Contacted
          </Box>
          <div>{application.primaryContact.consentToContact ? 'Yes' : 'No'}</div>
        </div>
        {application.primaryContact.preferredContactMethod != null &&
          <div>
            <Box variant='awsui-key-label'>
              Preferred Contact Method
            </Box>
            <div>{application.primaryContact.preferredContactMethod}</div>
          </div>}
        <div>
          <Box variant='awsui-key-label'>
            Member of Household / Role
          </Box>
          <div>{application.primaryContact.householdMember ? `Yes - ${application.primaryContact.role}` : 'No'}</div>
        </div>
      </ColumnLayout>
    </Container>
  )
}

function SecondaryContact ({ application }) {
  return (
    <Container
      header={
        <Header variant='h2'>
          Secondary Contact Information
        </Header>
      }
    >
      {application.secondaryContact == null && <Box color='text-status-inactive'>No secondary contact information provided.</Box>}
      {application.secondaryContact != null &&
        <ColumnLayout columns={3}>
          <div>
            <Box variant='awsui-key-label'>
              Name
            </Box>
            <div>{`${application.secondaryContact.firstName} ${application.secondaryContact.middleName ? application.secondaryContact.middleName + ' ' : ''} ${application.secondaryContact.lastName} ${application.secondaryContact.suffix ?? ''}`}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Email
            </Box>
            <div>{application.secondaryContact.email ? <Link href={`mailto:${application.secondaryContact.email}`}>{application.secondaryContact.email}</Link> : '-'}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Phone
            </Box>
            <div>{application.secondaryContact.phone?.number} - {application.secondaryContact.phone?.type}</div>
          </div>
        </ColumnLayout>}
    </Container>
  )
}

function HouseholdInformation ({ application }) {
  return (
    <Container
      header={
        <Header variant='h2'>
          Household Information
        </Header>
      }
    >
      <ColumnLayout columns={4}>
        <div>
          <Box variant='awsui-key-label'>
            Primary Language Spoken
          </Box>
          <div>{application.household.primaryLanguageSpoken}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Program(s)
          </Box>
          <div>{application.household.snap && 'SNAP'}</div>
          <div>{application.household.tanf && 'TANF'}</div>
          <div>{application.household.fdpir && 'FDPIR'}</div>
          <div>{application.household.medicaid && 'Medicaid'}</div>
          <div>{!application.household.snap && !application.household.tanf && !application.household.fdpir && !application.household.medicaid && '-'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Income
          </Box>
          <div>{formatCurrency(application.household.incomeAmount)} / {application.household.incomeFrequency}</div>
        </div>
        {showPin(application.branding) &&
          <div>
            <Box variant='awsui-key-label'>
              PIN
            </Box>
            <div>{application.household.pin ?? '-'}</div>
          </div>}
      </ColumnLayout>
    </Container>
  )
}

function Children ({ application, currentStatus, showAdmin }) {
  return (
    <SpaceBetween size='l'>
      {application.children.map((child, index) => {
        const identityUsages = (application.identityUsages ?? []).filter(x => x.childId === child.id)

        return (
          <Container
            key={'child-' + child.id}
            header={
              <Header variant='h2'>
                CHILD INFORMATION
              </Header>
          }
          >
            <ColumnLayout columns={4}>
              <div>
                <Box variant='awsui-key-label'>
                  Name
                </Box>
                <div>{`${child.firstName} ${child.middleName ? child.middleName + ' ' : ''} ${child.lastName} ${child.suffix ?? ''}`}</div>
              </div>
              <div>
                <Box variant='awsui-key-label'>
                  Race / Ethnicity
                </Box>
                <div>{child.raceEthnicity ?? '-'}</div>
              </div>
              {child.tribe != null &&
                <div>
                  <Box variant='awsui-key-label'>
                    Tribe
                  </Box>
                  <div>{child.tribe}</div>
                </div>}
              <div>
                <Box variant='awsui-key-label'>
                  Birth Date
                </Box>
                <div>{formatDate(child.birthDate)}</div>
              </div>
              <div>
                <Box variant='awsui-key-label'>
                  Grade
                </Box>
                <div>{child.grade}</div>
              </div>
              <div>
                <Box variant='awsui-key-label'>
                  School
                </Box>
                {child.schoolDistrict != null && <div>{child.schoolDistrict.name} SCHOOL DISTRICT<br />{child.schoolDistrict.county != null ? `${child.schoolDistrict.county} County` : ''}</div>}
                {child.schoolDistrict == null && application.schoolDistrict != null && <div>{application.schoolDistrict.name} SCHOOL DISTRICT<br />{application.schoolDistrict.county != null ? `${application.schoolDistrict.county} County` : ''}</div>}
                {child.homeSchool && <div>Home School</div>}
                {child.otherSchool && <div>Other School</div>}
              </div>
              <div>
                <Box variant='awsui-key-label'>
                  Foster Child
                </Box>
                <div>{child.fosterChild ? 'Yes' : 'No'}</div>
              </div>
              <div>
                <Box variant='awsui-key-label'>
                  Homeless / Migrant / Runaway
                </Box>
                <div>{child.homelessMigrantRunaway ? 'Yes' : 'No'}</div>
              </div>
              {showLactoseFree(application.branding) &&
                <div>
                  <Box variant='awsui-key-label'>
                    Lactose Free
                  </Box>
                  <div>{child.lactoseFree === true ? 'Yes' : child.lactoseFree === false ? 'No' : '-'}</div>
                </div>}
              <div />
            </ColumnLayout>
            <div />
            {!showAdmin && currentStatus?.ineligibilityReasons?.[child.id] != null &&
              <>
                <Divider />
                <Box
                  padding={{ top: 's' }}
                  fontWeight='heavy'
                  color='text-status-error'
                >
                  {currentStatus?.ineligibilityReasons[child.id].map((x, i) => <div key={'child-ineligibility-reasons-2-' + i}>{x}</div>)}
                </Box>
              </>}
            {showAdmin &&
              <>
                <Divider />
                <ColumnLayout columns={4}>
                  {currentStatus?.ineligibilityReasons?.[child.id] != null &&
                    <Box
                      padding={{ top: 's' }}
                      fontWeight='heavy'
                      color='text-status-error'
                    >
                      {currentStatus?.ineligibilityReasons[child.id].map((x, i) => <div key={'child-ineligibility-reasons-2-' + i}>{x}</div>)}
                    </Box>}
                  <div>
                    <Box variant='awsui-key-label'>
                      Bypass Potential Duplicate Checks
                    </Box>
                    <div>{child.bypassPotentialDuplicate ? 'Yes' : 'No'}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      Bypass Age Related Checks
                    </Box>
                    <div>{child.bypassAge ? 'Yes' : 'No'}</div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>
                      Exclude Child from Benefit Recipients
                    </Box>
                    <div>{child.excluded ? 'Yes' : 'No'}</div>
                  </div>
                </ColumnLayout>
              </>}
            {identityUsages.length > 0 &&
              <div>
                <Divider />
                <Box>
                  <Header variant='h4'>
                    Other Uses of This Identity
                  </Header>
                </Box>
                <Grid
                  gridDefinition={[
                    { colspan: 2 },
                    { colspan: 3 },
                    { colspan: 3 },
                    { colspan: 2 },
                    { colspan: 2 }
                  ]}
                >
                  <div>
                    <Box variant='awsui-key-label'>Source</Box>
                    <div>
                      {identityUsages.map((x, i) =>
                        <div direction='horizontal' size='xs' key={'identity-use-source-' + i}>
                          {x.source === 'application' && <Link target='_blank' href={'/admin/application/' + x.sourceId} rel='noreferrer'>App # {x.sourceId}</Link>}
                          {x.source === 'passive_enrollment' && <Link target='_blank' href={'/admin/passive-enrollment/' + x.sourceId} rel='noreferrer'>PE # {x.sourceId}</Link>}
                          {!['application', 'passive_enrollment'].includes(x.source) && <div>{`${x.source} # ${x.sourceId}`}</div>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>Tribe</Box>
                    <div>
                      {identityUsages.map((x, i) =>
                        <div direction='horizontal' size='xs' key={'identity-use-tribe-' + i}>
                          {x.tribe}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>Name</Box>
                    <div>
                      {identityUsages.map((x, i) =>
                        <div direction='horizontal' size='xs' key={'identity-use-name-' + i}>
                          {x.firstName} {x.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>Birthdate</Box>
                    <div>
                      {identityUsages.map((x, i) =>
                        <div direction='horizontal' size='xs' key={'identity-use-birthdate-' + i}>
                          {formatDate(x.birthdate)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Box variant='awsui-key-label'>Created</Box>
                    <div>
                      {identityUsages.map((x, i) =>
                        <div direction='horizontal' size='xs' key={'identity-use-created-' + i}>
                          {formatDate(x.createdAt)}
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </div>}
          </Container>
        )
      })}
    </SpaceBetween>
  )
}

function HouseholdMembers ({ application }) {
  return (
    <Container
      header={
        <Header variant='h2'>
          Household Member Information
        </Header>
      }
    >
      {application.householdMembers.length === 0 && <Box color='text-status-inactive'>No other household members provided.</Box>}
      {application.householdMembers.map((member, index) => (
        <div key={'member-' + member.id}>
          {index > 0 && <br />}
          {index > 0 && <Divider />}
          <ColumnLayout columns={3}>
            <div>
              <Box variant='awsui-key-label'>
                Name
              </Box>
              <div>{`${member.firstName} ${member.middleName ? member.middleName + ' ' : ''} ${member.lastName} ${member.suffix ?? ''}`}</div>
            </div>
            <div>
              <Box variant='awsui-key-label'>
                Race / Ethnicity
              </Box>
              <div>{member.raceEthnicity ?? '-'}</div>
            </div>
            {member.tribe != null &&
              <div>
                <Box variant='awsui-key-label'>
                  Tribe
                </Box>
                <div>{member.tribe}</div>
              </div>}
            <div>
              <Box variant='awsui-key-label'>
                Birth Date
              </Box>
              <div>{formatDate(member.birthDate)}</div>
            </div>
            <div>
              <Box variant='awsui-key-label'>
                Role
              </Box>
              <div>{member.role}</div>
            </div>
          </ColumnLayout>
        </div>
      ))}
    </Container>
  )
}

function AdditionalInformation ({ application }) {
  const { translate, user } = useContext(AppContext)
  const [error, setError] = useState()

  function truncateText (text) {
    const MAX_LENGTH = 100
    if (text.length <= MAX_LENGTH) {
      return text
    }
    return text.slice(0, MAX_LENGTH) + '...' + text.split('.').pop()
  }

  async function downloadFile (item) {
    const token = await user.getIdToken()
    const response = await fetch(`/api/application/${application.id}/file/${item.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/octet-stream'
      }
    })
    if (response.status !== 200) {
      setError({ message: 'Unable to download file. Please contact administrator.' })
      return
    }
    if (error != null) setError(null)
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = item.name
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
  }

  return (
    <Container
      header={
        <Header variant='h2'>
          {translate('Additional Information')}
        </Header>
      }
    >
      <SpaceBetween size='l'>
        {application.files.length === 0 && <Box color='text-status-inactive'>No additional information provided.</Box>}
        {application.files.length > 0 &&
          <SpaceBetween size='xs'>
            {application.files.map((file, index) => (
              <SpaceBetween direction='horizontal' size='s' key={`file-${file.uuid}-${file.id}`}>
                <Box>
                  {truncateText(file.name)}
                </Box>
                <Button
                  onClick={() => downloadFile(file)}
                  iconName='download'
                  variant='inline-icon'
                >
                  Download
                </Button>
              </SpaceBetween>
            ))}
          </SpaceBetween>}
        {error != null &&
          <Alert
            statusIconAriaLabel='Error'
            type='error'
          >
            {translate(error.message)}
          </Alert>}
      </SpaceBetween>
    </Container>
  )
}

function Signature ({ application }) {
  return (
    <Container
      header={
        <Header variant='h2'>
          eSignature(s)
        </Header>
      }
    >
      <SpaceBetween size='l'>
        <div className='signature-container'>
          <div className='signature' style={{ lineHeight: '38px' }}>{application.signature ?? ''}</div>
          <div className='date'>{formatDateTime(application.createdAt)}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            I agree that my electronic signature is the legal equivalent of my handwritten signature on this document
          </Box>
          <div>{application.agreeEsignature === true ? 'Yes' : 'No'}</div>
        </div>
      </SpaceBetween>
      {application.signature2 != null &&
        <>
          <Divider />
          <SpaceBetween size='l'>
            <div className='signature-container'>
              <div className='signature' style={{ lineHeight: '38px' }}>{application.signature2 ?? ''}</div>
              <div className='date'>{formatDateTime(application.updatedAt)}</div>
            </div>
            <div>
              <Box variant='awsui-key-label'>
                I agree that my electronic signature is the legal equivalent of my handwritten signature on this document
              </Box>
              <div>{application.agreeEsignature2 === true ? 'Yes' : 'No'}</div>
            </div>
          </SpaceBetween>
        </>}
    </Container>
  )
}
