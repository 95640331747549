import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGet,
  usePost,
  AppContext,
  Box,
  Button,
  FormField,
  Link,
  Modal,
  Select,
  SpaceBetween
} from 'rad-framework-ui'
import { AdaptiveList } from '../common/AdaptiveList'
import { Multiselect } from '../common/Multiselect'
import { toTitleCase } from 'titlecase'

export function EligibilityHistoryList () {
  const [searchParams, setSearchParams] = useSearchParams()
  const { error, setError, reloadCounter, setReloadCounter } = useContext(AppContext)
  const tribe = searchParams.get('tribe') ?? ''
  const [tribeId, setTribeId] = useState(null)
  const [showApplicationRunModal, setShowApplicationRunModal] = useState(false)
  const [showPassiveEnrollmentRunModal, setShowPassiveEnrollmentRunModal] = useState(false)
  const { data: userInfo } = useGet('/api/user/current')
  const { data: tribeOptions } = useGet('/api/option/tribe')

  useEffect(() => {
    if (userInfo?.tribes.length === 1) {
      setTribeId(userInfo.tribes[0].id.toString())
    }
  }, [userInfo, tribeOptions])

  function dismissApplicationRunModal () {
    setError(null)
    setShowApplicationRunModal(false)
    setTribeId(null)
  }

  function dismissPassiveEnrollmentRunModal () {
    setError(null)
    setShowPassiveEnrollmentRunModal(false)
    setTribeId(null)
  }

  function dismissErrorModal () {
    setError(null)
    setReloadCounter(reloadCounter + 1)
    setTribeId(null)
  }

  if (userInfo == null || tribeOptions == null) return null

  const filteredTribeOptions = tribeOptions
    .filter(x => userInfo.isAdmin || userInfo.tribes.map(x => x.id).includes(parseInt(x.value)))
  const selectedTribeOptions = filteredTribeOptions.filter(x => tribe.split('-').includes(x.value))

  const typeOptions = [
    { label: 'All Types', value: '' },
    { label: 'Application', value: 'application' },
    { label: 'Passive Enrollment', value: 'passive_enrollment' }
  ]

  return (
    <>
      <AdaptiveList
        title='Eligibility Admin'
        entity='eligibility-history'
        rootHref='/admin'
        create={false}
        edit={false}
        search={false}
        filter={
          <SpaceBetween size='m' direction='horizontal'>
            <FormField>
              <Multiselect
                onChange={({ detail }) => {
                  searchParams.delete('page')
                  if (detail.selectedOptions.length === 0) {
                    searchParams.delete('tribe')
                  } else {
                    searchParams.set('tribe', detail.selectedOptions.map(x => x.value).join('-'))
                  }
                  setSearchParams(searchParams)
                }}
                options={filteredTribeOptions}
                selectedOptions={selectedTribeOptions}
                placeholder='Choose a Tribe'
              />
            </FormField>
            <FormField>
              <Select
                filteringType='auto'
                selectedOption={typeOptions.find(x => x.value === (searchParams.get('type') ?? ''))}
                onChange={({ detail }) => {
                  if (detail.selectedOption.value === '') {
                    searchParams.delete('type')
                  } else {
                    searchParams.set('type', detail.selectedOption.value)
                  }
                  setSearchParams(searchParams)
                }}
                options={typeOptions}
                enteredTextLabel={value => value}
                selectedAriaLabel='Selected'
                placeholder='Choose a type'
              />
            </FormField>
          </SpaceBetween>
        }
        actions={[
          { label: 'Run Application Eligibility', onClick: () => { setError(null); setShowApplicationRunModal(true) } },
          { label: 'Run Passive Enrollment Eligibility', onClick: () => { setError(null); setShowPassiveEnrollmentRunModal(true) } }
        ]}
        fields={[
          { header: '#', name: 'id' },
          { header: 'Type', content: (item) => toTitleCase(item.kind.replace('_', ' ')) },
          {
            header: 'Date',
            content: (item) => new Date(item.createdAt).toLocaleString()
          },
          {
            header: 'User',
            content: (item) => <Link href={`/admin/user/${item.creator.id}`}>{item.creator.name}</Link>
          },
          'tribe.name',
          {
            header: 'Escalations',
            content: (item) =>
              <Box textAlign='center'>
                {item.escalations == null ? '-' : Number(item.escalations).toLocaleString()}
              </Box>
          },
          {
            header: 'Rejections',
            content: (item) =>
              <Box textAlign='center'>
                {item.rejections == null ? '-' : Number(item.rejections).toLocaleString()}
              </Box>
          },
          {
            header: 'Needs Verification',
            content: (item) =>
              <Box textAlign='center'>
                {item.needsVerifications == null ? 'n/a' : Number(item.needsVerifications).toLocaleString()}
              </Box>
          },
          {
            header: 'Acceptances',
            content: (item) =>
              <Box textAlign='center'>
                {item.acceptances == null ? 'n/a' : Number(item.acceptances).toLocaleString()}
              </Box>
          }
        ]}
      />
      <ApplicationRunModal
        showApplicationRunModal={showApplicationRunModal}
        dismissApplicationRunModal={dismissApplicationRunModal}
        tribeId={tribeId}
        setTribeId={setTribeId}
        filteredTribeOptions={filteredTribeOptions}
      />
      <PassiveEnrollmentRunModal
        showPassiveEnrollmentRunModal={showPassiveEnrollmentRunModal}
        dismissPassiveEnrollmentRunModal={dismissPassiveEnrollmentRunModal}
        tribeId={tribeId}
        setTribeId={setTribeId}
        filteredTribeOptions={filteredTribeOptions}
      />
      <DismissErrorModal dismissErrorModal={dismissErrorModal} error={error} />
    </>
  )
}

function ApplicationRunModal ({ showApplicationRunModal, dismissApplicationRunModal, tribeId, setTribeId, filteredTribeOptions }) {
  const run = usePost(`/api/eligibility-history/run-applications/${tribeId}`, {}, () => { dismissApplicationRunModal() })

  return (
    <Modal
      size='medium'
      onDismiss={() => { dismissApplicationRunModal() }}
      visible={showApplicationRunModal}
      header='Run Application Eligibility'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='s'>
            <Box padding={{ top: 'xs' }}>
              <Button
                onClick={() => dismissApplicationRunModal()}
                variant='inline-link'
              >
                Cancel
              </Button>
            </Box>
            <Button
              onClick={() => run()}
              variant='primary'
              disabled={tribeId == null}
            >
              Run
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size='l'>
        <FormField label='Tribe' field='tribeId' required>
          <Select
            filteringType='auto'
            selectedOption={filteredTribeOptions.find(x => x.value === tribeId)}
            onChange={({ detail }) => setTribeId(detail.selectedOption.value)}
            options={filteredTribeOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a Tribe'
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  )
}

function PassiveEnrollmentRunModal ({ showPassiveEnrollmentRunModal, dismissPassiveEnrollmentRunModal, tribeId, setTribeId, filteredTribeOptions }) {
  const run = usePost(`/api/eligibility-history/run-passive-enrollments/${tribeId}`, {}, () => { dismissPassiveEnrollmentRunModal() })

  return (
    <Modal
      size='medium'
      onDismiss={() => { dismissPassiveEnrollmentRunModal() }}
      visible={showPassiveEnrollmentRunModal}
      header='Run Passive Enrollment Eligibility'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='s'>
            <Box padding={{ top: 'xs' }}>
              <Button
                onClick={() => dismissPassiveEnrollmentRunModal()}
                variant='inline-link'
              >
                Cancel
              </Button>
            </Box>
            <Button
              onClick={() => run()}
              variant='primary'
              disabled={tribeId == null}
            >
              Run
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size='l'>
        <FormField label='Tribe' field='tribeId' required>
          <Select
            filteringType='auto'
            selectedOption={filteredTribeOptions.find(x => x.value === tribeId)}
            onChange={({ detail }) => setTribeId(detail.selectedOption.value)}
            options={filteredTribeOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a Tribe'
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  )
}

function DismissErrorModal ({ dismissErrorModal, error }) {
  return (
    <Modal
      size='medium'
      onDismiss={() => dismissErrorModal()}
      visible={error != null}
      header='Error'
      footer={
        <Box float='right'>
          <Button
            onClick={() => dismissErrorModal()}
            variant='primary'
          >
            Dismiss
          </Button>
        </Box>
      }
    >
      <Box color='text-status-error'>{error?.message}</Box>
    </Modal>
  )
}
