import React from 'react'
import Multiselect from '@cloudscape-design/components-themed/multiselect'

// https://cloudscape.design/components/multiselect
export function _Multiselect ({ ...rest }) {
  return (
    <Multiselect
      {...rest}
    />
  )
}

export { _Multiselect as Multiselect }
