import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from 'rad-framework-ui'

export function ContactAdministrator ({ home }) {
  const navigate = useNavigate()

  function goHome () {
    navigate(home ?? '/')
  }

  return (
    <Box
      textAlign='center'
      fontSize='display-l'
      padding={{ top: 'xxl' }}
    >
      <b>Error</b>
      <Box
        padding={{ bottom: 'xxl' }}
        color='text-body-secondary'
      >
        An unexpected error has occurred. Please contact an administrator.
      </Box>
      <Button onClick={goHome}>Home</Button>
    </Box>
  )
}
