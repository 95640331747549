
    import './styles.scoped.css';
    export default {
  "wrapper": "awsui_wrapper_z5mul_19j2m_181",
  "wrapper--fit-height": "awsui_wrapper--fit-height_z5mul_19j2m_214",
  "inner-wrapper--fit-height": "awsui_inner-wrapper--fit-height_z5mul_19j2m_219",
  "has-default-filter": "awsui_has-default-filter_z5mul_19j2m_225",
  "content": "awsui_content_z5mul_19j2m_229",
  "content--reserve-filter": "awsui_content--reserve-filter_z5mul_19j2m_236",
  "content--reserve-legend": "awsui_content--reserve-legend_z5mul_19j2m_240",
  "content--fit-height": "awsui_content--fit-height_z5mul_19j2m_244",
  "filter-container": "awsui_filter-container_z5mul_19j2m_248"
};
  